<template>
  <main class="registration w100">
    <div class="registration__intro d-flex justify-content-center align-items-center">
      <div class="registration-form">
        <form action="">
          <div class="form-title">Регистрация</div>
          <div class="form-body">
            <div class="form-type-radios d-flex align-items-center">
              <div class="d-flex radio">
                <RadioCustomVue name="radio" id="fiz" checked @click="fizLico = true; yurLico = false"/>
                <label class="radio-label" for="fiz" @click="fizLico = true; yurLico = false">Физическое лицо</label>
              </div>
              <div class="d-flex radio">
                <RadioCustomVue name="radio" id="yur" checked @click="fizLico = false; yurLico = true"/>
                <label class="radio-label" for="yur" @click="fizLico = false; yurLico = true">Юридическое лицо</label>
              </div>
            </div>
            <div class="data-inputs">
              <input placeholder="Имя" type="text" v-show="fizLico">
              <input placeholder="Фамилия" type="text" v-show="fizLico">
              <input placeholder="Наименование компании" type="text" v-show="yurLico">
              <input placeholder="БИИ" type="text" v-show="yurLico">
              <input placeholder="E-mail" type="text">
              <input placeholder="Телефон" type="text">
              <input placeholder="Пароль" type="text">
              <input placeholder="Повторите пароль" type="text">
            </div>

          </div>
          <div class="form-actions text-center">
            <router-link to="/auth/login">
              <button class="save-btn">Зарегистрироваться</button>
            </router-link>
            <div class="privacy d-flex text-start justify-content-center">
              <CheckboxCustom id="privacy-check"/>
              <!-- <input class="checkbox" type="checkbox" id="privacy-check" name="primary"> -->
              <label class="privacy-text" for="privacy-check">Я соглашаюсь с условиями <a class="privacy-link" href="#">публичной
                оферты</a> и обработкой моих персональных данных в порядке, предусмотренном публичной
                офертой.</label><br>
            </div>
            <router-link class="auth-link" to="/auth/login">Войти</router-link>
          </div>
        </form>
      </div>
    </div>
  </main>
</template>

<script>
import RadioCustomVue from '@/components/RadioCustom.vue';
import CheckboxCustom from '@/components/UI/CheckboxCustom.vue';
export default {
  name: "RegistrationPage",
  data() {
    return {
      fizLico: true,
      yurLico: false
    }
  },
  components: {RadioCustomVue, CheckboxCustom}
};
</script>

<style scoped lang="scss">
.registration {
  width: 100vw;
  padding: 68px 0;

  &-form {
    top: calc(50% - 750px / 2);
    left: calc(50% - 750px / 2);
    width: 526px;
    background: #FFFFFF;
    padding: 22px 73px 51px;
    border: 1px solid #E80707;
    box-shadow: 0 4px 24px rgba(10, 170, 250, 0.1);
    border-radius: 10px;

    .form-title {
      font-style: normal;
      font-weight: 550;
      font-size: 24px;
      line-height: 50px;
      text-align: center;
      color: #02283B;
      margin-bottom: 23px;
    }

    .form-body {
      .form-type-radios {
        padding: 23px 0 28px;
      }

      .data-inputs {
        input {
          outline-color: red;
          margin-bottom: 10px;
          background: #FFFFFF;
          border: 1px solid #E0E0E0;
          border-radius: 5px;
        }
      }
    }

    .form-actions {
      padding-top: 10px;

      .save-btn {
        width: 100%;
        padding: 19px;
        font-weight: 600;
        font-size: 16px;
        font-style: normal;
        line-height: 19px;
        color: #FEC50C;
        background: #E80707;
        box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
        border-radius: 5px;
      }

      .privacy {
        padding-top: 23px;
        display: flex;
        align-items: flex-start !important;

        .checkbox {
          margin: 0 9px;
          width: 16px;
          height: 16px;
          background: #0AAAFA;
          border-radius: 2px;

          .privacy-link {
            text-decoration-line: underline !important;
          }
        }

        .privacy-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #02283B;
          a {
            color: #02283B; 
          }
        }
      }

      .auth-link {
        font-weight: 600;
        font-size: 16px;
        text-decoration: none ;
        line-height: 50px;
        color: #02283B;
        border-bottom: 1px solid #02283B;
        margin-bottom: 13px;
      }
    }
  }

  &-title {
    margin-bottom: 35px;
  }
  .radio {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  input[type=radio] {
    height: auto;
    width: auto;
    margin-right: 5px;
  }
}
</style>