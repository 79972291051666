import { createRouter, createWebHistory } from "vue-router";
import HomePage from "@/modules/home/views/HomePage";
import OurRoutesPage from "@/modules/routes/views/OurRoutesPage";
import OurServicesPage from "@/modules/services/views/OurServicesPage";
import GroundFreightPage from "@/modules/services/views/GroundFreightPage";
import AviaFreightPage from "@/modules/services/views/AviaFreightPage";
import RailwayFreightPage from "@/modules/services/views/RailwayFreightPage";
import InternationalFreightPage from "@/modules/services/views/InternationalFreightPage"
import RegistrationPage from "@/modules/registration/views/RegistrationPage";
import NewsPage from "@/modules/news/views/NewsPage";
import MainLayout from "@/modules/app/views/layout/main/MainLayout";
import BlankLayout from "@/modules/app/views/layout/blank/BlankLayout";
import AboutCompanyPage from "@/modules/about-company/views/AboutCompanyPage";
import ArticlePage from "@/modules/article/views/ArticlePage";
import ContactsPage from "@/modules/contacts/views/ContactsPage";
import LoginPage from "@/modules/login/views/LoginPage";

const routes = [
  {
    path: '',
    component: MainLayout,
    meta: {
      requiresAuth: false,
    },
    redirect: { name: "home" },
    children: [
      {
        path: "/",
        name: "home",
        component: HomePage,
      },
      {
        path: "/article",
        name: "article",
        component: ArticlePage,
      },
      {
        path: "/about-company",
        name: "aboutCompany",
        component: AboutCompanyPage,
      },
      {
        path: "/contacts",
        name: "contacts",
        component: ContactsPage,
      },
      {
        path: "/our-routes",
        name: "routes",
        component: OurRoutesPage,
      },
      {
        path: "/our-services",
        name: "services",
        component: OurServicesPage,
      },
      {
        path: "/ground-freight",
        name: "groundFreight",
        component: GroundFreightPage,
      },
      {
        path: "/railway-freight",
        name: "railwayFreight",
        component: RailwayFreightPage,
      },
      {
        path: "/avia-freight",
        name: "aviaFreight",
        component: AviaFreightPage,
      },
      {
        path: "/international-freight",
        name: "internationalFreight",
        component: InternationalFreightPage,
      },
      {
        path: "/news",
        name: "news",
        component: NewsPage,
      },
      {
        path: "/news/:slug",
        name: "newsSlug",
        component: () => import("@/components/UI/NewsCard.vue")
      },
      {
        path: "/user-agreement",
        name: "UserAgreement",
        component: () => import('@/views/AgreementView.vue')
      },
      {
        path: "/user-privacy",
        name: "UserPrivacy",
        component: () => import('@/views/PrivacyView.vue')
      },
    ]
  },
  {
    path: '',
    component: BlankLayout,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/auth/login',
        name: 'login',
        component: LoginPage,
      },
      {
        path: "/registration",
        name: "registration",
        component: RegistrationPage,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

export default router;
