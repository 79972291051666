<template>
  <div class="details">
    <div class="details__intro">  
      <img :src="require('@/assets/icons/track3.png')" class="truck1"/>
      <div class="container">
        <div class="row">
          <div class="details1 col-6 ">
            <div class="details-title w46-600" style="font-weight: 400;">
              {{ $t('ourServ3') }}
            </div>
            <div class="col-10">
              <p class="details-desc w14-400 text-start" v-html="$t('airDesc')">
              </p>
            </div>
            <div class="details-feedback-btn d-flex justify-content-start">
              <button class="w16-700 feedback-btn" data-bs-toggle="modal" data-bs-target="#orderForm">
                {{ $t('leaveRequest') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class=container>
      <div class="railway-benefits borderBlock">
      <div class="railway-title b36-700 text-uppercase trainDesc2">{{ $t('airDesc2') }}</div>
      <div class="row d-flex justify-content-between align-items-center w100 delif">
        <div class="col-6 railway-left-benefits col1">
          <ul class="timeline">
            <li class="event">
              <p>
                {{ $t('avia.desc1') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('avia.desc2') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('avia.desc3') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('avia.desc4') }}
              </p>
            </li>
            <div class="timeline1-border"></div>
          </ul>
        </div>
        <div class="col-6 col2">
          <ul class="timeline">
             <li class="event">
              <p>
                {{ $t('avia.desc5') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('avia.desc6') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('avia.desc7') }}
              </p>
            </li>
            <div class="timeline2-border"></div>
          </ul>
        </div>
      </div>
      <p v-html="$t('noticeForClientAvia')" class="noticeClient">
      </p>
    </div>
    </div>


    <div class="pluses1">
      <div class="container">
        <div class="b36-700 text-uppercase title premushContainer">{{ $t('ourServ3Type') }}</div>
        <div class="benefit-list contGap">
          <div class="benefit-item">
            <div class="plus-icon1 d-flex align-items-center">
              <img :src="require('@/assets/images/our-services/avia/1.png')" alt="">
            </div>
            <div class="plus-title1 b20-700 d-flex align-items-center">{{ $t('container1') }}</div>
          </div>
          <div class="benefit-item">
            <div class="plus-icon1 d-flex align-items-center">
              <img :src="require('@/assets/images/our-services/avia/2.png')" alt="">
            </div>
            <div class="plus-title1 b20-700 d-flex align-items-center">{{ $t('container2') }}</div>
          </div>
          <div class="benefit-item">
            <div class="plus-icon1 d-flex align-items-center">
              <img :src="require('@/assets/images/our-services/avia/3.png')" alt="">
            </div>
            <div class="plus-title1 b20-700 d-flex align-items-center">{{ $t('container3') }}</div>
          </div>
          <div class="benefit-item">
            <div class="plus-icon1 d-flex align-items-center">
              <img :src="require('@/assets/images/our-services/avia/4.png')" alt="">
            </div>
            <div class="plus-title1 b20-700 d-flex align-items-center" v-html="$t('container4')"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="advantBack">
      <div class="advant">
          <p class="advant-title">{{ $t('airDesc3') }}</p>
          <div class="advant__content">
              <div class="advant__content__box advant__content__box1">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/wallet.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('avia.preTitle1') }}</p>
                <p class="advant__content__box-p2">{{ $t('avia.preDesc1') }}</p>
              </div>
              <div class="advant__content__box advant__content__box2">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/earth.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('avia.preTitle2') }}</p>
                <p class="advant__content__box-p2">{{ $t('avia.preDesc2') }}</p>
              </div>
              <div class="advant__content__box advant__content__box3">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/navigator.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('avia.preTitle3') }}</p>
                <p class="advant__content__box-p2">{{ $t('avia.preDesc3') }}</p>
              </div>
              <div class="advant__content__box advant__content__box4">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/container.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('avia.preTitle4') }}</p>
                <p class="advant__content__box-p2">{{ $t('avia.preDesc4') }}</p>
              </div>
          </div>
      </div>
    </div>

    

    <ModalOrderForm />
  </div>
  <OstQues />
</template>
<script>
import OstQues from "@/components/OstQues.vue";
import ModalOrderForm from "@/modules/app/views/layout/main/ModalOrderForm";
export default {
  name: "AviaDetails",
  components: { ModalOrderForm, OstQues }
};
</script>
<style lang="scss" scoped>


.advantBack {
  padding: 52px 0 55px 0;
  background: transparent;
}
.advant {
  width: 1378px;
  margin: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $laptop + px) {
      width: 1140px;
  }

  @media screen and (max-width: 1200px) {
    width: 960px;
  }

  @media screen and (max-width: $tablet + px) {
    width: 720px;
  }

  @media screen and (max-width: $mobile + px) {
    width: 95%;
  }
  &-title {
    margin-bottom: 50px;
    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.4286))), 36px);
    color: black;
    font-weight: 400;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 770px) {
        flex-direction: column;
        gap: 32px;
    }
    &__box {
      width: 23%;
      display: flex;
      flex-direction: column;
      gap: 9px;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
      &-img {    
        width: 100%;
        display: flex;
        height: 83px;
        justify-content: center;
        align-items: center;
      }
      &-p1 {
        font-weight: 500;
        font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0))), 17px);
        text-transform: uppercase;
        color: #161616;
      }
      &-p2 {
        text-align: start;
        font-size: 15px;
        color: #161616;
        font-weight: 400;
        @media screen and (max-width: 770px) {
          text-align: center;
        }
        
      }
    }
    &__box1 {
      width: 18.14%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
    &__box2 {
      width: 18.14%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
    &__box3 {
      width: 24.38%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
    &__box4 {
      width: 32.8%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
  }
}


.contGap {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    align-items: center;
  }
}
.timeline {
  border-left: 0px solid red !important;
  p {
    font-weight: 500;
  }
}
.timeline-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(84%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
  }

.event {
      font-size: 16px !important;
}

.b36-700  {
    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.2903))), 36px) !important;
}
.benefit-list {
  display: flex;
   @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 75px;
   }
}

.details1 {
  position: absolute;
  top: 0;
  margin-top: 100px;
  width: 45.68%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 40px;
  }
}


.b20-700 {
  font-size: 16px;
  font-weight: 600;
}

.truck1 {
    position: absolute;
    bottom: -255px;
    right: 0;
     @media screen and (max-width: 992px) {
        width: 85%;
        right: -2px;
        bottom: -77px;
    }
  }

.details {
  @media screen and (max-width: 992px) {
    margin-top: 76px;
  }
  &__intro {
    position: relative;
    height: 600px;
    background: url("@/assets/images/bg/avia.png") center no-repeat;
    background-size: cover;
    background-color: black;
    background-position: center;
    background-position-y: 70%;
    padding-bottom: 300px;
     @media screen and (max-width: 992px) {
      height: 393px;
    }
  }
  &__intro::before {
    position: absolute;
    background: url("@/assets/images/our-services/ground/car.png") center no-repeat;
    background-size: cover;
  }
  &__content {
    width: 50%;
  }
  &-img {
    width: 50%;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    img {
     position: relative;
      // bottom: -31px;
      bottom: 16px;
      width: 100%;
      object-fit: cover;
      @media screen and (max-width: 992px) {
          bottom: -31px;
      }
    }
  }
  &-title {
    font-size: min(max(33px, calc(2.0625rem + ((1vw - 3.2px) * 1.3978))), 46px) !important;
    line-height: 56px;
    margin-bottom: 25px;
    // margin-top: 140px;
    text-transform: uppercase;
    @media screen and (max-width: 992px) {
      font-weight: 400;
      line-height: 44px;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
  &-desc {
    line-height: 25px;
    margin-bottom: 25px;
    font-size: min(max(13.5px, calc(0.84375rem + ((1vw - 3.2px) * 0.7589))), 22px);
    @media screen and (max-width: 992px) {
      font-weight: 400;
      line-height: 25px;
      color: #FFFFFF;
      margin-bottom: 5px;
    }
  }
  &-feedback-btn {
    button {
      padding: 0 49px;
      height: 57px;
      background: #E80707;
      color: #FEC50C;
      box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
      font-weight: 400;
      font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px);
      border-radius: 5px;
      transition: all 0.5s ease;
      @media screen and (max-width: 992px) {
        font-weight: 550;
      }
    }
  }

  .pluses1 {
    background: #F3F3F3;
    padding: 70px 0;
    @media screen and (max-width: 768px) {
      padding: 54px 0;
    }
    .title1 {
      margin-bottom: 75px;
      text-align: start;
      // margin-left: 35px;
      @media screen and (max-width: 992px) {
       font-weight: 550;
      font-size: 26px;
      line-height: 31px;
      margin-left: 0;
      }
    }
    .plus-title1 {
      margin-top: 30px;
      text-transform: uppercase;
      color: #161616;
      line-height: 19px;
      text-align: start;
      font-weight: 700 !important;
      @media screen and (max-width: 992px) {
        margin-top: 22px;
      }
    }
    .plus-icon1 {
      height: 120px;
      padding-bottom: 25px;
    }
  }

  .benefits {
    padding-top: 110px;
    padding-bottom: 130px;
    @media screen and (max-width: 992px) {
      padding-top: 40px;
      display: flex;
      flex-direction: column;
    padding-bottom: 60px;
    }
    &__content {
      margin-right: 33px;
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-right: 0px;
      }
    }
    &-title {
      margin-bottom: 39px;
      @media screen and (max-width: 992px) {
         margin-bottom: 24px;
         margin-top: 10px;
        font-weight: 550;
        font-size: 26px;
        line-height: 31px;
        width: 100%;
      }
    }
  }

  .car-types {
    background: url("@/assets/images/our-services/ground/cars.svg") center no-repeat;
    background-size: cover;
    padding-top: 43px;
    width: 1440px;
    height: 400px;
  }
}
.airDesc2 {
  font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.4286))), 36px) !important;
  text-align: start;
}
.airDesc2Center {
  display: flex;
  flex-direction: column;
  align-items: start !important;
}

.pluses {
    background: #ffffff;
    padding: 70px 0;
    @media screen and (max-width: 992px) {
      padding: 50px 0;
    }
    .title {
      margin-bottom: 32px;
      @media screen and (max-width: 992px) {
        margin-left: 0;
        margin-bottom: 25px;
      }
    }
    .plus-title {
       font-weight: 440;
          font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.3226))), 20px);
        line-height: 22px;
        margin: 10px 0;
      margin: 10px 0;
      text-align: start;
      text-transform: uppercase;
      @media screen and (max-width: 992px) {
        font-weight: 550;
        line-height: 22px;
        margin: 10px 0;
        text-align: center;
      }
    }
    .plus-icon {
      height: 120px;
      @media screen and (max-width: 992px) {
        height: auto;
      }
    }
    .premush {
      justify-content: center;
      display: flex;
      font-weight: 400;
      text-transform: none !important;
      font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 0.8929))), 34px) !important;
      line-height: 31px;
    }
    .premush1 {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        gap: 30px;
      }
    }
    .benefit-item  {
      width: 17.5%;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
    .benefit-item4 {
      width: 29%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .avia-title {
        // margin-left: 35%;
        @media screen and (max-width: 768px) {
          margin-left: 0px;
        }

      }
    }
    .benefit-item3 {
      // width: 23%;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      .avia-title {
        // margin-left: 35%;
        @media screen and (max-width: 768px) {
          margin-left: 0px;
        }
      }
    }
  }
  .premushContainer {
    text-transform: none !important;
    font-weight: 400 !important;
    justify-content: center;
    margin-bottom: 89px;
    @media screen and (max-width: 992px) {
      font-size: min(max(20.75px, calc(1.296875rem + ((1vw - 3.2px) * 1.3616))), 36px) !important;
      margin-bottom: 42px;
      text-transform: uppercase !important;
      width: 74%;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .widthAvia {
    width: 82%;
  }
   .noticeClient {
    color: black;
    font-weight: 500;
    margin-top: 21px;
    font-size: 16px !important;
    @media screen and (max-width: 776px) {
      margin-top: 45px;
      width: 90%;
      margin-right: auto;
      margin-left: auto;
    }

}
.col-10 {
  @media screen and (max-width: 776px) {
    width: 100%;
  }
}




.trainDesc2 {
  margin-bottom: 56px;
  text-transform: none !important;
  font-weight: 400 !important;
  width: max-content;
  font-size: min(max(21.5px, calc(1.34375rem + ((1vw - 3.2px) * 1.2946))), 36px) !important;
  @media screen and (max-width: 992px) {
    width: auto;
    text-align: center;
  }
}

.delif {
  align-items: start !important;
  flex-wrap: nowrap !important;
  @media screen and (max-width: 992px) {
    --bs-gutter-x: 0rem;
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
  }
}
.col1 {
  width: 52%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.col2 {
  width: 48%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.timeline1-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(78%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
      @media screen and (max-width: 768px) {
        height: min(max(105%, 5.125rem + (1vw - 4.1%) * 2.1429), 105%);
      }
  }
  .timeline2-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(61%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
      @media screen and (max-width: 768px) {
        height:  min(max(55%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      }
  }
  .borderBlock {
    margin: 52px 0 37px 0;
  }
  .avia {
      &-title {
      display: flex;
        justify-content: center;
        font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
    }
    &-desc {
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.3571))), 13px);
    }
  }
  .aviaContainer {
    width: 80%;
    margin: auto;
  }
</style>