<template>
  <main class="inter-freight w100">
    <InternationalDetails/>
  </main>
</template>

<script>
import InternationalDetails from "../components/InternationalFreight/InternationalDetails.vue";

export default {
  components: { InternationalDetails },
  name: "InterFreightPage",
};
</script>

<style scoped lang="scss">
.inter-freight {
  width: 100vw;

  .inter-img {
    padding: 100px 0;
    img {
      object-fit: cover;
      width: 100%;
    }
  }

  &__intro {
    //margin-top: 74px;
  }

  //
  &-title {
    margin-bottom: 26px;
  }
}
</style>
