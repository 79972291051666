<template>
    <div class="checkbox">
        <div class="checkbox__content">
            <input class="checkbox__content-input" type="checkbox" :name="name" :id="id"/>
            <label class="checkbox__content-label" :for="id">{{ title }}</label>
        </div>
    </div>
</template>
<script>
export default {
    props: ['id', 'title', 'name']
}
</script>
<style lang="scss" scoped>
 .checkbox {
    position: relative;
    display: flex;
    align-items: stretch;
    gap: 10px;
    &__content {
        position: relative;
        width: 18px;
        height: 18px;
        &-input {
            display: none;
        }

        &-label { 
            width: max-content;
            display: flex;
            margin-left: 30px;
            cursor: pointer;
            &:before {
                content: "";
                display: block;
                   width: 17px;
                    height: 16.8px;

                border: 2px solid red;
                background: white;
                border-radius: 3px;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
            }

            &:after {
                content: "";
                display: block;
                width: 18px;
                height: 18px;

                background: url("@/assets/icons/whiteCheck.svg") no-repeat;
                opacity: 0;

                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }
        
        .checkbox__content-input:checked + .checkbox__content-label:after {
            opacity: 1;
        }
    }
}

</style>