<template>
  <main class="ground-freight w100">
    <GroundDetails/>
  </main>
</template>

<script>
import GroundDetails from "@/modules/services/components/GroundFreight/GroundDetails";

export default {
  components: {GroundDetails},
  name: "GroundFreightPage",
};
</script>

<style scoped lang="scss">
.ground-freight {
  width: 100vw;

  .ground-img {
    padding: 100px 0;
    img {
      object-fit: cover;
      width: 100%;
    }
  }

  &__intro {
    //margin-top: 74px;
  }

  //
  &-title {
    margin-bottom: 26px;
  }
}
</style>
