<template>
  <main class="login w100">
    <div class="login__intro d-flex justify-content-center align-items-center">
    <div class="login-form">
      <!--        <PhysicalLoginForm />-->
      <form action="">
        <div class="form-title">Авторизация</div>
        <div class="form-body">
          <div class="data-inputs">
            <input placeholder="БИИ" type="text">
            <input placeholder="Пароль" type="text">
          </div>
        </div>
        <div class="form-actions text-center">
          <router-link to="/personal-area">
            <button class="save-btn">Войти</button>
          </router-link>
          <div class="privacy d-flex text-start justify-content-center">
            <CheckboxCustom id="privacy-check"/>
            <label class="privacy-text" for="privacy-check">Забыли пароль?</label><br>
          </div>
          <router-link class="auth-link" to="/registration">Зарегистрироваться</router-link>
        </div>
      </form>
    </div>
  </div>
  </main>
</template>

<script>
import PhysicalLoginForm from "@/modules/login/components/PhysicalLoginForm";
import CheckboxCustom from "@/components/UI/CheckboxCustom.vue";

export default {
  name: "LoginPage",
  components: {PhysicalLoginForm, CheckboxCustom}
};
</script>

<style lang="scss">

.privacy {
  display: flex;
  align-items: center !important;
}
.login {
  padding: 68px 0;

  &-form {
    top: calc(50% - 750px / 2);
    left: calc(50% - 750px / 2);
    width: 343px;
    background: #FFFFFF;
    padding: 15px 19px 19px 19px;
    border: 1px solid #E80707;
    box-shadow: 0px 4px 24px rgba(10, 170, 250, 0.1);
    border-radius: 7px;
    // @media screen and (max-width: 992px) {
    //   background: transparent;
    // }
    @media screen and (max-width: 992px) {
      width: 100%;
      padding: 22px 16px 51px;
    }

    .form-title {
      font-style: normal;
      font-weight: 400;
      font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.625))), 24px);
      line-height: normal;
      text-align: center;
      color: #161616;
      margin-bottom: 15px;
    }

    .form-body {
      .form-type-radios {
        padding: 23px 0 28px;
      }

      .data-inputs {
        input {
          margin-bottom: 10px;
          background: #FFFFFF;
          border: 0.5px solid #E0E0E0;
          outline-color: red;
          height: 50px;
          border-radius: 5px;
          @media screen and (max-width: 992px) {
            width: 100%;
          }
        }
      }
    }

    .form-actions {
      padding-top: 10px;

      .save-btn {
        width: 65%;
        height: 58px;
        font-weight: 600;
        font-size: 16px;
        font-style: normal;
        line-height: 19px;
        color: #FEC50C;
        background: #E80707;
        box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
        border-radius: 5px;
      }

      .privacy {
        padding-top: 23px;

        .checkbox {
          margin: 0 9px;
          width: 16px;
          height: 16px;
          background: #0AAAFA;
          border-radius: 2px;

          .privacy-link {
            text-decoration-line: underline !important;
          }
        }

        .privacy-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #02283B;
        }
      }

      .auth-link {
        font-weight: 600;
        font-size: 16px;
        line-height: 50px;
        color: #02283B;
        margin-bottom: 13px;
        border-bottom: 1.5px solid black;
        text-decoration: none;
      }
    }
  }

  &-title {
    margin-bottom: 35px;
  }
}
</style>