<template>
  <!-- Modal -->
  <div class="modal fade" id="orderForm" tabindex="-1" aria-labelledby="orderForm" aria-hidden="true" 
    ref="myModal1">
    <div class="modal-dialog">
      <div class="modal-content login p-0" v-click-outside="resetModalData" style="width: fit-content; margin: auto;">
        <div class="login__intro d-flex justify-content-center align-items-center">
          <div class="login-form">
            <div>
              <div class="form-title">{{ $t('leaveRequest') }}</div>
              <div class="form-body">
                <div class="data-inputs">

                   <input 
                   @input="proverka = false" type="text" :placeholder="$t('name') + ' ' + '*'" v-model="v$.name.$model" @keyup="onlyText()"/>
                    <template
                      v-for="(error, index) of v$.name.$errors"
                      :key="index"
                    >
                      <p class="errorValid" v-if="name.length > 0 || proverka">{{ error.$message }}</p>
                    </template>

                   <input type="text" 
                    :placeholder="$t('phone') + ' ' + '*'" 
                    v-model="v$.phone.$model" 
                    v-mask="'+7 (###) ### ## ##'"
                    @input="isNumber"
                    @focus="proverka = false"
                    @accept="onAccept"
                    @complete="onComplete"/>
                    <template
                      v-for="(error, index) of v$.phone.$errors"
                      :key="index"
                    >
                      <p class="errorValid" v-if="phone.length > 0 || proverka">{{ error.$message }}</p>
                    </template>
                    
                    <input type="text" :placeholder="$t('req1')" :required="false" v-model="req1">
                    <input type="text" :placeholder="$t('req2')" :required="false" v-model="req2">
                    <input type="text"  :placeholder="$t('req3')" :required="false" v-model="req3">

                    <input type="email" :placeholder="$t('email')" v-model="v$.email.$model" 
                    @input="proverka = false" />
                    <template
                      v-for="(error, index) of v$.email.$errors"
                      :key="index"
                    >
                      <p class="errorValid"  v-if="email.length > 0 || proverka">{{ error.$message }}</p>
                    </template>

                    <textarea class="textareaForm" placeholder="Введите комментарий" v-model="comment" :required="false"></textarea>

                </div>
                </div>
                <div class="form-actions text-center">
                <button class="save-btn" @click="leaveRequest">{{ $t('send') }}</button>
              </div>
            </div>
          </div>
        </div>
        <a href="#" class="close" data-bs-dismiss="modal">
          <img class="closeIcon" :src="require('@/assets/icons/closeIcon.svg')" aria-label="Close" aria-hidden="true"/>
        </a>
      </div>
    </div>
  </div>
</template>
<script>

import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email } from "@vuelidate/validators";

import { mask } from "vue-the-mask";
import { ref } from 'vue';
import { mapGetters } from 'vuex';


export default {
  data() {
    return {
      proverka: false,
      v$: useVuelidate(),
      name: "",
      phone: "",
      req1: "",
      req2: "",
      req3: "",
      comment: "",
      email: "",
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
    }
  },
  directives: { mask },
  validations() {
    return {
      name: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        minLength: helpers.withMessage(
          this.$t("errors.nameError"),
          minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        email: helpers.withMessage(this.$t("errors.email"), email),
      },
      phone: {
        required: helpers.withMessage(this.$t("errors.required1"), required),
        minLength: helpers.withMessage(
          this.$t("errors.telLength"),
          minLength(18)
        ),
      },
    };
  },
  computed: {
    ...mapGetters(["getIsAuth"])
  },
  methods: {

    resetModalData() {
      this.name = "",
      this.phone = "",
      this.req1 = "",
      this.req2 = "",
      this.req3 = "",
      this.comment = "",
      this.email = "",
      this.v$.$reset()
    },

    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error("Такого мобильного оператора не существует!");
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      console.log("complete", maskRef.unmaskedValue);
    },


    onlyText() {
      this.name = this.name.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function(match) {
        return match.substr(0, 1);
      });
    },


    async leaveRequest() {

      this.proverka = true;

      this.v$.name.$validate();
      this.v$.phone.$validate();
      this.getIsAuth ? this.v$.$validate() : ''
      let bool = this.getIsAuth ? !this.v$.name.$invalid && !this.v$.phone.$invalid && !this.v$.email.$invalid : !this.v$.name.$invalid && !this.v$.phone.$invalid
      if (bool) {
        const reqData = {
        name: this.name,
        phone: this.phone,
        cargo: this.req1,
        weight: this.req2,
        type: this.req3,
        note: this.comment,
        email: this.email
        };
        await this.$axios.post('noticeClient', reqData)
          .then(res => {
            this.$toaster.success('Ваша заявка успешно отправлена!');
            this.$refs.myModal1.querySelector('[data-bs-dismiss="modal"]').click();
            this.resetModalData();
          })
          .catch(err => {
            this.$toaster.error('Неверные данные');
          })
        }
      else {
        // $('#orderForm').modal('hide');
         this.$toaster.error('Заполните обязательные поля, пожалуйста!');
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.errorValid {
  text-align: start;
  font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
  margin-top: -10px;
  padding-bottom: 10px;
  margin-left: 16px;
  color: red;
}

.model-content {
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 5px;
  transition: 0.5s ease;
  &:hover {
    transform: scale(1.15);

  }
  @media screen and (max-width: 992px) {
    right: 5px;
  }
}
.textareaForm {
  resize: none;
  height: 56px;
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 10px;
  background: #FFFFFF;
  border: 0.5px solid #E0E0E0;
  outline-color: red;
  border-radius: 5px;   
}

.modal-dialog {
  margin-top: 5.5%;
  @media screen and (max-width: 992px) {
    margin-top: 22%;
  }
}
.login-form {
  
}
.save-btn {
  font-weight: 400 !important;
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
}
</style>