<template> 
  <main class="home w100">
    <div class="homeBanner1">
        <div class="home__intro">
          <img :src="require('@/assets/images/track00.png')" class="truck1"/>
          <div class="container flex-row-between-start forma2">
            <div class="home__intro-title w56-800" style="font-size: min(max(28px, calc(1.75rem + ((1vw - 3.2px) * 1.6071))), 46px); font-weight: 500;">
              <p>{{ $t("mainTitle") }}</p>
              <p class="mainTextDesc">{{ $t('mainTitleDesc') }}</p>
            </div>
            <BlockFormOrder />
          </div>
        </div>
        <block-form-order-mobile />
    </div>

    <div class="block2 container">
        <div class="block2__content" v-for="item in block2" :key="item.id" :style="{ backgroundImage: 'url(' + require('@/assets/images/block2/'+ item.image +'.png') + ')' }">
            <p class="block2__content-p1" v-html="$t(item.title)"></p>
            <router-link :to="item.link"><p class="block2__content-button">{{ $t('podrognee') }}</p></router-link>
        </div>
    </div>

    <div class="block3">
        <p class="block3-title" v-html="$t('extraUslugi')"></p>
        <div class="block3__back">
            <div class="block3__content container">
                <div class="block3__content__card" v-for="item in block3" :key="item.id">
                    <img class="block3__content__card-img" :src="require('@/assets/icons/block3/' + item.image + '.png')"/>
                    <p class="block3__content__card-title" v-html="$t(item.title)"></p>
                </div>
            </div>
        </div>
    </div>

    <div class="desctop">
      <div class="block4">
        <p class="block4-p1">{{ $t('etapRaboti') }}</p>
        <p class="block4-p2">{{ $t('etapRabotiDesc') }}</p>
        <div class="block4-line"></div>
        <div class="block4__content">
            <div class="block4__content__card" v-for="(item, index) in block4" :key="index">
                <p class="block4__content__card-number">{{ ++index }}</p>
                <div class="block4__content__card__text">
                  <p class="block4__content__card__text-p1" v-html="$t(item.title)"></p>
                  <p class="block4__content__card__text-p2" v-html="$t(item.desc)"></p>
                </div>
            </div>
        </div>
      </div>
    </div>

    <div class="mobile">
      <div class="block4 container">
        <p class="block4-p1">{{ $t('etapRaboti') }}</p>
        <p class="block4-p2">{{ $t('etapRabotiDesc') }}</p>
        <div class="block4MobBLock">
          <div class="block4-line"></div>
          <div class="block4__content">
              <div class="block4__content__card" v-for="(item, index) in block4" :key="index">
                  <p class="block4__content__card-number">{{ ++index }}</p>
                  <div class="block4__content__card__text">
                    <p class="block4__content__card__text-p1" v-html="$t(item.title)"></p>
                    <p class="block4__content__card__text-p2" v-html="$t(item.desc)"></p>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="block5 container">
        <p class="block4-p1">{{ $t('whyUs')}}</p>
        <div class="block5__content">
            <div class="block5__content__card" v-for="item in block5" :key="item.id" :style="{ backgroundImage: 'url(' + require('@/assets/images/block5/'+ item.image +'.png') + ')' }">
                <div class="block5__content__card-box"><img :src="require('@/assets/images/block5/' + item.icon + '.png')"/></div>
                <div class="block5__content__card-line"></div>
                <p class="block5__content__card-p1">{{ $t(item.title) }}: <span class="block5__content__card-p2" v-html="$t(item.desc)"></span></p>
            </div>
        </div>
    </div>

    <div class="block6 container">
        <p class="block4-p1">{{ $t('forWho') }}</p>
        <div class="block6__content">
            <div class="block6__content__card"  v-for="(item, index) in block6" :key="index" :style="{ backgroundImage: 'url(' + require('@/assets/images/'+ item.image +'.png') + ')' }">
                <p class="block6__content__card-p1">0{{ ++index }}</p>
                <p class="block6__content__card-p2" :style="'color:' + item.color " v-html="$t(item.title)"></p>
            </div>
        </div>
        <div class="block6__bot">
          <p v-html="$t('forWhoDesc')"></p>
        </div>
    </div>

    <div class="block7 container"> 
        <p class="block4-p1 desctop" v-html="$t('weCargo')"></p>
        <p class="block4-p1 mobile" v-html="$t('weCargoMobile')"></p>
        <div class="block7__content">
            <div class="block7__content__card" v-for="item in block7" :key="item.id">
                <div class="block7__content__card-succ">
                  <img :src="require('@/assets/icons/redLine2.png')"/>
                </div>
                <img class="block7__content__card-img" :src="require('@/assets/icons/block7/' + item.image + '.png')"/>
                <p class="block7__content__card-p1">{{ $t(item.title) }}</p>
            </div>
        </div>
    </div>
        
    <ModalOrderForm />
    <OurPartners/>
    <OstQues />
  </main>
</template>

<script>

import OurPartners from "@/modules/home/components/OurPartners";
import ModalOrderForm from "@/modules/app/views/layout/main/ModalOrderForm.vue";


import BlockFormOrder from "@/components/BlockFormOrder.vue";
import BlockFormOrderMobile from '@/components/BlockFormOrderMobile.vue';
import OstQues from "@/components/OstQues.vue";

export default {
  components: { OurPartners, ModalOrderForm, BlockFormOrder, BlockFormOrderMobile, OstQues },
  name: "HomePage",
  data() {
    return {
      block2: [
        {
          title: "ourServ2",
          image: "block21",
          link: "/railway-freight"
        },
        {
          title: "ourServ1",
          image: "block22",
          link: "/ground-freight"
        },
        {
          title: "ourServ3",
          image: "block23",
          link: "avia-freight"
        },
        {
          title: "ourServ4",
          image: "block24",
          link: "international-freight"
        }
      ],


      block3: [
        {
          title: `extraUslugiDesc1`,
          image: "block31"
        },
        {
          title: `extraUslugiDesc2`,
          image: "block32"
        },
        {
          title: `extraUslugiDesc3`,
          image: "block33"
        },
        {
          title: `extraUslugiDesc4`,
          image: "block34"
        },
        {
          title: `extraUslugiDesc5`,
          image: "block35"
        },
        {
          title: `extraUslugiDesc6`,
          image: "block36"
        },
        {
          title: `extraUslugiDesc7`,
          image: "block37"
        },
        {
          title: `extraUslugiDesc8`,
          image: "block38"
        }

      ],
      block4: [
        {
          title: "etapRabotiTitle1",
          desc: "etapRabotiDesc1"
        },
        {
          title: "etapRabotiTitle2",
          desc: "etapRabotiDesc2"
        },
        {
          title: "etapRabotiTitle3",
          desc: "etapRabotiDesc3"
        },
        {
          title: "etapRabotiTitle4",
          desc: "etapRabotiDesc4"
        },
        {
          title: "etapRabotiTitle5",
          desc: "etapRabotiDesc5"
        }
        
      ],

      block5: [
        {
          icon: "icon1",
          image: "block51",
          title: "whyUsTitle1",
          desc: "whyUsDesc1"
        },
        {
          icon: "icon2",
          image: "block52",
          title: "whyUsTitle2",
          desc: "whyUsDesc2"
        },
        {
          icon: "icon3",
          image: "block53",
          title: "whyUsTitle3",
          desc: "whyUsDesc3"
        },
        {
          icon: "icon4",
          image: "block54",
          title: "whyUsTitle4",
          desc: "whyUsDesc4"
        },
        {
          icon: "icon5",
          image: "block55",
          title: "whyUsTitle5",
          desc: "whyUsDesc5"
        },
        {
          icon: "icon6",
          image: "block56",
          title: "whyUsTitle6",
          desc: "whyUsDesc6"
        }
      ],
      block6: [
        {
          image: "block6Back",
          title: "forWhoTitle1",
          color: "#FEC50C"
        },
        {
          image: "block6Back2",
          title: "forWhoTitle2",
          color: "#E80707"
        },
        {
          image: "block6Back3",
          title: "forWhoTitle3",
          color: "#283043"
        }
      ],

      block7: [
        {
          title: "weCargo1",
          image: "image1"
        },
        {
          title: "weCargo2",
          image: "image2"
        },
        {
          title: "weCargo3",
          image: "image3"
        },
        {
          title: "weCargo4",
          image: "image4"
        },
        {
          title: "weCargo5",
          image: "image5"
        },
        {
          title: "weCargo6",
          image: "image6"
        }
      ]
    }
  }
};
</script>

<style scoped lang="scss">
.block7 {
  margin-bottom: 90px;
   @media screen and (max-width: 768px) {
   margin-bottom: 0;
   }
  &__content {
      margin-top: 94px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 11.04%;
      justify-content: center;
      row-gap: 154px;
      @media screen and (max-width: 768px) {
        margin-top: 64px;
          column-gap: 8%;
          row-gap: 66px;
      }
      @media screen and (max-width: 512px) {
          row-gap: 36px;
      }
      &__card {
        position: relative;
        padding: 34px 24px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        // height: 225px;
        height: 261px;
        width: 21.44%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          width: 46%;
          height: 250px;
        }
        @media screen and (max-width: 512px) {
          padding: 34px 28px;
          width: 46%;
          height: 165px;
        }
        &-succ {
          position: absolute;
          top: 0;
          right: 50%;
          transform: translate(50%, -50%);
          width: min(max(40px, calc(2.5rem + ((1vw - 3.2px) * 1.7857))), 60px);
          height: min(max(40px, calc(2.5rem + ((1vw - 3.2px) * 1.7857))), 60px);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #FEC50C;
          img {
            @media screen and (max-width: 768px) {
              width: 50%;
            }
          }
        }
        &-img {
          // width: 100%;
          // height: 56%;
        }
        &-p1 {
          line-height: 20px;
          height: 38px;
          margin-top: 10px;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          color: #000000;
          font-weight: 500;
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.8036))), 20px);
          @media screen and (max-width: 768px) {
            height: 12px;
          }
          @media screen and (max-width: 450px) {
            line-height: 15px;
            height: 17px;
          }
        }
      }
  }
}
.block6 {
  margin: 85px 0;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 992px) {
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    margin-bottom: 0;
  }
  &__content {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    gap: 5.28%;
    @media screen and (max-width: 992px) {
      margin-top: 30px;
    }
    &__card {
      width: 21.6%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 234px;
      @media screen and (max-width: 992px) {
        width: 100%;
        background-size: 100% 60%;
      }
      &-p1 {
        color: white;
        font-size: min(max(25px, calc(1.5625rem + ((1vw - 3.2px) * 2.2321))), 50px);
      }
      &-p2 {
        margin-top: 36px;
          font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 1.6071))), 30px);
         @media screen and (max-width: 992px) {
          margin-top: 22px;
         }
      }
    }
  }
  &__bot {
    margin-top: -50px;
    margin-left: 6px;
    background-position-x: center;
    color: white;
    font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 1.25))), 30px);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 116px;
    background-image: url('@/assets/images/block6BackBot.png');
    background-repeat: no-repeat;
    background-size: auto;
    @media screen and (max-width: 992px) {
      margin-top: -125px;
      background-size: 100% 60%;
    }
    p {
      line-height: 28px;
      font-size: min(max(10.5px, calc(0.65625rem + ((1vw - 3.2px) * 1.7411))), 30px);
      margin-top: 25px;
      @media screen and (max-width: 992px) {
      margin-top: -24px;
        line-height: 16px;
      }
    }
  }
}
.block5 {
  &__content {
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 57px;
    row-gap: 111px;
    @media screen and (max-width: 992px) {
        justify-content: flex-start;
        column-gap: 6%;
        row-gap: 60px;
    }
    &__card {
      border-radius: 10px;
      box-shadow: 0px 4px 12px 10px rgba(0, 0, 0, 0.25);
      background-size: cover;
      position: relative;
      width: 28%;
      height: 280px;
      background-repeat: no-repeat;
      background-position-x: 40%;
      transition: all 0.5s ease;
      @media screen and (max-width: 992px) {
        width: 47%;
        height: 150px;
      }
      &:before {
        content: "";
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        background-color: black;
          opacity: 0.5;
      }
      &:hover {
        transform: scale(1.085);
        .block5__content__card-line {
          width: 28.14%;
        }
        &:before {
          opacity: 0.7;
        }
        .block5__content__card-box {
          height: 82px;
          width: 82px;
          @media screen and (max-width: 992px) {
            width: min(max(62px, calc(3.875rem + ((1vw - 3.2px) * 2.6786))), 92px);
            height: min(max(62px, calc(3.875rem + ((1vw - 3.2px) * 2.6786))), 92px);
          }
        }
      }
      &-line {
        position: relative;
        z-index: 1;
        margin-top: 62px;
        margin-left: 17px;
        width: 25.14%;
        height: 5px;
        background: #FEC50C;
        transition: all 0.5s ease;
        @media screen and (max-width: 992px) {
          margin-left: 13px;
          height: 3px;
          margin-top: 35px;
        }
      }
      &-box {
        transition: all 1s ease;
        z-index: 1;
        position: absolute;
        top: 0;
        right: 50%;
        transform: translate(50%, -50%);
        width: min(max(62px, calc(3.875rem + ((1vw - 3.2px) * 2.6786))), 92px);
        height: min(max(62px, calc(3.875rem + ((1vw - 3.2px) * 2.6786))), 92px);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: red;
        img {
          @media screen and (max-width: 992px) {
            width: 50%;
          }
        }
      }
      &-p1 {
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.625))), 16px);
        line-height: 21px;
        position: relative;
        z-index: 1;
        margin-top: 21px;
        margin-left: 17px;
        text-align: start;
        color: #FEC50C;
        @media screen and (max-width: 992px) {
          line-height: 18px;
          margin-left: 13px;
          font-weight: 600;
          margin-top: 0;
        }
      }
      &-p2 {
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.625))), 16px);
        z-index: 1;
        line-height: 21px;
        position: relative;
        font-weight: 500;
        color: white !important;
      }
    }
  }
}
.block4 {
  margin: 35px 0 100px 0;
  @media screen and (max-width: 992px) {
    margin: 35px 0 30px 0;
    margin-left: auto;
    margin-right: auto;
  }
  &-p1 {
    color: black;
    font-size: min(max(20.75px, calc(1.296875rem + ((1vw - 3.2px) * 1.3616))), 36px);
    font-weight: 500;
  }
  &-p2 {
    color: black;
    font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.3571))), 20px);
  }
  &-line {
    margin-top: 92px;
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    height: 2px;
    background: black;
    margin-bottom: -45px;
    @media screen and (max-width: 768px) {
      position: relative;
      left: 12%;
      z-index: -1;
      height: 350px;
      margin: auto;
      width: 1px;
      background: black;
    }
    @media screen and (max-width: 512px) {
      left: 8%;
    }
  }
  &__content {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 992px) {
      width: 100%;
      margin-top: 36px;
      flex-direction: column;
      gap: 16px;
    }
    &__card {
      width: 12.72%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      @media screen and (max-width: 992px) {
        width: 100%;
        flex-direction: initial;
      }
      &-number {
        font-weight: 600;
        font-size: min(max(22px, calc(1.375rem + ((1vw - 3.2px) * 1.1607))), 35px);
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FEC50C;
        border-radius: 50%;
        width: min(max(62px, calc(3.875rem + ((1vw - 3.2px) * 2.6786))), 92px);
        height: min(max(62px, calc(3.875rem + ((1vw - 3.2px) * 2.6786))), 92px);
      }
      &__text {
        @media screen and (max-width: 992px) {
          width: 70%;
          text-align: start;
        }
        &-p1 {
            font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.3571))), 20px);
          color: #E80707;
          font-weight: 600;
        }
        &-p2 {
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.1786))), 13px);
          font-weight: 500;
          color: #000000;
        }
      }
    }
  }
}
.block3 {
  &-title {
    font-weight: 500;
    margin: 96px 0 54px 0;
    font-size: min(max(20.75px, calc(1.296875rem + ((1vw - 3.2px) * 1.3616))), 36px);
    color: black;
    @media screen and (max-width: 992px) {
      margin: 30px 0 35px;
    }
  }
  &__back {
    padding: 58px 0 88px 0;
    background-image: url('@/assets/icons/block3/block3Background.png');
     @media screen and (max-width: 992px) {
      padding: 26px 0 36px 0;
    }
  }
  &__content {
      display: flex;
      flex-wrap: wrap;
      row-gap: 133px;
      column-gap: 59px;
      justify-content: center;
      @media screen and (max-width: 992px) {
        row-gap: 25px;
        column-gap: 4%;
      }
      &__card {
        width: 19.72%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 15px;
        @media screen and (max-width: 992px) {
          width: 46.72%;
        }
        &-title {
            font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.2679))), 15px);
          color: white;
        }
        
        &-img {
          @media screen and (max-width: 768px) {
            width: 21%;
          }
        }
      }
  }
}
.block2 {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
    margin-top: 33px;
    gap: 10px;
  }
  &__content {
    position: relative;
    border-radius: 5px;
    height: 222px;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24.08%;
    gap: 52px;
    transition: all 0.5s ease;
    @media screen and (max-width: 992px) {
      width: 48%;
      height: 150px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.3;
    }
    &-p1 {
      @media screen and (max-width: 992px) {
        width: 190px;
        height: 5px;
      }
      text-transform: uppercase;
      height: 37px;
      z-index: 1;
      color: white;
      font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.625))), 20px);
    }
    &-button {
      margin-bottom: -52px;
      z-index: 1;
      color: white;
      font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.1786))), 13px);
      font-size: 13px;
      border-bottom: 1px solid white;
      transition: all 0.5s ease;
      @media screen and (max-width: 992px) {
        margin-bottom: -28px;
      }
    }
      a {
        text-decoration: none !important;
        color: white !important;
      }
    &:hover {
      transform: scale(1.08);
      &:before {
        opacity: 0.1;
      }
    }
    &:hover &-button {
      border-bottom: 1px solid transparent;
      cursor: pointer;
      padding: 0 19px;
      border-radius: 5px;
      background: #FEC50C;
      color: red;
      transform: scale(1.08);
    }
  }
}
.mainTextDesc {
    font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.8036))), 22px);
    font-weight: 300;
    font-style: normal;
    line-height: 40px;
    width: 120%;
    @media screen and (max-width: 992px) {
      line-height: 16px;
      width: 85%;
    }
}
.w22-800 {
    font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 0.4301))), 22px);
}
.homeBanner1 {
  position: relative;
}

.forma2 {
  position: relative;
}
.forma1 {
  position: absolute;
  right: 0;
  @media screen and (max-width: 992px) {
    position: static;
  }
}
.etap {
  box-sizing: border-box;
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 992px)  {
    height: 367px;

  }
  &-title {
     position: relative;
    z-index: 1;
    display: flex;
    font-weight: 400;
    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 2.3656))), 46px);
    color: #161616;
    @media screen and (max-width: 992px) {
      font-size: 26px;
      font-weight: 550;
    }
  }
  &-desc {
     position: relative;
    z-index: 1;
    width: 26.48%;
    text-align: start;
    margin-top: 15px;
    display: flex;
    font-weight: 600;
    font-size: 20px;
     @media screen and (max-width: 992px) {
      font-weight: 550;
      width: 67%;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.4301))), 16px);
     }
  }
  &-button {
    position: relative;
    z-index: 1;
    cursor: pointer;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    padding: 0 49px;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.6452))), 20px);
    align-items: center;
    background: #E80707;
    color: #FEC50C;
    box-shadow: 0px 4px 14px rgba(255, 95, 95, 0.7);
    border-radius: 45px;
    height: 57px;
    transition: all 0.5s ease;
    &:hover {
      background: white;
      color: red;
    }
  }
  .etapppe {
    position: relative;
    margin-top: -185px;
    @media screen and (max-width: 992px) {
      margin-top: 0;
    }
  }
  &-image {
    position: relative;
    user-select: none;
     @media screen and (max-width: 992px) {
      width: 100%;
     }
  }
  &-p1 {
    text-align: start;
    font-weight: 600; 
    font-size: min(max(5.5px, calc(0.34375rem + ((1vw - 3.2px) * 1.5591))), 20px);
    position: absolute;
    z-index: 1;
    left: 4%;
    top: 48%
  }
  &-p2 {
    text-align: start;
    font-weight: 600; 
    font-size: min(max(5.5px, calc(0.34375rem + ((1vw - 3.2px) * 1.5591))), 20px);
    position: absolute;
    z-index: 1;
    left: 45%;
    top: 23%
  }
  &-p3 {
    font-weight: 600; 
    font-size: min(max(5.5px, calc(0.34375rem + ((1vw - 3.2px) * 1.5591))), 20px);
    position: absolute;
    z-index: 1;
    left: 76%;
    top: 7%
  }
  &-p4 {
    font-weight: 600; 
    font-size: min(max(5.5px, calc(0.34375rem + ((1vw - 3.2px) * 1.5591))), 20px);
    position: absolute;
    z-index: 1;
    left: 76%;
    top: 68%
  }
}
.home {
  width: 100%;
  @media screen and (max-width: 992px) {
    margin-top: 76px;
  }
  .home__intro-title{
    width: 50%;
    font-weight: 500;
    font-size: 56px;
    line-height: 55px;
    line-height: 55px;
  }
  &__intro{
    display: flex;
  }
  .truck1 {
    position: absolute;
    bottom: -0px;
    width: 42%;
    left: 0;
    @media screen and (max-width: 992px) {
        width: 80%;
        bottom: -0px;
        left: -8%;
    }
  }
  &__intro {
    position: relative;
    background: url("@/assets/images/mainBannerNew.png") no-repeat 50%;
    width: 100%;
    background-position-y: 75%;
    background-size: 100% 100%;
    padding-top: 144px;
    height: 785px;
    @media screen and (max-width: 992px) {
      height: 395px;
      padding-top: 65px;
    }


    &-send {
      background: #E80707;
      color: #FEC50C;
      box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
      border-radius: 5px;
      padding: 0 49px;
        font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
      height: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: auto;
      transition: all 0.5s ease;
      &:hover {
        background: white;
        color: red;
      }
    }
  }

  &-img {
    padding: 100px 0;

    img {
      width: 100%;
    }
  }

  .services {
    display: flex;
    @media screen and (max-width: 992px) {
      justify-content: unset !important;
    }
    .service-item {
      position: relative;
      width: 25%;
      &__text {
        position: absolute;
        top: 55%;
        left: 0;
        width: 100%;
      }
      img {
        margin-left: -3%;
      }
    }
    .boat img {
      width: 103.6%;
      @media screen and (max-width: 992px) {
        width: max-content;
      }
    }

    .service__block{
      display: flex;
      justify-content: space-between;
      width: 50%;
    }

    .service-icon {
      padding: 35px 0 35px 35px;

      img {
        width: 100%;
      }
    }

    .service-title {
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6452))), 23px);
      line-height: 27px;
      text-align: center;
      color: #FFFFFF;
      padding: 10px 33px;
    }
    .service-link {
      background: #FFFFFF;
      box-shadow: 0px 4px 14px rgba(236, 71, 64, 0.3);
      border-radius: 5px;
      color: red;
      font-weight: 600;
      padding: 0 49px;
      height: 47px;
      transition: background 0.5s ease;
      @media screen and (max-width: $tablet + px) {
        height: 37px;
        padding: 0 37px;
      }
      &:hover {
        background: red;
        color: white;
        box-shadow: 2px 1px 10px 2px rgba(255, 0, 0, 0.2);

      }
    }
  }
  @media (max-width: 1200px){}
  @media (max-width:992px){
    /*-------------Intro----------------*/

    .home__intro__form{
      width: 80%;

    }
    .home__intro__form p{
      text-align: center;
    }
    .home__intro-title{
      text-align: center;
      width: 100%;
    font-size: min(max(32px, calc(2rem + ((1vw - 3.2px) * 2.5806))), 56px);
      @media screen and (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: start;
        font-weight: 500;
        text-align: start;
        line-height: 38px;
      }
    }
    .home-img {
      padding: 50px 0;
    }
    /*-------------Intro----------------*/

    /*---------------Service---------------*/
    .service-item{
      width: 49% !important;
    }
    .service-item img {
      margin-left: 0;
      width: max-content;
    }
    .services{
      flex-wrap: wrap;
      gap: 10px;
      @media screen and (max-width: 992px) {
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll; 
        padding: 20px;
        margin-left: -10px;

        &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
                border: transparent;
                color: transparent;
                outline: none;
            }

            &::-webkit-scrollbar-thumb {
                height: 0px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 0px;
                height: 0px;
            }
      }
    }
    .service__block{
      width: 100% !important;
    }

    /*---------------Service---------------*/
  }
  @media (max-width: 772px){
    /*-------------Intro----------------*/
    .home__intro__form p{

    }
    /*-------------Intro----------------*/
  }
  @media (max-width: 577px){
    .home-img {
      padding: 30px 0;
    }
    .service-item{
      width: 65% !important;
    }
  }
}
.block4MobBLock {
  display: flex;
}
</style>
