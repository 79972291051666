<template>
  <main class="routes w100">
    <div class="routes__intro container">
      <div class="w100">
        <div class="routes__content">
          <div class="routes-title b46-700">{{ $t('ourMarsh') }}</div>
        </div>
      </div>
    </div>
    <InternationalFreight />
    <KZFreight />
  </main>
  <OstQues />
</template>

<script>

import OstQues from "@/components/OstQues.vue";
import InternationalFreight from "@/modules/routes/components/InternationalFreight";
import KZFreight from "@/modules/routes/components/KZFreight";

export default {
  components: { InternationalFreight, KZFreight, OstQues },
  name: "OurRoutesPage",
};
</script>

<style scoped lang="scss">
.container {
  @media screen and (max-width: 992px) {
    width: 80%;
  }
}
.routes {
  width: 100vw;
  padding: 74px 0;
  @media screen and (max-width: 992px) {
    margin-top: 76px;
    padding: 29px 0 0px 0;
  }
  &__intro {
    //margin-top: 74px;
  }
  //
  &-title {
    margin-bottom: 7px;
    display: flex;
      font-size: min(max(28px, calc(1.75rem + ((1vw - 3.2px) * 1.6071))), 46px) !important;
    color: #161616;
      font-weight: 400;
    @media screen and (max-width: 992px) {
      display: flex;
      margin-bottom: 16px;
      font-size: 30px;
      color: #161616;
    }
  }
}
</style>
