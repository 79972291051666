<template>
  <div class="details">
    <div class="details__intro">
      <img :src="require('@/assets/icons/track1.png')" class="truck1"/>
      <div class="container">
        <div class="row">
          <div class="details1 col-6 ">
            <div class="details-title w46-600" style="font-weight: 400;">
              {{ $t('ourServ1') }}
            </div>
            <div class="col-10">
              <p class="details-desc w14-400 text-start" v-html="$t('furaDesc')">
              </p>
            </div>
            <div class="details-feedback-btn d-flex justify-content-start">
              <button class="w16-700 feedback-btn" data-bs-toggle="modal" data-bs-target="#orderForm">
                {{ $t('leaveRequest') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class=container>
      <div class="railway-benefits borderBlock">
      <div class="railway-title b36-700 text-uppercase trainDesc2">{{ $t('furaDesc2') }}</div>
      <div class="row d-flex justify-content-between align-items-center w100 delif">
        <div class="col-6 railway-left-benefits col1">
          <ul class="timeline">
            <li class="event">
              <p>
                {{ $t('fura.desc1') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('fura.desc2') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('fura.desc3') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('fura.desc4') }}
              </p>
            </li>
            <div class="timeline1-border"></div>
          </ul>
        </div>
        <div class="col-6 col2">
          <ul class="timeline">
             <li class="event">
              <p>
                {{ $t('fura.desc5') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('fura.desc6') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('fura.desc7') }}
              </p>
            </li>
            <div class="timeline2-border"></div>
          </ul>
        </div>
      </div>
      <p v-html="$t('noticeForClientFura')" class="noticeClient">
      </p>
    </div>
    </div>

    <div class="mobile"><p class="gruz-title">{{ $t('outServBack') }}</p></div>
    <div class="back-gruz">
       <div class="gruz container">
        <div class="gruzTitleDesctop"><p class="gruz-title">{{ $t('outServBack') }}</p></div>
        <div class="gruz-gap">
          <div class="gruz__box">
              <p class="gruz__box-p1">{{ $t('gazel') }}</p>
              <p class="gruz__box-p2">{{ $t('outServDesc1v1') }}</p>
              <p class="gruz__box-p2 redline3">{{ $t('outServDesc2v1') }}</p>
              <img class="gruz__box-img1" :src="require('@/assets/images/Ground1.png')"/>
          </div>
          <div class="gruz__box">
              <p class="gruz__box-p1">{{ $t('furgon') }}</p>
              <p class="gruz__box-p2">{{ $t('outServDesc1v2') }}</p>
              <p class="gruz__box-p2 redline3">{{ $t('outServDesc2v2') }}</p>
              <img class="gruz__box-img" :src="require('@/assets/images/Ground2.png')"/>
          </div>
          <div class="gruz__box">
              <p class="gruz__box-p1 gruz__box-p13">{{ $t('evrofura') }}</p>
              <p class="gruz__box-p2 gruz__box-p23">{{ $t('outServDesc1v3') }}</p>
              <p class="gruz__box-p2 gruz__box-p23 redline3">{{ $t('outServDesc2v3') }}</p>
              <img class="gruz__box-img" :src="require('@/assets/images/Ground3.png')"/>
          </div>
        </div>
      </div>
    </div>

    <div class="advantBack">
      <div class="advant">
          <p class="advant-title">{{ $t('furaDesc3') }}</p>
          <div class="advant__content">
              <div class="advant__content__box advant__content__box1">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/wallet.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('fura.preTitle1') }}</p>
                <p class="advant__content__box-p2" v-html="$t('fura.preDesc1')"></p>
              </div>
              <div class="advant__content__box advant__content__box2">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/earth.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('fura.preTitle2') }}</p>
                <p class="advant__content__box-p2" v-html="$t('fura.preDesc2')"></p>
              </div>
              <div class="advant__content__box advant__content__box3">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/navigator.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('fura.preTitle3') }}</p>
                <p class="advant__content__box-p2" v-html="$t('fura.preDesc3')"></p>
              </div>
              <div class="advant__content__box advant__content__box4">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/container.png')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('fura.preTitle4') }}</p>
                <p class="advant__content__box-p2" v-html="$t('fura.preDesc4')"></p>
              </div>
          </div>
      </div>
    </div>

  </div>
  <OstQues />
  <ModalOrderForm />
</template>
<script>
import OstQues from "@/components/OstQues.vue";
import ModalOrderForm from "@/modules/app/views/layout/main/ModalOrderForm";
export default {
  name: "GroundDetails",
  components: { ModalOrderForm, OstQues }
};
</script>
<style lang="scss" scoped>

.advantBack {
  padding: 52px 0 95px 0;
  background: #F3F3F3;
  @media screen and (max-width: 770px) {
    background: white;
  }
}
.advant {
  width: 1330px;
  margin: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $laptop + px) {
      width: 1140px;
  }

  @media screen and (max-width: 1200px) {
    width: 960px;
  }

  @media screen and (max-width: $tablet + px) {
    width: 720px;
  }

  @media screen and (max-width: $mobile + px) {
    width: 95%;
  }
  &-title {
    margin-bottom: 36px;
    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.4286))), 36px);
    color: black;
    font-weight: 400;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 770px) {
        flex-direction: column;
        gap: 32px;
    }
    &__box {
      width: 23%;
      display: flex;
      flex-direction: column;
      gap: 14px;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
      &-img {    
        width: 100%;
        display: flex;
        height: 83px;
        justify-content: center;
        align-items: center;
      }
      &-p1 {
        font-weight: 500;
        font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0))), 17px);
        text-transform: uppercase;
        color: #161616;
      }
      &-p2 {
        text-align: start;
        font-size: 15px;
        color: #161616;
        font-weight: 400;
        @media screen and (max-width: 770px) {
          text-align: center;
          width: 82%;
          margin: auto;
        }
       
      }
    }
    &__box1 {
      width: 24.1%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
    &__box2 {
      width: 21.13%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
    &__box3 {
      width: 24.36%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
    &__box4 {
      width: 23.98%;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
    }
  }
}

.gruzTitleDesctop {
  p {
    color: transparent !important;
  }
}
.timeline {
  border-left: 0px solid red !important;
}
.timeline-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(89.5%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
  }

.event {
      font-size: 16px !important;
}

.b36-700  {
    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.2903))), 36px) !important;
}

.details1 {
  position: absolute;
  top: 0;
  margin-top: 100px;
  width: 45.68%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 40px;
  }
}

.gruzEx {
  margin-top: 138px;
  width: 100%;
  margin-bottom: 120px; 
  @media screen and (max-width: 992px) {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
    height: 265px;
    -o-object-fit: cover;
    object-fit: contain;
  }  
}
.back-gruz {
  // height: min(max(265px, calc(16.5625rem + ((1vw - 3.2px) * 14.5161))), 400px);
  // height: min(max(259px, 16.1875rem + (1vw - 3.2px) * 15.1613), 430px);

  height: min(max(190px, calc(11.875rem + ((1vw - 3.2px) * 21.4286))), 430px);
  // margin-top: 138px;
  background-image: url('@/assets/images/outServ1Image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  @media screen and (max-width: 992px) {
    background-position: unset;
  }
  @media screen and (max-width: 330px) {
    height: 225px;
  }
}
.gruz {
  position: relative;
  top: 42px;
  @media screen and (max-width: 992px) {
    top: 32px;
  }
  &-title {
    color: black;
    display: flex;
    margin-bottom: 45px;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    text-transform: none;
    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.4286))), 36px);
    @media screen and (max-width: 992px) {
      margin-bottom: 20px;
    }
  }
  &-gap {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 992px) {
      gap: 5%;
    }
  }
  &__box {
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: 5px;
    &-p1 {
      text-transform: uppercase;
      font-weight: 550;
      font-size: min(max(8px, calc(0.5rem + ((1vw - 3.2px) * 1.2903))), 20px);
    }
    &-p13 {
      margin-left: 35%;
      @media screen and (max-width: 992px) {
        margin-left: 0%;
      }
    }
    &-p23 {
      margin-left: 35%;
      @media screen and (max-width: 992px) {
        margin-left: 0%;
      }
    }
    &-p2 {
      font-size: min(max(7.5px, calc(0.46875rem + ((1vw - 4px) * 1.2353))), 18px) !important;
      font-weight: 500;
      font-size: 18px;
      height: 23px;
      @media screen and (max-width: 992px) {
        height: 22px;
      }
    }
    .redline3 {
        border-bottom: 1px dashed  red;
    }
    &-img1 {
      margin-top: 20px;
      @media screen and (max-width: 992px) {
        width: 80%;
        margin-top: 0;
      }
    }
    &-img {
      margin-top: 25px;
      @media screen and (max-width: 992px) {
        width: 80%;
        margin-top: 0;
      }
    }
  }
}
.details {
  @media screen and (max-width: 992px) {
    margin-top: 76px;
  }
  &__intro {
    position: relative;
    height: 600px;
    background: url("@/assets/images/bg/ground.png") bottom no-repeat;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-position-y: 70%;
    padding-top: 150px;
    padding-bottom: 55vh;
    position: relative;
    @media screen and (max-width: 992px) {
      height: 393px;
    }
  }
  .truck1 {
    position: absolute;
    bottom: -140px;
    right: 0;
    @media screen and (max-width: 992px) {
      width: 60%;
      bottom: -55px;
      right: 0px;
    }
  }
  &__intro::before {
    position: absolute;
    background: url("@/assets/images/our-services/ground/car.png") center no-repeat;
    background-size: cover;
  }
  &__content {
    width: 50%;
  }
  &-img {
    width: 50%;
    margin-right: 37px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &-title {
    font-size: min(max(33px, calc(2.0625rem + ((1vw - 3.2px) * 1.3978))), 46px) !important;
    line-height: 56px;
    margin-bottom: 25px;
    // margin-top: 140px;
    text-transform: uppercase;
    @media screen and (max-width: 992px) {
      font-weight: 550;
      line-height: 44px;
      margin-bottom: 10px;
      margin-top: 0;
    }
  }
   &-desc {
    line-height: 25px;
    margin-bottom: 25px;
    font-size: min(max(13.5px, calc(0.84375rem + ((1vw - 3.2px) * 0.7589))), 22px);
    @media screen and (max-width: 992px) {
      font-weight: 400;
      line-height: 25px;
      color: #FFFFFF;
      margin-bottom: 5px;
    }
  }
  &-feedback-btn {
    button {
      padding: 0 49px;
      height: 57px;
      background: #E80707;
      color: #FEC50C;
      box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
      font-weight: 400;
      font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px);
      border-radius: 5px;
      transition: all 0.5s ease;
      @media screen and (max-width: 992px) {
        margin-top: 20px;
        font-weight: 550;
      }
    }
  }


  .benefits {
    padding-top: 100px;
    .benefits-title {
      margin-bottom: 39px;
      font-weight: 550;
      font-size: 36px;
      display: flex;
      color: #161616;
      @media screen and (max-width: 992px) {
      text-align: start;
      align-items: flex-start;
      font-weight: 550;
      font-size: 26px;
      line-height: 31px;
      margin-bottom: 21px;
    }
    }
    &__content {
      margin-bottom: -50px;
    }
  }

  .car-types {
    background: url("@/assets/images/our-services/ground/cars.svg") center no-repeat;
    background-size: cover;
    padding-top: 43px;
    width: 1440px;
    height: 400px;
  }


  @media (max-width: 1400px){}
  @media (max-width: 992px){
    .details{
      width: 100%;
    }
    .feedback-btn{
      // padding: 5px 44px;
    }
    .benefits-title{
      font-size: 24px;
    }
    .timeline{
      font-size: 14px;
    }
  }
  @media (max-width: 772px){
    .benefits{
      align-items: flex-start !important;
    }
    .benefits-title{
      font-size: 22px;
    }
    .timeline{
      font-size: 12px;
    }
  }
  @media (max-width: 577px){
    .details{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &-feedback-btn{
        
      }
      &__intro{
        padding-top: 55px;
        padding-bottom: 200px;
      }
      // &-desc{
      //   font-size: 12px;
      //   text-align: left;
      // }
    }
    .details-title{
      font-size:36px;
    }
    .details-img img{
      width: 270px;
      height: 110px;
    }
    .timeline{
      font-size: 10px;
    }
    .benefits{
      flex-direction: column;
      margin-left: 30px;
       @media screen and (max-width: 992px) {
        margin-left: 0; 
    }
    }
    .benefits__content{
      width: 80%;
       @media screen and (max-width: 992px) {
        margin-top: 25px;
        width: 100%;
       }
    }
    .benefits-title{
      font-size: 24px;
    }
    .timeline{
      font-size: 14px;
    }
  }
}
.furaDesc2 {
  align-items: start !important;
  flex-direction: column;
  &__text {
    text-align: start;
  }
}
.procentFura {
  width: 100%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.pluses {
    background: #F3F3F3;
    padding: 70px 0;
    @media screen and (max-width: 992px) {
      padding: 50px 0;
    }
    .title {
      margin-bottom: 32px;
      @media screen and (max-width: 992px) {
        margin-left: 0;
        margin-bottom: 25px;
      }
    }
    .plus-title {
      width: max-content;
      font-weight: 550;
        font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.3226))), 20px);
      line-height: 22px;
      margin: 10px 0;
      margin: 10px 0;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 992px) {
        font-weight: 550;
        line-height: 22px;
        margin: 10px 0;
        text-align: center;
      }
    }
    .plus-icon {
      height: 120px;
      @media screen and (max-width: 992px) {
        height: auto;
      }
    }
    .premush {
      text-transform: none !important;
      justify-content: center;
      display: flex;
      font-weight: 400;
       font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.0714))), 36px) !important;
      line-height: 31px;
    }
    .premush1 {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        gap: 30px;
      }
    }
    .benefit-item  {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 15.5%;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    }
  }

 .noticeClient {
  margin-top: 15px;
  font-size: 16px !important;
  text-align: center;
  color: black;
  font-weight: 500;
}

.col-10 {
  @media screen and (max-width: 776px) {
    width: 100%;
  }
}

.trainDesc2 {
  margin-bottom: 56px;
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: min(max(21.5px, calc(1.34375rem + ((1vw - 3.2px) * 1.2946))), 36px) !important;
  @media screen and (max-width: 992px) {
    text-align: center;
  }
}

.delif {
  align-items: start !important;
  flex-wrap: nowrap !important;
  @media screen and (max-width: 992px) {
    --bs-gutter-x: 0rem;
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
  }
}
.col1 {
  width: 53%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.col2 {
  width: 54%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.timeline1-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(86%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
      @media screen and (max-width: 768px) {
        height: min(max(105%, 5.125rem + (1vw - 4.1%) * 2.1429), 105%);
      }
  }
  .timeline2-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(70%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
      @media screen and (max-width: 768px) {
        height:  min(max(70%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      }
  }
  .borderBlock {
    margin: 52px 0 37px 0;
  }
  .avia {
    &-title {
        font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
    }
    &-desc {
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.3571))), 13px);
    }
  }
  .aviaContainer {
    width: 80%;
    margin: auto;
  }

  .timeline {
    p {
       font-weight: 500;
    }
  }
  .plus-desc {
    width: 165%;
    text-align: center !important;
    @media screen and (max-width: 768px) {
      width: 100%;
    }

  }
</style>