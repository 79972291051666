<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="callMeForm"
    tabindex="-1"
    aria-labelledby="callMeForm"
    aria-hidden="true"
    ref="myModal"
  >
    <div class="modal-dialog">
      <div class="modal-content login p-0"  v-click-outside="resetModalData">
        <div
          class="login__intro d-flex justify-content-center align-items-center"
        >
          <div class="login-form">
            <div>
              <div class="form-title">{{ $t("orderPhone") }}</div>
              <div class="form-body">
                <div class="data-inputs">
                  <input
                    type="text"
                    :placeholder="$t('name') + ' ' + '*'"
                    v-model="v$.name.$model"
                    @keyup="onlyText()"
                  />
                  <template
                    v-for="(error, index) of v$.name.$errors"
                    :key="index"
                  >
                    <p class="errorValid">{{ error.$message }}</p>
                  </template>

                  <input
                    type="text"
                    :placeholder="$t('phone') + ' ' + '*'"
                    v-model="v$.phone.$model"
                    v-imask="phoneMask"
                    @input="isNumber"
                    @accept="onAccept"
                    @complete="onComplete"
                  />
                  <template
                    v-for="(error, index) of v$.phone.$errors"
                    :key="index"
                  >
                    <p class="errorValid">{{ error.$message }}</p>
                  </template>
                </div>
              </div>
              <div class="form-actions text-center">
                <button class="save-btn" @click="orderPhone">
                  {{ $t("send") }}
                </button>
                <!-- data-bs-dismiss="modal" -->
              </div>
            </div>
          </div>
        </div>

        <a href="#" class="close" data-bs-dismiss="modal">
          <img
            class="closeIcon"
            :src="require('@/assets/icons/closeIcon.svg')"
            aria-label="Close"
            aria-hidden="true"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";

import { IMaskDirective } from "vue-imask";
import { ref } from 'vue';

export default {
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      v$: useVuelidate(),
      name: "",
      phone: "",
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        minLength: helpers.withMessage(
          this.$t("errors.nameError"),
          minLength(3)
        ),
      },
      phone: {
        required: helpers.withMessage(this.$t("errors.required1"), required),
        minLength: helpers.withMessage(
          this.$t("errors.telLength"),
          minLength(18)
        ),
      },
    };
  },  
  methods: {
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error("Такого мобильного оператора не существует!");
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      console.log("complete", maskRef.unmaskedValue);
    },

    
    onlyText() {
      this.name = this.name.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function(match) {
        return match.substr(0, 1);
      });
    },

    resetModalData() {
      this.name = "",
      this.phone = "",
      this.v$.$reset()
    },

    

    async orderPhone() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        this.v$.$validate();
        const orderData = {
          name: this.name,
          phone: this.phone,
        };
        await this.$axios
          .post("v1/mail/call", orderData)
          .then((res) => {
            this.$toaster.success("Ваша заявка успешно отправлена!");
            this.$refs.myModal.querySelector('[data-bs-dismiss="modal"]').click();
            this.resetModalData();
            this.v$.$reset()
          })
          .catch((err) => {
            this.$toaster.error("Неверные данные");
          });
      } else {
        this.$toaster.error("Заполните обязательные поля, пожалуйста!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.errorValid {
  text-align: start;
  font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
  margin-top: -10px;
  padding-bottom: 10px;
  margin-left: 16px;
  color: red;
}

.model-content {
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 5px;
  transition: 0.5s ease;
  &:hover {
    transform: scale(1.15);
  }
  @media screen and (max-width: 992px) {
    right: 5px;
  }
}
.modal-dialog {
  margin-top: 5.5%;
  @media screen and (max-width: 992px) {
    margin-top: 22%;
  }
}

.login-form {
  padding: 35px 70px 70px 70px;
  width: 500px;
  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 15px 20px 20px 20px;
  }
}
.save-btn {
  font-weight: 400 !important;
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
  width: 100% !important;
  height: 45px !important;
}
.form-title {
  margin-bottom: 25px !important; 
}
</style>