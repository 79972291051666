<template>
  <header class="header desctop" id="header">
    <div class="container flex-row-between-center">
      <div class="header-left flex-row-between-center b15-500">
        <div class="langClass">
            <SelectComponent
                :type="'lang'"
                :options="languages"
                @select="getLang">
          </SelectComponent>
        </div>
        <div class="hoverPuncts"><router-link to="/about-company" class="header__nav" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : 'min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.4464))), 16px)'}">{{ $t("aboutCompany") }}</router-link></div>
        <div class="position-relative nav_service hoverPuncts hoverPunctsUslig">
          <a href="#" class="header__nav0" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : 'min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.4464))), 16px)'}">{{ $t("ourServices") }}</a>
          <div class="service_menu">
            <div>
              <router-link to="/railway-freight" class="header__nav1">{{ $t("ourServ2") }}</router-link>
            </div>
            <div>
              <router-link to="/ground-freight" class="header__nav1">{{ $t("ourServ1") }}</router-link>
            </div>
            <div>
              <router-link to="/avia-freight" class="header__nav1">{{ $t("ourServ3") }}</router-link>
            </div>
            <div>
              <router-link to="/international-freight" class="header__nav1">{{ $t("ourServ4") }}</router-link>
            </div>
          </div>
        </div>
        <div class="hoverPuncts"><router-link to="/our-routes" class="header__nav" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : 'min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.4464))), 16px)'}">{{ $t("ourMarsh") }}</router-link></div>
      </div>
      <router-link to="/">
        <div class="header-logo">
          <img :src="require('@/assets/LogoMain.svg')"/>
        </div>
      </router-link>
      <div class="header__right flex-row-between-center">
        <router-link to="/news" class="header__nav hoverPuncts" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : 'min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.4464))), 16px)'}">{{ $t("news") }}</router-link>
        <router-link to="/contacts" class="header__nav hoverPuncts" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : 'min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.4464))), 16px)'}">{{ $t("contacts") }}</router-link>
        <div class="header__right-account flex-row-between-center hoverPuncts hoverPunctsLeave">
                <img :src="require('@/assets/icons/user.svg')"/>
                <a v-if="!getIsAuth" href="#" data-bs-toggle="modal" data-bs-target="#loginForm" @click="isBurgerActive = false" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : 'min(max(12px, 0.75rem + (1vw - 3.2px) * 0.3226), 15px)'}">{{ $t('SignIn') }}</a>
                <a class="leaveAccBox" @click="leaveAccount" v-else :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : 'min(max(12px, 0.75rem + (1vw - 3.2px) * 0.3226), 15px)'}">{{ $t('leave') }}</a>
                <!-- <div class="leaveAcc">
                    <div class="leaveAcc__top">
                      <img :src="require('@/assets/icons/userLeave.svg')"/>
                      <p>{{ me.name }}</p>
                    </div>
                    <button class="leaveAcc-button" @click="leaveAccount">{{ $t('leave') }}</button>
                </div> -->
        </div>
        <!-- <div class="header__right-phone" data-bs-toggle="modal" data-bs-target="#callMeForm">{{ $t("orderPhone") }}</div> -->
        <div class="header__right-phone header__right-phoneTel" data-bs-toggle="modal" data-bs-target="#callMeForm">{{ $t("orderPhone") }}</div>
      </div>
    </div>

  </header>

  <div class="mobile">
      <div class="backHeader"  v-click-outside="closeHead">
        <div class="headerM container">
          <div class="headerM__content">
              <div class="leftHeaderMphone">
                <img :src="require('@/assets/icons/redPhone.svg')" data-bs-toggle="modal" data-bs-target="#callMeForm"/>
                <div class="langClass leftHeaderMphone_box">
                    <SelectComponent
                        :type="'lang'"
                        :options="languages"
                        @select="getLang">
                  </SelectComponent>
                </div>
              </div>
              <img :src="require('@/assets/LogoMain.svg')" @click="$router.push('/')"/>
              <div @click.prevent="showBurgerMenu" :class="{ 'burger-active': isBurgerActive }" class="header__burger">
                <div>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
          </div>
          <!-- <div class="headerM__bot1Back" :class="{'headerM__bot1BackActive': isBurgerActive}" @click="isBurgerActive = false"> -->
            <div class="headerM__bot" :class="{'headerM__bot1': isBurgerActive}">
              <div class="headerM__bot__menu">
                <router-link to="/about-company">{{ $t('aboutCompany') }}</router-link>
                <div class="headerM__bot__menu__marsh">
                    <div class="headerM__bot__menu__marsh-p1" @click="openMarsh = !openMarsh" :class="{activeMarsh: openMarsh == true}">
                      <p>{{ $t('ourServices') }}</p>
                      <img :src="require('@/assets/icons/selectBotRed.svg')"/>
                    </div>
                    <div class="headerM__bot__menu__marsh__p2" :class="{'headerM__bot__menu__marsh__p2Active': openMarsh == true}">
                      <p @click="routeTo('/railway-freight')">{{ $t('ourServ2') }}</p>
                      <p @click="routeTo('/ground-freight')">{{ $t('ourServ1') }}</p>
                      <p @click="routeTo('/avia-freight')" >{{ $t('ourServ3') }}</p>
                      <p @click="routeTo('/international-freight')">{{ $t('ourServ4') }}</p>
                    </div>
                </div>
                <router-link to="/our-routes">{{ $t('ourMarsh') }}</router-link>
                <router-link to="/news">{{ $t('news') }}</router-link>
                <router-link to="/contacts">{{ $t('contacts') }}</router-link>
                <a v-if="!getIsAuth" href="#" data-bs-toggle="modal" data-bs-target="#loginForm" @click="isBurgerActive = false">{{ $t('SignIn') }}</a>
                <a v-else @click="leaveAccount">{{ $t('leave') }}</a>
              </div>
            </div>
          <!-- </div> -->
      </div>
      </div>
  </div>

  <ModalLoginForm/>
  <ModalCallMeForm />
</template>

<script>
import ModalLoginForm from "@/modules/app/views/layout/main/ModalLoginForm";
import ModalCallMeForm from "@/modules/app/views/layout/main/ModalCallMeForm";

import SelectComponent from "@/components/SelectComponent.vue";

import SelectCustom from "@/components/SelectCustom.vue";
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  name: "MainHeader",
  components: { ModalCallMeForm, ModalLoginForm, SelectCustom, SelectComponent },
  data() {
    return {
      me: [],
      scTimer: 0,
      scY: 0,
      isBurgerActive: false,
      test: false,
      openMarsh: false,
      url: null,
      languages: [
        { id: 1, value: "ru", img: require("@/assets/images/langs/ru.svg") },
        { id: 1, value: "kz", img: require("@/assets/images/langs/kz.svg") },
        { id: 1, value: "en", img: require("@/assets/images/langs/en.svg") },
        { id: 1, value: "cn", img: require("@/assets/images/langs/cn.svg") },
      ],
    }
  },
  computed: {
    ...mapGetters(["getIsAuth"])
  },
  async created() {
    this.checkAuth()
    this.url = localStorage.getItem('crm_url');
    await this.$axios
      .get(`auth/me`, {
        headers: {
            Authorization: "Bearer ".concat(
                localStorage.getItem("access_token")
            ),
        },
      })
      .then(res => {
          this.me = res.data.data
      })
      .catch(err => {
        console.log(err);
      })
    
  },
  methods: {
    ...mapActions(["checkAuth"]),
    ...mapMutations(["UPDATE_LANG"]),
    showBurgerMenu() {
      return this.isBurgerActive = !this.isBurgerActive,
      this.openMarsh = false
    },
    routeTo(link) {
      if (link === this.$route.path) {
        this.isBurgerActive = false;
        this.openMarsh = false        
      } else {
        this.$router.push(link) 
      }
    },
    getLang(option) {
      // this.$i18n.locale = option.value;
      this.UPDATE_LANG(option.value);
      // axios.defaults.params = {
      //   lang: this.$store.state.lang,
      // };
      //or in file components
      this.$forceUpdate();
      location.reload();
    },
    closeHead() {
      if (this.isBurgerActive) {
        this.isBurgerActive = false
      }
    },
    async leaveAccount() {
      await this.$axios .post('auth/logout', {}, {
        headers: {
            Authorization: "Bearer ".concat(
                localStorage.getItem("access_token")
            ),
        },
      })
        .then(res => {
          this.$toaster.error("Вы вышли из аккаунта.");
          localStorage.removeItem('access_token');
          localStorage.removeItem('crm_url');
          this.$router.push('/');
          this.$router.go();
        })
        .catch(err => {
          this.$toaster.error("Ошибка!");
          console.log(err);
        })
    }
  },
  watch: {
    $route() {
      this.isBurgerActive = false;
      this.openMarsh = false
    },
  },
}

</script>

<style scoped lang="scss">
.leftHeaderMphone {
  position: relative;
  &_box {
    position: absolute;
    top: 2.5px;
    left: 170%;
  }
}

.hoverPuncts {
  display: flex;
  // padding: 16px 23px !important;
  padding: 6.5% 15px !important;
  &:hover {
    cursor: pointer;
      a {
        color: red !important;
      }
    background: rgba(254, 197, 12, 0.75);
    border-radius: 5px;
  }
  @media screen and (max-width: 1100px) {
    padding: 1% 1.5% !important;
  }
  @media screen and (max-width: 992px) {
    padding: 0 !important;
  }
}

.leaveAccBoxBack {
  position: absolute;
  height: 10px;
  width: 100%;
  left: 0;
}
.leaveAccBox {
  &:hover + .leaveAcc {
      display: flex;
  }
}
.leaveAcc {
  cursor: default;
  width: 237px;
  position: absolute;
  // display: flex;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 9px;
  top: 33px;
  left: -108px;
  background: #FFFFFF;
  border: 1px solid #E80707;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 0 15px 0;
  &__top {
    display: flex;
    gap: 8px;
    font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.2016))), 13px);
    p {
      font-weight: 500;
      color: #000000;
      border-bottom: 1px solid black;
    }
  }
  &-button {
    font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.2016))), 13px);
    background: #FEC50C;
    color: red;
    width: 34.84% ;
    height: 25px;
    transition: all 0.5s ease;
    &:hover {
      transform: scale(1.05);
    }
  }
  &:hover {
    display: flex;
  }
}
.changeLang {
  position: absolute;
  right: 23%;
}
.activeMarsh img {
    rotate: 0deg !important;
}
.header-left {
  text-transform: uppercase;
}
.header__right {
  a {
    text-transform: uppercase;
    font-weight: 500 !important;
  }
}
.header-logo {
    width: 13.6%;
}
.langClass {
  display: flex;
}

.backHeader {
  // position: fixed;
  // background: red;
  // z-index: 999;
  // top: 0;
  
    left: 0;
    background: white;
    box-shadow: 0px 5px 5px -5px rgba(34, 60, 80, 0.25);
    top: 0;
    z-index: 999;
    right: 0;
    position: fixed;
}

.headerM {
  left: 0;
  background: white;
  top: 0;
  z-index: 999;
  right: 0;
  // position: fixed;
  padding-bottom: 15px;
  padding-top: 15px;
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__bot {
    transition: all 0.5s ease;
    display: none;
    opacity: 0;
    border-radius: 0 0 10px 10px;
    &__menu {
      padding: 27px 39px;
      gap: 20px;
      display: flex;
      flex-direction: column;
      &__marsh {
        position: relative;
        height: 30px;
        padding: 0 19px;
        display: flex;
        text-align: center;
        color: black;
        text-decoration: none;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        &-p1 {
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          img {
            transition: all 0.5s ease;
            rotate: -90deg;
          }
        }
        &__p2Active {
          display: flex !important;
          -webkit-animation: fadeIn-4225ec55 0.5s ease-in-out;
          animation: fadeIn-4225ec55 .5s ease-in-out;
        }
        &__p2 {
          display: none;
          transform: translateY(0);
          top: 23px;
          flex-direction: column;
          gap: 26px;
          position: absolute;
          text-align: start;
          width: 100%;
          left: 0;
          padding: 26px 19px;
          background: red;
          font-size: 14px;
          background: #FFFFFF;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 0px 0px 10px 10px;
          p {
            &:hover {
              color: red;
            }
          }
        }
      }
    }
    a {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 14px;
      height: 30px;
      padding: 0 19px;
      text-align: start;
      color: black;
      text-decoration: none;
      background: #FFFFFF;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      transition: all 0.5s ease;
      &:hover {
        transform: scale(1.03);
        background: red;
        color: white;
      }
    }
  }
  &__bot1 {
    background: white;
    display: block;
    opacity: 1;
    -webkit-animation: fadeIn-4225ec55 0.5s ease-in-out;
    animation: fadeIn-4225ec55 .5s ease-in-out;
  }
  &__bot1Back {
    display: none;
    z-index: 100;
    position: fixed;
    top: 72px;
    left: 0;
    // background-color: rgba(0,0,0,.647);
    width: 100%;
    height: 100%;
  }
  &__bot1BackActive {
    display: block !important;
  }
}

.header {
  // background: #f8f9fa;
  // padding: 27px 0 17px 0;
  color: $textColor;
  position: relative;
  z-index: 1;
  &__burger {
    display: none;
  }
  .mobile-navbar {
    position: absolute;
    background-color: white;
    width: 100%;
    font-size: clamp(0.875rem, 0.739rem + 0.68vw, 1.25rem);
    line-height: 2;
    a {
      text-decoration: none;
      color: black;
    }
  }

  &__nav {
      font-size: min(max(12px, calc(0.75rem + ((1vw - 10px) * 0.8))), 14px);

  }
  

  &-left {
    width: 40.7%;
    // gap: 1%;
    // width: max-content;
    a {
      font-weight: 9000;
        font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.885))), 12px);
      // font-size: 16px;
      width: max-content;
      text-decoration: none;
      color: #161616;
      transition: all 0.2s ease;
      &:hover {
        font-weight: 500;
        color: #E80707;
      }
    }
  }

  &__right {
    width: 40.7%;
    // gap: 17px;
    font-size: min(max(10px, calc(0.625rem + ((1vw - 10px) * 0.8))), 12px);

    &-account {
      position: relative;
      cursor: pointer;  
      gap: 5px;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3226))), 15px);
       a {
        font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3226))), 15px);
        width: max-content;
        font-weight: 500   !important;
        transition: all 0.2s ease;
        text-transform: uppercase;
      }
    }

    &-phone {
      margin-left: 19px;
      cursor: pointer;
      border: 2px solid transparent;
      font-weight: 600;
      min-width: max-content;
      font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3571))), 16px);
      border-radius: 5px;
      padding: 0 5px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease;
      background: #E80707;
      color: #FEC50C;
    }
    &-phoneTel {
      &:hover {
        color: white;
        box-shadow: 2px 4px 14px rgba(232, 7, 7, 0.3);
      }
    }
    a {
      font-weight: 550;
      text-decoration: none;
      color: #161616;
      &:hover {
        font-weight: 500;
        color: #E80707;
      }
    }
  }
  .header__nav {
    // padding: 1em 0;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3226))), 15px);
    font-weight: 500;
    // padding: 32px 5px;
  }
  .header__nav0 {
    
    position: relative;
    font-weight: 500;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.3226))), 15px);
    // margin-bottom: -20px;
    // padding: 32px 5px;
     &:hover + .service_menu{
      display: flex;
      transition: 0.3s ease-out;
    }
  }
  .header__navBack {
    position: absolute;
    background: transparent;
    width: 178px;
    height: 30px;
    &:hover + .service_menu{
      display: flex;
      transition: 0.3s ease-out;
    }
  }


  .service_menu {
    display: none;
    flex-direction: column;
    gap: 18px;
    position: absolute;
    // top: calc(100% + 1em);
    top: 90%;
    width: 295px;
    left: 0;
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transition: 0.3s ease-in;
    &:hover {
      display: block;
    }
    a {
      text-transform: none;
      color: black !important;
      font-weight: 400;
      font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.4464))), 18px) !important;
      line-height: 0px;
      &:hover {
        color: #E80707 !important;
      }
    }
  }
}


@media (max-width: 1200px) {
  // .header__nav {
  //   display: none;
  // }
  .header__burger {
    display: block;
    width: 30px;
    height: 18px;
    position: relative;
    cursor: pointer;
    z-index: 9;

    & span {
      transition: all .3s ease 0s;
      top: calc(50% - 1px);
      left: 0;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #E80707;

      &:first-child {
        top: 0;
      }

      &:last-child {
        top: auto;
        bottom: 0;
      }
    }
  }
  .burger-active {
    span {
      transform: scale(0);

      &:first-child {
        transform: rotate(-45deg);
        top: calc(50% - 1px);
      }

      &:last-child {
        transform: rotate(45deg);
        bottom: calc(50% - 1px);
      }
    }
  }
  // .header__right-phone, .header__right-account, .header-left {
  //   display: none;
  // }

  // navigation

  .header:last-child{
    position: sticky;
  }
  .mobile-navbar a:hover{
    color: #fff;
  }
}














// .change {
//   position: absolute;
//   left: 15%;
//   top: 7px;
// }
// .check {
//   position: relative;
//   border: none;
//   appearance: none;
//   transition: 0.5s ease;
//   outline: none;
//   cursor: pointer;
//   transform: scale(1.2);
// }
// .check::before {
//   filter: brightness(0) saturate(100%) invert(11%) sepia(77%) saturate(6665%) hue-rotate(359deg) brightness(113%) contrast(114%);
//   content: url('@/assets/icons/changeLang.svg');
//   font-size: 36px;
//   font-weight: 550;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: rotate(0) translate(-50%, -50%);
// }
// .check:checked {
//   transform: scale(0.8);
// }

// .items {
//   position: absolute;
//   top: 10px;
//   left: 10px;
//   width: 25px;
//   height: 25px;
//   display: grid;
//   place-items: center;
//   font-size: 28px;
//   border-radius: 50%;
//   transition: .3s ease;
//   opacity: 0;
//   z-index: -1;
//   img {
//     width: 30px;
//     height: 30px;
//     object-fit: cover;
//     border-radius: 50%;
//     border: 1px solid rgb(255, 208, 0);
//   }
// }

// .map1 {
//   transform: rotate(90deg);
// }

// .map2 {
//   transform: rotate(90deg);
// }

// .map3 {
//   transform: rotate(90deg);
// }

// .map4 {
//   transform: rotate(90deg);
// }

// .check:checked ~ .items {
//   top: 0;
//   left: 0;
//   width: 50px;
//   height: 50px;
//   opacity: 1;
//   z-index: 1;
// }

// .check:checked ~.map1 {
//   left: 15px;
//   transform: rotate(0);
// }
// .check:checked ~.map2 {
//   left: 0px;
//   top: 30px;
//   transform: rotate(0);
// }
// .check:checked ~.map3 {
//   left: -35px;
//   top: 50px;
//   transform: rotate(0);
// }
// .check:checked ~.map4 {
//   left: -70px;
//   top: 60px;
//   transform: rotate(0);
// }

.hoverPunctsUslig {
  cursor: pointer;
  &:hover {
    .service_menu {
      display: flex;
    }
  }
}
.hoverPunctsLeave {
  img {
    transition: all 0.5s ease;
  }
  &:hover {
    color: red;
    img {
      filter: brightness(0) saturate(100%) invert(14%) sepia(97%) saturate(4300%) hue-rotate(6deg) brightness(92%) contrast(120%);
    }
  }
}
</style>
