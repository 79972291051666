<template>
  <div></div>
</template>

<script>
export default {
  name: "PhysicalLoginForm"
};
</script>


<style scoped lang="scss">
.login {
  padding: 68px 0;

  &-form {
    top: calc(50% - 750px / 2);
    left: calc(50% - 750px / 2);
    height: 723px;
    width: 526px;
    background: #FFFFFF;
    padding: 22px 73px 51px;
    border: 1px solid #E80707;
    box-shadow: 0 4px 24px rgba(10, 170, 250, 0.1);
    border-radius: 10px;

    .form-body {
      .form-type-radios {
        padding: 23px 0 28px;
      }

      .data-inputs {
        input {
          margin-bottom: 10px;
          background: #FFFFFF;
          border: 1px solid #E0E0E0;
          border-radius: 5px;
        }
      }
    }

    .form-actions {
      padding-top: 10px;

      .save-btn {
        width: 100%;
        padding: 19px;
        font-weight: 600;
        font-size: 16px;
        font-style: normal;
        line-height: 19px;
        background: #E80707;
        color: #FEC50C;
        box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
        border-radius: 5px;
      }

      .privacy {
        padding-top: 23px;

        .checkbox {
          margin: 0 9px;
          width: 16px;
          height: 16px;
          background: #0AAAFA;
          border-radius: 2px;

          .privacy-link {
            text-decoration-line: underline !important;
          }
        }

        .privacy-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #02283B;
        }
      }

      .auth-link {
        font-weight: 600;
        font-size: 16px;
        line-height: 50px;
        color: #02283B;
        text-decoration: none;
        border-bottom: 1px solid #02283B;
        margin-bottom: 13px;
      }
    }
  }

  &-title {
    margin-bottom: 35px;
  }
}
</style>