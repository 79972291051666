<template>
  <main class="services w100">
    <AviaDetails />
  </main>
</template>

<script>
import AviaDetails from "@/modules/services/components/AviaFreight/AviaDetails";

export default {
  components: { AviaDetails },
  name: "AviaFreightPage",
};
</script>

<style scoped lang="scss">
.services {
  width: 100vw;

  &__intro {
    //margin-top: 74px;
  }
  //
  &-title {
    margin-bottom: 26px;
  }
}
</style>
