<template>
  <main class="contacts w100">
    <div class="wrapper">
      <div class="container">
        <div class="contacts">
          <div class="breadcrumbs">
            <router-link to="/">{{ $t('main') }}</router-link>
            <span> / {{ $t('contacts') }}</span>
          </div>

          <div class="desctop">
            <div class="contacts__inner">
            <div class="contacts__title title">{{ $t('contacts') }}</div>
            <div class="contacts__content">
              <div class="content__address">
                <div class="mapContact">
                  <iframe v-if="openMap == 1" src="https://yandex.ru/map-widget/v1/?um=constructor%3A996954d94ff1aa4444441591268bca09042f99846bc26caf16628d7b0776d865&amp;source=constructor" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
                  <iframe v-else-if="openMap == 2" src="https://yandex.ru/map-widget/v1/?um=constructor%3A48b008f0868e1e2620bda7ba83c8cdc6846a5032323f6fb005b40d14a0abb1c9&amp;source=constructor" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
                  <iframe v-else-if="openMap == 3" src="https://yandex.ru/map-widget/v1/?um=constructor%3Af1e1e98728a8f04b5a52a7472059c1e60b649b32bc3ebab4e59a52461a314ddd&amp;source=constructor" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe>
                </div>
                <div class="address__info">
                  <div class="streetGap">

                    <div class="info__streets" @click="openMapTang(1)">
                      <div class="streets__city" :class="{acitveMap: openMap == 1}">
                        {{ $t('contacts1.almaty') }}
                      </div>
                    </div>

                    <div class="info__streets" @click="openMapTang(2)">
                      <div class="streets__city" :class="{acitveMap: openMap == 2}">
                        {{ $t('contacts1.tashkent') }}
                      </div>
                    </div>

                    
                    <div class="info__streets" @click="openMapTang(3)">
                      <div class="streets__city" :class="{acitveMap: openMap == 3}">
                        {{ $t('contacts1.urumshi') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="city">
                <div class="city__block">
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/companyIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.company') }}:</p>
                            <p class="city__block__box__left-p2" v-html="$t('contacts1.almatyAddress')"></p>
                        </div>
                    </div>
                    <div class="city__block__box" style="margin-top: 26px;">
                        <img :src="require('@/assets/icons/streetIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.street') }}:</p>
                            <p class="city__block__box__left-p2" v-html="$t('contacts1.streetAlmaty')"></p>
                        </div>
                    </div>
                    <div class="city__block__box" style="margin-top: 26px;">
                        <img :src="require('@/assets/icons/phoneIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.telephone') }}:</p>
                            <p class="city__block__box__left-p2"><a href="tel:+77712288318"><span>+7 (771) 228 8318</span></a></p>
                        </div>
                    </div>
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/mailIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.email') }}:</p>
                            <p class="city__block__box__left-p2" style="border-bottom: 1px solid black; line-height: 20px;">n.tleubaeva@tang-nur.com</p>
                        </div>
                    </div>
                </div>
                

                <div class="city__block city__blockLeft">
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/companyIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.company') }}:</p>
                            <p class="city__block__box__left-p2">{{ $t('contacts1.tashkentAddress') }}</p>
                        </div>
                    </div>
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/streetIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.street') }}:</p>
                            <p class="city__block__box__left-p2" v-html="$t('contacts1.streetTaskent')"></p>
                        </div>
                    </div>
                    <div class="city__block__box" style="margin-top: 26px;">
                        <img :src="require('@/assets/icons/phoneIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.telephone') }}:</p>
                            <p class="city__block__box__left-p2"><a href="tel:+998971579566"><span>+ (998) 971579566</span></a></p>
                        </div>
                    </div>
                </div>
                
                <div class="city__block city__blockLeft">
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/companyIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.company') }}:</p>
                            <p class="city__block__box__left-p2">{{ $t('contacts1.urumshiAddress') }}</p>
                        </div>
                    </div>
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/streetIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.street') }}:</p>
                            <p class="city__block__box__left-p2">{{ $t('contacts1.streetUrumshi') }}</p>
                        </div>
                    </div>
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/phoneIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">{{ $t('contacts1.telephone') }}:</p>
                            <p class="city__block__box__left-p2"><a href="tel:+17716943951"><span>+ 17716943951</span></a>,&nbsp; <a href="tel:+13619919377"><span>+ 13619919377</span></a></p>
                        </div>
                    </div>
                    <div class="city__block__box">
                        <img :src="require('@/assets/icons/qqIcon.svg')"/>
                        <div class="city__block__box__left">
                            <p class="city__block__box__left-p1">QQ:</p>
                            <!-- <p class="city__block__box__left-p2"><a href="tel:+457999608"><span>457999608</span></a>. <a href="tel:+261728320"><span>261728320</span></a>.</p> -->
                            <p class="city__block__box__left-p2">457999608, 261728320.</p>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          </div>


          <div class="mobile">
              <div class="contactsM">
                  <div class="contactsM__box">
                    <p class="streets__city">{{ $t('contacts1.almaty') }}</p>
                    <div class="mapContact"><iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A996954d94ff1aa4444441591268bca09042f99846bc26caf16628d7b0776d865&amp;source=constructor" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
                    <div class="city__block">
                      <div class="city__block__box">
                          <img :src="require('@/assets/icons/companyIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">{{ $t('contacts1.company') }}:</p>
                              <p class="city__block__box__left-p2" v-html="$t('contacts1.almatyAddress')"></p>
                          </div>
                      </div>
                      <div class="city__block__box" style="margin-top: 26px;">
                          <img :src="require('@/assets/icons/streetIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">{{ $t('contacts1.street') }}:</p>
                              <p class="city__block__box__left-p2" v-html="$t('contacts1.streetAlmaty')"></p>
                          </div>
                      </div>
                      <div class="city__block__box" style="margin-top: 26px;">
                          <img :src="require('@/assets/icons/phoneIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">{{ $t('contacts1.telephone') }}:</p>
                              <p class="city__block__box__left-p2"><a href="tel:+77712288318"><span>+7 (771) 228 8318</span></a></p>
                          </div>
                      </div>
                      <div class="city__block__box">
                          <img :src="require('@/assets/icons/mailIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">{{ $t('contacts1.email') }}:</p>
                              <p class="city__block__box__left-p2" style="border-bottom: 1px solid black; line-height: 20px;">n.tleubaeva@tang-nur.com</p>
                          </div>
                      </div>
                  </div>
                    <button class="buttonContacts" data-bs-toggle="modal" data-bs-target="#callMeForm">{{ $t('orderPhone') }}</button>
                  </div>
                  <div class="contactsM__box">
                      <p class="streets__city">{{ $t('contacts1.tashkent') }}</p>
                      <div class="mapContact"><iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A48b008f0868e1e2620bda7ba83c8cdc6846a5032323f6fb005b40d14a0abb1c9&amp;source=constructor" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
                      <div class="city__block city__blockLeft">
                        <div class="city__block__box">
                            <img :src="require('@/assets/icons/companyIcon.svg')"/>
                            <div class="city__block__box__left">
                                <p class="city__block__box__left-p1">{{ $t('contacts1.company') }}:</p>
                                <p class="city__block__box__left-p2">{{ $t('contacts1.tashkentAddress') }}</p>
                            </div>
                        </div>
                        <div class="city__block__box">
                            <img :src="require('@/assets/icons/streetIcon.svg')"/>
                            <div class="city__block__box__left">
                                <p class="city__block__box__left-p1">{{ $t('contacts1.street') }}:</p>
                                <p class="city__block__box__left-p2" v-html="$t('contacts1.streetTaskent')"></p>
                            </div>
                        </div>
                        <div class="city__block__box" style="margin-top: 26px;">
                            <img :src="require('@/assets/icons/phoneIcon.svg')"/>
                            <div class="city__block__box__left">
                                <p class="city__block__box__left-p1">{{ $t('contacts1.telephone') }}:</p>
                                <p class="city__block__box__left-p2"><a href="tel:+998971579566"><span>+ (998) 971579566</span></a></p>
                            </div>
                        </div>
                    </div>
                    <button class="buttonContacts" data-bs-toggle="modal" data-bs-target="#callMeForm">{{ $t('orderPhone') }}</button>
                  </div>
                  <div class="contactsM__box">
                    <p class="streets__city">{{ $t('contacts1.urumshi') }}</p>
                    <div class="mapContact"><iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af1e1e98728a8f04b5a52a7472059c1e60b649b32bc3ebab4e59a52461a314ddd&amp;source=constructor" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
                    <div class="city__block city__blockLeft">
                      <div class="city__block__box">
                          <img :src="require('@/assets/icons/companyIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">{{ $t('contacts1.company') }}:</p>
                              <p class="city__block__box__left-p2">{{ $t('contacts1.urumshiAddress') }}</p>
                          </div>
                      </div>
                      <div class="city__block__box">
                          <img :src="require('@/assets/icons/streetIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">{{ $t('contacts1.street') }}:</p>
                              <p class="city__block__box__left-p2">{{ $t('contacts1.streetUrumshi') }}</p>
                          </div>
                      </div>
                      <div class="city__block__box">
                          <img :src="require('@/assets/icons/phoneIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">{{ $t('contacts1.telephone') }}:</p>
                              <p class="city__block__box__left-p2"><a href="tel:+17716943951"><span>+ 17716943951</span></a>,&nbsp; <br/> <a href="tel:+13619919377"><span>+ 13619919377</span></a></p>
                          </div>
                      </div>
                      <div class="city__block__box">
                          <img :src="require('@/assets/icons/qqIcon.svg')"/>
                          <div class="city__block__box__left">
                              <p class="city__block__box__left-p1">QQ:</p>
                              <!-- <p class="city__block__box__left-p2"><a href="tel:+457999608"><span>457999608</span></a>. <a href="tel:+261728320"><span>261728320</span></a>.</p> -->
                              <p class="city__block__box__left-p2">457999608, <br/> 261728320.</p>
                          </div>
                      </div>
                    </div>
                    <button class="buttonContacts" data-bs-toggle="modal" data-bs-target="#callMeForm">{{ $t('orderPhone') }}</button>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <OstQues />
</template>

<script>
import OstQues from '@/components/OstQues.vue';

export default {
    name: "ContactsPage",
    data() {
        return {
            openMap: 1
        };
    },
    methods: {
        openMapTang(value) {
            this.openMap = value;
        }
    },
    components: { OstQues }
};
</script>

<style scoped lang="scss">
.buttonContacts {
  margin-top: 23px;
  background: #E80707;
  color: #FEC50C;
  padding: 0 49px;
  height: 58px;
}
.contactsM {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.city {
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
  &__blockLeft {
    border-left: 1.8px solid #81818180;
    padding-left: 15px;
    padding-bottom: 40px;
    @media screen and (max-width: 768px) {
      border: none;
      padding-left: 0px;
      padding-bottom: 0px;
    }
  }
  &__block {
    width: 30.06%;
    display: flex;
    flex-direction: column;
    gap: 22px;
     @media screen and (max-width: 768px) {
      width: 100%;
      margin-top: 24px;
      flex-wrap: wrap;
      flex-direction: initial;
      justify-content: center;
     }
    &__box {
      align-items: start;
      display: flex;
      gap: 16px;
      img {
        @media screen and (max-width: 768px) {
          height: 29px;
        }
      }
      @media screen and (max-width: 768px) {
        margin-top: 0 !important;
        flex-direction: column;
        align-items: center;
        width: 44%;
      }
      &__left {
        display: flex;
        flex-direction: column;
        gap: 8px;
        text-align: start;
        &-p1 {
          color: black;
          font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.625))), 24px);
          font-weight: 600;
          line-height: 23px;
          @media screen and (max-width: 768px) {
            text-align: center;
            font-weight: 600;
            margin-bottom: 0px;
          }
        }
        &-p2 {
             font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.5376))), 18px);
             color: #000000;
             line-height: 27px;
             @media screen and (max-width: 768px) {
              line-height: 23px;
              text-align: center;
             }
            a {
              text-decoration: none;
              color: #000000;
              span {
                color: #000000;
              }
            }
        }
      }
    }
  }
}
.acitveMap {
  border-bottom: 5px solid #E80707 !important;
}
.streetGap {
  display: flex;
  margin-top: 42px;
  margin-left: 42px;
  gap: 12.3%;
  color: black;
  @media screen and (max-width: 992px) {
    margin: 0;
    gap: 0%;
    justify-content: space-between;
  }
}
.mapContact {
  width: 100%;
  height: 510px;
  iframe {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 250px;
  }
}
.breadcrumbs {
   @media screen and (max-width: 992px) {
      padding-top: 20px;
    }
}
.title {
  font-size: 44px;
  color: #02283B;
  font-weight: 400;
  text-align: left;
}
.settings{
    font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
    margin-bottom: 5px;
    a {
      color: black;
      text-decoration: none;
    }
}
  .contacts {
    margin: 72px 0px 62px 0;
    @media screen and (max-width: 992px) {
      margin-top: 76px;
    }
  }
.contacts__inner {}
.contacts__title {
  margin: 0px 0px 35px 0px;
  font-weight: 400;
  // text-transform: uppercase;
  color: black;
    font-size: min(max(23px, calc(1.4375rem + ((1vw - 3.2px) * 1.3978))), 36px);
  line-height: 44px;
   @media screen and (max-width: 992px) {
    margin-bottom: 16px;
  }
}
.contacts__content {}
.content__address {
  display: flex;
  flex-direction: column;
  border-top: 3px solid rgba(0, 0, 0, 0.24);
  border-bottom: 3px solid rgba(0, 0, 0, 0.24);
  padding: 32px 0;
  justify-content: space-between;
}
.address__map {
  width: 70%;
  min-height: 100px;
  margin: 0px 40px 0px 0px;
  & img{
    width: 100%;
  }
}
.address__info {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.info__streets {
  cursor: pointer;
}
.streets__city {
  transition: all 0.5s ease-in-out;
  border-bottom: 5px solid transparent;
  font-size: min(max(21px, calc(1.3125rem + ((1vw - 3.2px) * 0.8036))), 30px);
  font-weight: 600;
    color: #161616;
  @media screen and (max-width: 768px) {
    font-weight: 400;
    margin-bottom: 19px;
  }
}
.streets__dir {
    font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
  max-width: 320px;
  // margin: 0px 0px 30px 0px;
}
.info__phone {
  margin-bottom: 30px;
}
.phone__tele {}
.phone__fax {}
.phone__mob {}
.phone__email {}
.info__call {
  cursor: pointer;
  border-radius: 155px;
  max-width: 230px;
  height: 60px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
 transition: all 0.8s ease;
  a {
    font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px) !important;
    text-decoration: none;
    font-weight: 600;
    color: #E80707;
  }
  &:hover {
    background: red;
    a {
      color: white !important;
    }
  }
}
.info__call a{
  font-size: 16px;
}

.adress__city-place {
  width: 100%;
   @media screen and (max-width: 992px) {
      display: flex;
      padding: 12px;
      margin-left: -12px;
      overflow: scroll;
      &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
                border: transparent;
                color: transparent;
                outline: none;
            }

            &::-webkit-scrollbar-thumb {
                height: 0px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 0px;
                height: 0px;
            }
    }
}
.city-place__block-1 {
  display: flex;
  margin: 0px 0px 15px 0px;
  @media screen and (max-width: 992px) {
    margin: 0;
  }
}
.city-place__name{
  font-weight: 600;
  margin-right: 10px;
  border-radius: 5px;
  height: 57px;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.2);
  max-width: 300px;
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
    color: #161616;
    padding: 0 49px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
    width: max-content;
  }
}
.city-place__name a:hover{
  color: #E80707;
}
.city-place__block-2 {display: flex;}

@media (max-width: 1400px){}
@media (max-width: 1200px){}
@media (max-width: 992px){
  .content__address{
    flex-direction: column;
    gap: 36px;
  }
  .address__map{
    width: 100%;
  }
}
@media (max-width: 772px){}
@media (max-width: 577px){}
</style>