import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import 'typeface-roboto';
import i18n from "./i18n";
import axios from 'axios'
import vClickOutside from "click-outside-vue3"


import { createToaster } from "@meforma/vue-toaster";

import scrollDirectives from '@/directives/scrollDirectives';


const axiosInstance = axios.create({
  baseURL: 'https://ecrm.test-nomad.kz/api/',
  params: {
    lang: store.getters.getLang
  }
})


const app = createApp(App).use(store).use(router).use(i18n).use(vClickOutside).directive('scroll', scrollDirectives)
app.config.globalProperties.$toaster = createToaster({ position: "top-right", duration: 1900 });
app.config.globalProperties.$axios = { ...axiosInstance }
app.config.globalProperties.$cdn = 'https://cdn-ges.test-vip.kz/'
app.mount('#app')
