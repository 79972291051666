<template>
  <main class="w100">
    <railway-details />
  </main>
</template>

<script>
import RailwayDetails from "@/modules/services/components/RailwayFreight/RailwayDetails";

export default {
  components: { RailwayDetails },
  name: "RailwayFreightPage",
};
</script>

<style scoped lang="scss">
.services {
  width: 100vw;
  padding: 74px 0;

  &-title {
    margin-bottom: 26px;
  }
}
</style>
