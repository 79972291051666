<template>
  <footer class="footer">
    <div class="container flex-col-stretch">
      <div class="footer__top flex-row-between-center">
        <router-link to="/">
          <div class="footer__top-logo">
            <img :src="require('@/assets/footerLogo.svg')"/>
          </div>
        </router-link>
        <div class="footer__top-block">
          <div class="footer__top-links b16-500 flex-row-between-center">
            <router-link class="hoverPuncts" to="/about-company"><a to="/about-company" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : '16px'}">{{ $t('aboutCompany') }}</a></router-link>
            <div style="position: relative;" class="nav_service  hoverPuncts hoverPunctsUslig">
              
              <div class="mobile"> 
                <div class="nasiAll" v-click-outside="closeFoot">
                  <div class="nasi" @click="openUslugiMobile = !openUslugiMobile" :class="{activeNasi: openUslugiMobile == true}">
                    <p class="nasi-title">{{ $t('ourServices') }}</p>
                    <img class="nasi-arrow" :src="require('@/assets/icons/selectBotWhite.svg')"/>
                  </div>
                  <!-- <div class="nasi-contentBack" :class="{'nasi-contentBackActive': openUslugiMobile == true}" @click="openUslugiMobile == false"> -->
                    <div class="nasi-content" @click.stop>
                        <p @click="routeTo('/railway-freight')">{{ $t('ourServ2') }}</p>
                        <p @click="routeTo('/ground-freight')">{{ $t('ourServ1') }}</p>
                        <p @click="routeTo('/avia-freight')" >{{ $t('ourServ3') }}</p>
                        <p @click="routeTo('/international-freight')">{{ $t('ourServ4') }}</p>
                    </div>
                  <!-- </div> -->
                </div>
              </div>
              <a style="cursor: pointer;" class="header__nav0 desctop" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : '16px'}">{{ $t('ourServices') }} <div class="header__navBack"></div></a>
              <div class="desctop">
                <div class="service_menu">
                <div>
                  <router-link to="/railway-freight" class="header__nav1">{{ $t('ourServ2') }}</router-link>
                </div>
                <div>
                  <router-link to="/ground-freight" class="header__nav1">{{ $t('ourServ1') }}</router-link>
                </div>
                <div>
                  <router-link to="/avia-freight" class="header__nav1">{{ $t('ourServ3') }}</router-link>
                </div>
                <div>
                  <router-link to="/international-freight" class="header__nav1">{{ $t('ourServ4') }}</router-link>
                </div>
              </div>
              </div>
            </div>
            <router-link class="hoverPuncts" to="/our-routes"><a to="/our-routes" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : '16px'}"> {{ $t('ourMarsh') }}</a></router-link>
            <router-link class="hoverPuncts" to="/news"><a to="/news" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : '16px'}">{{ $t('news') }}</a></router-link>
            <router-link class="hoverPuncts" to="/contacts"><a to="/contacts" :style="{fontSize: $store.state.lang === 'kz' ? 'min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1075))), 13px)' : '16px'}">{{ $t('contacts') }}</a></router-link>
          </div>
          <div class="footer__top-social flex-row-between-center">
            <!-- <a href=""><img :src="require('@/assets/icons/telegram.png')"/></a> -->
            <a href="https://vk.com/tangnurlogistic"><img :src="require('@/assets/icons/vk.png')"/></a>
            <a href="https://www.facebook.com/profile.php?id=100090776784938"><img :src="require('@/assets/icons/facebook.png')"/></a>
            <a href="https://www.instagram.com/tangnur_logistics_group/"><img :src="require('@/assets/icons/instagram.png')"/></a>
          </div>
        </div>
      </div>
      <p class="footer-title b18-600">{{ $t('contacts') }}</p>
      <div class="footer__contacts flex-row-between-center">
        <!-- <div
            class="flex-row-between-center"
            style="gap: 14px"
            v-for="(item, i) in contacts"
            :key="i">
          <img :src="require('@/assets/icons/' + item.icon)"/>
          <p class="gapFoo" v-html="item.text"/>
        </div> -->
        <div class="flex-row-between-center gapFooBox">
            <img :src="require('@/assets/icons/map.svg')"/>
            <div class="gapFoo">
              <p v-html="$t('address')"></p>
            </div>
        </div>

        <div class="flex-row-between-center gapFooBox">
            <img :src="require('@/assets/icons/phone.svg')"/>
            <div class="gapFoo">
              <a href="tel:+77712288318"><span>{{ $t('tel') }}</span>: +7 771 228 83 18</a>
              <!-- <a href="tel:+77086672508">Моб: +7 708 667 25 08</a> -->
            </div>
        </div>

        <div class="flex-row-between-center gapFooBox">
            <img :src="require('@/assets/icons/mail.svg')"/>
            <div class="gapFoo" style="font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1786))), 14px);">
              <p><span>{{ $t('email') }}</span> n.tleubaeva@tang-nur.com</p>
              <!-- <p>E-mail: kamilzhanzhumagali@tang-nur.com</p> -->
            </div>
        </div>

        <div class="flex-row-between-center gapFooBox">
            <img :src="require('@/assets/icons/clock.svg')"/>
            <div class="gapFoo">
              <p>{{ $t('watchwork') }}:</p>
              <p>{{ $t('timeWorking') }}</p>
            </div>
        </div>
      </div>
      <!-- <div class="line1"></div> -->
      <hr />
      <div class="footer__bottom flex-row-between-center">
        <p class=" footer__bottom-items b14-600">(c) 2022 tang-nur.kz</p>
        <div class="footer__bottom-items flex-row-between-center" style="gap: 12px; cursor: pointer;" @click="$router.push('/user-agreement')">
          <img :src="require('@/assets/icons/document.svg')"/>
          <p class="gapFoo">{{ $t('ourAgreement') }}</p>
        </div>
        <div class="footer__bottom-items flex-row-between-center" style="gap: 12px; cursor: pointer;" @click="$router.push('/user-privacy')">
          <img :src="require('@/assets/icons/document.svg')"/>
          <p class="gapFoo">{{ $t('ourPrivacy') }}</p>
        </div>
      </div>
    </div>
    <transition name="fade" data-aos="fade-up">
      <div id="pagetop" v-show="scY > 800" @click="toTop">
        <img class="phoneHelper" :src="require('@/assets/icons/phone2.svg')" data-bs-toggle="modal" data-bs-target="#callMeForm"/>
        <img class="carBack" :src="require('@/assets/icons/backtotop.svg')">
      </div>
    </transition>
  </footer>
</template>
<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  data() {
    return {
      scTimer: 0,
      scY: 0,
      isOpen: false,
      openUslugiMobile: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);

    AOS.init();
  },
  watch: {
    $route() {
      this.openUslugiMobile = false;
      if (this.$route.path == '/ground-freight') {
          this.openUslugiMobile = false;
      }
    }
  },
  methods: {
    handleScroll: function () {
      if (this.scTimer) return;
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY;
        clearTimeout(this.scTimer);
        this.scTimer = 0;
      }, 100);
    },
    toTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },

    routeTo(link) {
      if (link === this.$route.path) {
        this.openUslugiMobile = false;
      } else {
        this.$router.push(link) 
      }
    },
    closeFoot() {
      if (this.openUslugiMobile === true) {
        this.openUslugiMobile = false;
      }
    }
  },
};
</script>

<style scoped lang="scss">
.hoverPuncts {
  // padding: 16px 23px !important;
  padding: 1% 2.5% !important;
  &:hover {
    background: rgba(254, 197, 12, 0.75);
    border-radius: 5px;
  }
  @media screen and (max-width: 1100px) {
    padding: 1% 1.5% !important;
  }
  @media screen and (max-width: 992px) {
    background: transparent !important;
    padding: 0 !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.activeNasi {
  color: #E80707 !important;
  .nasi-arrow {
    rotate: 0deg;
  }
}
.activeNasi + .nasi-content {
    display: flex;
    animation: fadeIn 0.5s ease-in-out;
    p{
      &:hover {
        color: red !important;
      }
    }
}
.nasiAll {
  position: relative;
}
.nasi {
  color: white;
  display: flex;
  gap: 10px;
  align-items: center;
  p {
    font-size: 16px !important;
    text-transform: uppercase;
    // font-size: 14.5px;
  }
  &-arrow {
    transition: all 0.5s ease;
    rotate: -90deg;
  }
}
.nasi-contentBack {
    display: none;
    z-index: 100;
    position: absolute;
    margin-top: 72px;
    left: 0;
    background-color: rgba(0,0,0,.647);
    width: 100vw;
    height: 100vh;
}
.nasi-contentBackActive {
  display: block !important;
  .nasi-content {
    display: flex !important;
  }
}
.nasi-content {
  position: absolute;
  margin-top: 7px;
  display: none;
  // display: flex;
  font-weight: 500;
  flex-direction: column;
  gap: 18px;
  font-size: min(max(12px, 0.75rem + (1vw - 3.2px) * 0.4301), 16px) !important;
  text-transform: uppercase;
  border-radius: 10px;
  color: black !important;
  background: white;
  padding: 25px 9px 25px 21px;
  width: 80vw;
}

.header__nav0 {
  
  position: relative;
  padding: 1em 0;
  font-size: min(max(10px, calc(0.625rem + ((1vw - 10.24px) * 0.885))), 12px);
  margin-bottom: -20px;
    &:hover + .service_menu{
    display: flex;
    transition: 0.3s ease-out;
  }
}
.header__navBack {
  cursor: default;
  position: absolute;
  background: transparent;
  width: 120px;
  border-radius: 20px;
  height: 20px;
  &:hover + .service_menu{
    display: flex;
  }
}



.phoneHelper {
  background: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 8px;
  // transform: scale(0.8);
  box-shadow: 0px 0px 3px 4px rgba(252, 4, 4, 0.4);
  animation: pulse 6.5s infinite;

}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    box-shadow: 0px 0px 3px 7px rgba(255, 0, 0, 0.25);
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}



 .nav_service:hover .service_menu{
    opacity: 1;
  }

  .header__nav {
    &:hover + .service_menu{
      display: flex;
      transition: 0.3s ease-out;
    }
  }
  .service_menu {
    display: none;
    flex-direction: column;
    gap: 18px;
    position: absolute;
    top: calc(100% + 1em);
    width: 330px;
    top: 35px;
    left: 0px;
    padding: 21px 21px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    transition: 0.3s ease-in;
    &:hover {
      display: flex !important;
    }
    @media screen and (max-width: 992px) {
      left: 0;
    }
    a {
      text-transform: none;
      color: black !important;
      font-weight: 400;
      font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
      line-height: 0px;
      &:hover {
        color: #E80707 !important;
      }
    }
  }


// @import url('../../adaptive/footer-adaptive.scss');
.footer__top-logo {
  @media screen and (max-width: 992px) {
    margin-bottom: 17px;
  }
}
.line1 {
  width: 100vw;
  border: 1px solid #FFFFFF;
  opacity: 0.5;
}

.gapFooBox {
  display: flex;
  align-items: center;
  gap: 14px;
}
.gapFoo {
    font-size: clamp(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226)), 16px);
  display: flex;
  flex-direction: column;
  text-align: initial;
  a {
    text-decoration: none;
    color: white;
  }
}
.footer {
  color: white;
  padding-top: 25px;
  padding-bottom: 22px;
  background: #283043;
  position: relative;
  @media screen and (max-width: 992px) {
    padding-top: 37px;
  }

  &__top {
    color: white;
    &-links {   
      justify-content: center;
      width: 100%;
      text-transform: uppercase;
      // gap: 42px;  
         font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.4301))), 16px);
      a {
          font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.4301))), 16px);
          transition: all 0.2s ease;
      }

      & a:hover{
        color: #E80707;
      }
    }
    &-block{
      width: 100% !important;
      color: white;
      display: flex;
      justify-content: space-between;
      width: 80%;
      a {
        color: #FFFFFF;
        text-decoration: none;
      }
    }

    &-social {
      gap: 10px;
      margin-left: 10px;
    }
  }

  &-title {
    color: white;
    margin: 58px 0 19px 0;
      font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.3226))), 18px);
    @media screen and (max-width: 992px) {
      margin: 25px 0 18px 0;
    }
  }

  &__contacts {
    color: white;
    align-items: flex-start;
    // justify-content: space-between;
    // align-items: center;
    padding-bottom: 40px;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.4301))), 16px);
    @media screen and (max-width: 992px) {
      gap: 16px;
    }

  }

  &__bottom {
    color: #828282;
    margin-top: 15px;
    font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.5376))), 16px);
  }

  hr {
    position: absolute;
    left: 0;
    bottom: 40px;
    width: 100vw;
    border-top: 2px solid white;
  }
}
#pagetop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  position: fixed;
  z-index: 100;
  cursor: pointer;
  bottom: 40px;
  right: 40px;
  @media (max-width:992px) {
    right: 0%;
    bottom: 30px;
  }
  // img {
  //   width: 55px;
  // }
}

@media (max-width:992px) {
  .footer__top{
    display: flex;
    flex-direction: column;
  }
  .footer__top-links{
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }
  .footer__top-block{
    justify-content: space-between;
    width: 100%;
    font-size: 16px;
    @media screen and (max-width: 992px) {
      align-items: flex-start;
    }
  }
  .footer__top-social{
    
  }
}
@media (max-width:770px) {
  .footer__contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__bottom {
    margin: 0px 0px 0px 0px;
    @media screen and (max-width: 992px) {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
    }
  }
  .footer__bottom-items{
  }
  .footer hr{
    bottom: 60px;
  }
} 
@media (max-width:992px) {
  .footer hr{
      bottom: 105px;
  }
  .footer__bottom{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
      gap: 12px;
  }
}
.hoverPunctsUslig {
  cursor: pointer;
  &:hover {
    .service_menu {
      display: flex;
    }
  }
}
.header__nav1 {
  font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.4464))), 18px) !important;
}

.carBack {
  width: 125%;
  @media (max-width:768px) {
    width: 100%;
  }
}
</style>
  