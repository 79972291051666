<template>
  <main class="article w100">
    <div class="wrapper">
      <div class="container">
        <div class="article">
          <div class="article__inner">
            <div class="article__image"><img :src="require('@/assets/images/article/article.png')"/></div>
            <div class="article__title title">ВИДЫ ГРУЗОВЫХ МАШИН</div>
            <section class="article__content">
              <div class="content__info">
                <div class="info__block">
                  <div class="info__author">Автор: Ирина Милаярова</div>
                  <div class="info__data">
                    <div class="data__date">
                      <img :src="require('@/assets/images/article/calendar.png')"/> 20/08/2021
                      <!-- <div class="date__image">
                        <img :src="require('@/assets/images/article/calendar.png')"/>
                      </div> -->
                    </div>
                    <div class="data__seen">
                      <img :src="require('@/assets/images/article/eye.png')"/> 20
                      <!-- <div class="seen__image">
                        <img :src="require('@/assets/images/article/eye.png')"/>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="info__items">
                  <div class="items__block">
                    <div class="block__title cl-title">Класс — «Газель»</div>
                    <div class="block__text">
                      <div class="text__description">
                        По своим основным потребительским качествам (грузоподъемность, габаритные размеры, маневренность) к малотоннажным 
                        автомобилям 
                        типа «Газель» можно отнести различные модели отечественных и импортных грузовиков. 
                        Грузовые отсеки этих автомобилей могут незначительно отличаться по габаритным размерам и, соответственно, объёмам.
                      </div>
                      <div class="text__charac">
                        <strong>Характеристики грузового транспорта класса «Газель»:</strong> 
                        <ul>
                          <li>
                            Длина: 2,8-3,2 м (существуют удлинённые варианты до 4 м)
                          </li>
                          <li>
                            Ширина: 1,8 — 1,9 м
                          </li>
                          <li>
                            Высота: 1,7 — 2 м
                          </li>
                          <li>
                            Объём: 9-16 м3
                          </li>
                          <li>
                            Грузоподъёмность: 1,5 — 2 тонны
                          </li>
                          <li>
                            Вместимость кузова: 4 европаллеты
                          </li>
                        </ul>
                      </div>
                      <div class="text__description">
                        Изотермический малотоннажный фургон позволяет обеспечить оперативную доставку скоропортящихся грузов, 
                        при этом оптимизируя транспортные расходы. 
                        Тентованный вариант грузового отсека в одних случаях предполагает возможность растентоваться и получить открытый 
                        грузовой отсек, в других — боковые борта укрепляют жесткими панелями, что дополнительно обеспечивает сохранность 
                        груза. 
                      </div>
                    </div>
                  </div>
                  <div class="items__block">
                    <div class="block__title cl-title">Класс — «Пятитонник»</div>
                    <div class="block__text">
                      <div class="text__description">
                        По своим основным потребительским качествам (тоннаж, объём, габаритные размеры) к автомобилям класса 
                        «Пятитонник» можно отнести различные модели отечественных и импортных грузовиков. Грузовые отсеки этих 
                        автомобилей могут незначительно отличаться по габаритным размерам и, соответственно, объёмам.
                      </div>
                      <div class="text__charac">
                        <strong>Характеристики грузового отсека автомобилей класса «Пятитонник»:</strong> 
                        <ul>
                          <li>
                            Длина: 3,5 — 6,1 м
                          </li>
                          <li>
                            Ширина: 2-2,3 м
                          </li>
                          <li>
                            Высота: 2,45 м
                          </li>
                          <li>
                            Объём: до 35 м3
                          </li>
                          <li>
                            Грузоподъёмность: до 5 тонн
                          </li>
                          <li>
                            Вместимость кузова: 10 европаллет
                          </li>
                        </ul>
                      </div>
                      <div class="text__description">
                        Автомобили данного класса активно используются на внутригородских и междугородних маршрутах малой 
                        и средней дальности (500-700 км). Часто используют для перевозки личных вещей, переездов. 
                        Автомобиль может быть оборудован крепёжными ремнями и другим дополнительным оборудованием 
                        (гидробортом, жёсткими бортами).
                      </div>
                    </div>
                  </div>
                  <div class="items__block">
                    <div class="block__title cl-title">Класс — «Десятитонник»</div>
                    <div class="block__subtitle subtitle">
                      К классу 10-тонных автомобилей с тентованным кузовом можно отнести различные автомобили 
                      отечественного и импортного производства.
                    </div>
                    <div class="block__text">
                      <div class="text__description">
                        <strong>Класс можно разбить на несколько подклассов:</strong>
                          <ul class="subclass__item">
                            <li>
                              Автомобили грузоподъёмностью до 5 тонн с объёмом кузова близкому к объёму 10 тонного автомобиля (36 м3)
                            </li>
                            <li>
                              Автомобили грузоподъёмностью до 10 тонн с объёмом кузова до 56 м3.
                            </li>
                            <li>
                              Автомобили грузоподъёмностью до 15 тонн с объёмным (50-60 м3) и длинным кузовом (до 8м).
                            </li>
                          </ul>
                      </div>
                      <div class="text__charac">
                        <strong>Характеристики транспорта грузоподъёмностью 10-15 тонн*:</strong> 
                        <ul>
                          <li>
                            Длина: 5,0 — 8,0 м
                          </li>
                          <li>
                            Ширина: 2,4 — 2,5 м
                          </li>
                          <li>
                            Высота: 1,8 — 3,0 м
                          </li>
                          <li>
                            Объём: 25-60 м3
                          </li>
                          <li>
                            Грузоподъёмность: 5 — 15 тонн
                          </li>
                          <li>
                            Вместимость кузова: 15 европаллет
                          </li>
                        </ul>
                      </div>
                      <div class="text__description">
                        Грузовики данного класса активно используются на междугородних и международных направлениях. 
                        Обычно кабина оборудована спальным местом и предусматривает место для экспедитора. 
                        В стандартной комплектации машина комплектуется крепёжными ремнями (до 6 штук). 
                        Грузовой отсек приспособлен к различным вариантам погрузки/разгрузки (верх, бок). 
                        Импортные модели грузовиков могут комплектоваться пневмоподвеской, что существенно улучшает плавность 
                        хода и обеспечивает лучшую сохранность легкобъющегося груза.
                      </div>
                    </div>
                  </div>
                  <div class="items__block">
                    <div class="block__title cl-title">Класс — «Еврофура»</div>
                    <div class="block__text">
                      <div class="text__description">
                        «Еврофура» — условное понятие обозначающее грузовой автомобиль с полуприцепом имеющим габаритные размеры,
                        близкие к указанным характеристикам. В еврофуру, как иначе называют евротент, вмещается по ширине поставленные
                        поперек 2 европоддона длиной 120 см каждый.
                      </div>
                      <div class="text__charac">
                        <strong>Габаритные размеры «Еврофуры» :</strong>
                        <ul>
                          <li>
                            Длина: 13,6 м
                          </li>
                          <li>
                            Ширина: 2,45 м
                          </li>
                          <li>
                            Высота: 2,45 — 2,75 м
                          </li>
                          <li>
                            Объём: 82-90 м3
                          </li>
                          <li>
                            Грузоподъёмность: 20-21 тонн
                          </li>
                          <li>
                            Вместимость кузова: 32-33 европаллеты
                          </li>
                        </ul>
                      </div>
                      <div class="text__description">
                          Существует большое кол-во модификаций полуприцепов, среди которых встречаются 
                          полуприцепы объёмом 76-78 м3 — с меньшей длиной (12,5 — 13 м) и полуприцепы, имеющие стандартную 
                          или большую длину, ширину и высоту (13,6 — 15 м; 2,5 м; 2,7 м)
                          Конструкция полуприцепа позволяет убирать тент и тем самым даёт
                          возможность производить погрузку/выгрузку сбоку или сверху. Кроме того, полуприцеп без 
                          тента позволяет использовать полуприцеп как открытую площадку с высотой бортов от 35 до 60 см. 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div class="article__news">
              <div class="news__inner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: "ArticlePage",
};
</script>

<style scoped lang="scss">
@mixin basic-data{
  color: #828282;
  display: flex;
  align-items: center;
}
.title {
  font-size: 36px;
  font-weight: 550;
  text-align: left;
}
.cl-title {
  font-size: 20px;
  font-weight: 600;
}
  .article {
    &__inner{}
    &__image{}
    &__title{margin: 0px 0px 30px 0px;}
    &__content{}
    .content{
      &__info{}
    }
    .info{
      &__author{
        font-weight: 600;
      }
      &__data{display: flex;}
      &__block{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
      }
      &__items{text-align: left;}
    }
    .data{
      &__date{
        margin-right: 20px;
        @include basic-data;
      }
      &__seen{
        @include basic-data;
      }
    }
    .items{
      &__block{}
    }
    .block{
      &__title{margin: 25px 0px 15px 0px;}
      &__title{}
      &__text{}
      &__subtitle{margin-bottom: 15px;}
    }
    .text{
      &__description{}
      &__charac{margin: 20px 0px 20px 0px;}
      &__charac ul{margin-top: 15px;}
    }
  }
</style>