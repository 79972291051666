<template>
    <div class="cer">
        <img :src="require('@/assets/images/certif/' + cert.image + '.svg')" class="cer-image"/>
        <img :src="require('@/assets/images/certif/dot.svg')" class="cer-dot"/>
    </div>
</template>
<script>
export default {
    props: ['cert']
}
</script>
<style lang="scss">
.cer {
    position: relative;
    height: 300px;
    width: fit-content;
    transition: all 0.5s ease;
    // box-shadow: 0px 1px 15px 7px rgba(34, 60, 80, 0.26);
    &-image {
        height: 100%;
        width: 100%;
    }
    &-dot {
        transition: all 0.5s ease;
        position: absolute;
        right: -12%;
        top: -10%;
        @media screen and (max-width: 992px) {
            top: 12%;
            width: 25%;
            right: -10%;
        }
    }
}
.cer:hover{
    transform: scale(1.2);
     .cer-dot {
        right: -25%;
        top: -25%;
     }
}
</style>