<template>
  <div class="details">
    <div class="details__intro">  
    <img :src="require('@/assets/icons/track4.png')" class="truck1"/>
      <div class="container">
        <div class="row">
          <div class="details1 col-6 ">
            <div class="details-title w46-600" style="font-weight: 400;">
              {{ $t('ourServ4') }}
            </div>
            <div class="col-10">
              <p class="details-desc w14-400 text-start" v-html="$t('multiDesc')">
              </p>
            </div>
            <div class="details-feedback-btn d-flex justify-content-start">
              <button class="w16-700 feedback-btn" data-bs-toggle="modal" data-bs-target="#orderForm">
                {{ $t('leaveRequest') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

     <div class=container>
      <div class="railway-benefits borderBlock">
      <div class="railway-title b36-700 text-uppercase trainDesc2">{{ $t('multiDesc1') }}</div>
      <div class="row d-flex justify-content-between align-items-center w100 delif">
        <div class="col-6 railway-left-benefits col1">
          <ul class="timeline">
             <li class="event">
              <p>
                {{ $t('multi.desc1') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('multi.desc2') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('multi.desc3') }}
              </p>
            </li>
            <div class="timeline1-border"></div>
          </ul>
        </div>
        <div class="col-6 col2">
          <ul class="timeline">
            <li class="event">
              <p>
                {{ $t('multi.desc4') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('multi.desc5') }}
              </p>
            </li>
            <div class="timeline2-border"></div>
          </ul>
        </div>
      </div>
      <p v-html="$t('noticeForClientMulti')" class="noticeClient">
      </p>
    </div>
    </div>
    
    <div class="advantBack">
      <div class="advant">
          <p class="advant-title">{{ $t('multiDesc2') }}</p>
          <div class="advant__content">
              <div class="advant__content__box advant__content__box1">
                <div class="advant__content__box-img"><img :src="require('@/assets/icons/inter1.svg')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('multi.preTitle1') }}</p>
                <p class="advant__content__box-p2" v-html="$t('multi.preDesc1')"></p>
              </div>
              <div class="advant__content__box advant__content__box2">
                <div class="advant__content__box-img"><img :src="require('@/assets/icons/inter2.svg')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('multi.preTitle2') }}</p>
                <p class="advant__content__box-p2" v-html="$t('multi.preDesc2')"></p>
              </div>
              <div class="advant__content__box advant__content__box3">
                <div class="advant__content__box-img"><img :src="require('@/assets/icons/inter3.svg')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('multi.preTitle3') }}</p>
                <p class="advant__content__box-p2" v-html="$t('multi.preDesc3')"></p>
              </div>
              <div class="advant__content__box advant__content__box4">
                <div class="advant__content__box-img"><img :src="require('@/assets/icons/inter4.svg')" alt=""></div>
                <p class="advant__content__box-p1">{{ $t('multi.preTitle4') }}</p>
                <p class="advant__content__box-p2" v-html="$t('multi.preDesc4')"></p>
              </div>
          </div>
      </div>
    </div>


    <div class="container"><p class="noticeClient2">{{ $t('noticeForClientMulti2') }}</p></div>
    <ModalOrderForm />
  </div>
  <OstQ ues />
</template>
<script>
import OstQues from "@/components/OstQues.vue";
import ModalOrderForm from "@/modules/app/views/layout/main/ModalOrderForm";
export default {
  name: "AviaDetails",
  components: { ModalOrderForm, OstQues }
};
</script>
<style lang="scss" scoped>

.advantBack {
  padding: 52px 0 55px 0;
  background: #F3F3F3;
}
.advant {
  width: 1350px;
  margin: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $laptop + px) {
      width: 1140px;
  }

  @media screen and (max-width: 1200px) {
    width: 960px;
  }

  @media screen and (max-width: $tablet + px) {
    width: 720px;
  }

  @media screen and (max-width: $mobile + px) {
    width: 95%;
  }
  &-title {
    margin-bottom: 50px;
    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.4286))), 36px);
    color: black;
    font-weight: 400;
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 770px) {
        flex-direction: column;
        gap: 32px;
    }
    &__box {
      width: 23%;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
      gap: 14px;
      &-img {    
        width: 100%;
        display: flex;
        height: 83px;
        justify-content: center;
        align-items: center;
      }
      &-p1 {
        font-weight: 500;
        font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0))), 17px);
        text-transform: uppercase;
        color: #161616;
        @media screen and (max-width: 770px) {
          font-size: 20px;
        }
      }
      &-p2 {
        text-align: start;
        font-size: 15px;
        color: #161616;
        font-weight: 400;
        @media screen and (max-width: 770px) {
          font-size: 16px;
          text-align: center;
          margin-right: auto;
          margin-left: auto;
          width: 70%;
        }
        
      }
    }
    // &__box1 {
    //   width: 23.6%
    // }
    // &__box2 {
    //   width: 21.28%;
    // }
    // &__box3 {
    //   width: 24.46%
    // }
    // &__box4 {
    //   width: 24.1%
    // }
  }
}

.timeline {
  border-left: 0px solid red !important;
}
.timeline-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(90%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
  }

.benefit-item  {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 26.64%;
  @media screen and (max-width: 992px) {
    width: 70%;
    margin: auto;
  }
}
.event {
      font-size: 16px !important;
}

.b36-700  {
    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.2903))), 36px) !important;
}

.benefit-list {
  justify-content: space-between;
  display: flex;
   @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 75px;
   }
}

.details1 {
  position: absolute;
  top: 0;
  margin-top: 100px;
  width: 45.68%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 40px;
  }
}

.b20-700 {
  font-size: 16px;
  font-weight: 600;
}
.truck1 {
    position: absolute;
    bottom: -25px;
    right: 0;
     @media screen and (max-width: 992px) {
       width: 61%;
        right: -2px;
        bottom: -10px;
    }
  }

.details {
  @media screen and (max-width: 992px) {
    margin-top: 76px;
  }
  &__intro {
    background: url("@/assets/images/bg/inter.png") center no-repeat;
    background-size: cover;
    background-color: black;
    background-position: center;
    background-position-y: 70%;
    padding-bottom: 300px;
    position: relative;
    height: 600px;
     @media screen and (max-width: 992px) {
      height: 393px;
    }
  }
  &__intro::before {
    position: absolute;
    background: url("@/assets/images/our-services/ground/car.png") center no-repeat;
    background-size: cover;
  }
  &__content {
    width: 50%;
  }
  &-img {
    width: 50%;
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  &-title {
    font-size: min(max(29.5px, calc(1.84375rem + ((1vw - 3.2px) * 1.4732))), 46px) !important;
    line-height: 56px;
    margin-bottom: 25px;
    text-align: initial !important;
    // margin-top: 140px;
    text-transform: uppercase;
    @media screen and (max-width: 992px) {
      font-weight: 550;
      line-height: 44px;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
  &-desc {
    line-height: 25px;
    margin-bottom: 25px;
    font-size: min(max(13.5px, calc(0.84375rem + ((1vw - 3.2px) * 0.7589))), 22px);
    @media screen and (max-width: 992px) {
      font-weight: 400;
      line-height: 20px;
      color: #FFFFFF;
      margin-bottom: 5px;
    }
  }
  &-feedback-btn {
    button {
      padding: 0 49px;
      height: 57px;
      background: #E80707;
      color: #FEC50C;
      box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
      font-weight: 400;
      font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px);
      border-radius: 5px;
      transition: all 0.5s ease;
      @media screen and (max-width: 992px) {
        font-weight: 550;
      }
    }
  }

  .pluses {
    background: #F3F3F3;
    padding: 70px 0;
    .title {
      margin-bottom: 35px;
      @media screen and (max-width: 992px) {
        display: flex;
        justify-content: center;
      }
    }
    .plus-title {
      text-transform: uppercase;
      font-weight: 400;
       font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.3226))), 20px);
      display: flex;
      text-align: start;
      line-height: 24px;
       @media screen and (max-width: 992px) {
        margin-bottom: 10px;
        justify-content: center;
       }
    }
    .plus-icon {
      height: 120px;
      padding-bottom: 25px;
    }
  }

  .benefits {
    padding-top: 140px;
    padding-bottom: 70px;
    @media screen and (max-width: 992px) {
      padding-top: 40px;
      display: flex; 
      flex-direction: column;
    }
    &__content {
      margin-right: 33px;
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-right: 0;
      }
    }
    &-title {
      margin-bottom: 39px;
      text-align: start;
       @media screen and (max-width: 992px) {
        font-weight: 550;
        font-size: 26px;
        line-height: 31px;
        text-align: start;
        margin-bottom: 18px;
       }
    }
  }

  .car-types {
    background: url("@/assets/images/our-services/ground/cars.svg") center no-repeat;
    background-size: cover;
    padding-top: 43px;
    width: 1440px;
    height: 400px;
  }
}

.plus-desc {
  display: flex;
  align-items: center;
  width: 80%;
  text-align: center !important;
   font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
  @media screen and (max-width: 992px) {
    width: 100%;
    text-align: center !important;
  }
}
.multiDesc {
  display: flex;
  flex-direction: column;
  align-items: start !important;
  &-text {
    justify-content: center;
    text-transform: none !important;
    font-weight: 400;
    font-size: min(max(19px, calc(1.1875rem + ((1vw - 3.2px) * 0.7143))), 27px) !important;
    text-align: start;
  }
}
.multiWidth {
  width: 80%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.noticeClient {
    @media screen and (max-width: 992px) {
      width: 100%;
    }
    width: 80%;
    margin-top: 21px;
    font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.1786))), 14px);
    text-align: start;
}
.noticeClient2 {
    @media screen and (max-width: 992px) {
      width: 90%;
      text-align: center;
    }
    font-weight: 600;
    text-align: start;
    color: black;
    font-size: 16px !important;
    margin-top: 40px;
    margin-bottom: 48px;
    margin-right: auto;
    margin-left: auto;
}

.col-10 {
  @media screen and (max-width: 776px) {
    width: 100%;
  }
}



.trainDesc2 {
  margin-bottom: 56px;
  text-transform: none !important;
  font-weight: 400 !important;
   font-size: min(max(21px, calc(1.3125rem + ((1vw - 3.2px) * 1.3393))), 36px) !important;
  text-align: initial;
  @media screen and (max-width: 992px) {
    line-height: 22px;
    text-align: center;
    color: #161616;
    font-weight: 500;
    margin-bottom: 34px;
  }
}

.delif {
  align-items: start !important;
  flex-wrap: nowrap !important;
  @media screen and (max-width: 992px) {
    --bs-gutter-x: 0rem;
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
  }
}
.col1 {
  width: 52%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.col2 {
  width: 48%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.timeline1-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(78%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
      @media screen and (max-width: 768px) {
        height: min(max(105%, 5.125rem + (1vw - 4.1%) * 2.1429), 105%);
      }
  }
  .timeline2-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height: min(max(39%, 5.125rem + (1vw - 4.1%) * 2.1429), 64%);
      border-left: 1px dashed #161616 !important;
      @media screen and (max-width: 768px) {
        height:  min(max(55%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      }
  }
  .borderBlock {
    margin: 40px 0 83px 0;
    @media screen and (max-width: 768px) {
      margin: 20px 0 38px 0;
    }
  }
  .avia {
    &-title {
        font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
    }
    &-desc {
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.3571))), 13px);
    }
  }
  .aviaContainer {
    width: 80%;
    margin: auto;
  }
  .noticeClient {
    width: 100%;
    color: black;
    font-weight: 500;
    margin-top: 21px;
    font-size: 16px !important;
    @media screen and (max-width: 768px) {
      margin-right: auto;
      margin-left: auto;
      width: 85%;
      text-align: center;
      line-height: 25px;
    }
  }
  .interContainer {
    width: 93%;
    margin: auto;
  }
</style>