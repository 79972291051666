<template>
    <div class="select" @click="openDrop(select)" :class="{active: open == true}, classCustom">
        <p class="select-p1">{{ selectTitle }}</p>
        <img :src="require('@/assets/icons/selectBot.svg')" v-if="open == false"/>
        <img :src="require('@/assets/icons/selectBot.svg')"  v-else-if="open == true"/>
        <div v-if="open" class="select__dropdown">
            <template  v-for="(item, id) in values" :key="id">
               <p class="select__dropdown-block" @click="selectItem(item.value)">{{ item.value }}</p>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'select', 'value', 'values', 'classCustom'],
    data() {
        return {
            open: false,
            selectTitle: ""
        }
    },
    mounted() {
        this.selectTitle = this.select;
    },
    methods: {
        openDrop(value) {
            this.open = !this.open
        },
        selectItem(value) {
            this.$emit("selectValue", value)
            if (value !== this.select) {
                this.selectTitle = value;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
img {
    user-select: none;
}
.ads {
    padding: 0 1px !important;
}
.select {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    height: 39px;
    background: #FFFFFF;
    border: 1px solid #E3E5E6;
    border-radius: 4px;
    box-sizing: border-box;
    &__dropdown {
        background: white;
        width: 100%;
        z-index: 102;
        position: absolute;
        display: flex;
        flex-direction: column;
        border-left: 1px solid #E3E5E6;
        border-right: 1px solid #E3E5E6;
        border-bottom: 1px solid #E3E5E6;
        border-radius: 0 0 6px 6px;
        top: 0;
        left: 0;
        margin-top: 40px;
        box-sizing: border-box;
        &-block {
            border-radius: 0 0 5px 5px;
            cursor: pointer;
            padding-left: 14px;
            background: #FFFFFF;
            height: 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            &:hover {
                opacity: 0.8;
                background: #0496AD;
                color: white;
            }
            @media (max-width: $mobile + px) {
                font-size: 14px;
            }
        }
    }
    &-p1 {
        @media (max-width: $mobile + px) {
                font-size: 14px;
            }
    }
}
.active {
        border-bottom: none;
        border-radius: 5px 5px 0 0;
    }
</style>