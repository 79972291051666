<template>
  <div>
    <MainHeader />
      <router-view />
    <MainFooter />
  </div>
</template>

<script>
import MainHeader from "./MainHeader";
import MainFooter from "./MainFooter";

export default {
  name: "MainLayout",
  components: { MainHeader, MainFooter }

}
</script>

<style scoped>

</style>