<template>
  <div class="block8 container">
      <p class="block8-p1" v-html="$t('ourPartners')"></p>
      <swiper
          class="style1"
          :space-between="30"
          :breakpoints="breakpoints"
          :lazy="true"
          :navigation="brandNavigation"
          :loop="false"
          :autoplay="autoplay1"
          >
              <swiper-slide v-for="item in brands" :key="item.id" class="style2">
                <div class="style3">
                  <img :src="require('@/assets/images/' + item.image + '.png')" class="style4"/>
                </div>
              </swiper-slide>
      </swiper>
      <img :src="require('@/assets/icons/redArrow2.svg')" class="styleLeft"/>
      <img :src="require('@/assets/icons/redArrow2.svg')" class="styleRight"/>

    </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
// props: ['image', 'salePersents', 'price', 'discription', 'count', 'salePrice', 'link'],
SwiperCore.use([Navigation, Pagination, A11y, Autoplay]);

export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      breakpoints: {
          320: {
          slidesPerView: 'auto',
          spaceBetween: 35
          },
          400: {
            slidesPerView: 'auto',
          spaceBetween: 35
          },
          770: {
            slidesPerView: 'auto',
          spaceBetween: 35
          },
          991: {
            slidesPerView: 'auto',
          spaceBetween: 45
          },
          1440: {
            slidesPerView: 'auto',
            spaceBetween: 45
          },
      },
      autoplay1: {
        delay: 3000,
        disableOnInteraction: false,
      },
      brandNavigation: {
          prevEl: ".styleLeft",
          nextEl: ".styleRight",
      },
      brands: [
        {
          image: "slider1"
        },
        {
          image: "slider2"
        },
        {
          image: "slider3"
        },
        {
          image: "slider4"
        },
        {
          image: "slider5"
        },
        {
          image: "slider6"
        },
        {
          image: "slider7"
        },
        {
          image: "slider8"
        },
        // {
        //   image: "slider9"
        // },
        {
          image: "slider10"
        },
        {
          image: "slider11"
        },
        {
          image: "slider12"
        },
        {
          image: "slider13"
        }
      ]
    }
  },
  mounted() {
    setTimeout(() => {
    const swiperSlide = document.querySelectorAll('.swiper-slide-duplicate')
    swiperSlide.forEach(item => {
      item.style.width = 'auto'
    })
      
    }, 2000);
    
  },
  
}
</script>
<style lang="scss" scoped>
.styleLeft {
  position: absolute;
  top: 80%;
  z-index: 1;
  left: -3%;
  rotate: 180deg;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.styleRight {
  position: absolute;
  top: 80%;
  z-index: 1;
  right: -3%;
  @media screen and (max-width: 768px) {
    display: none;
  }
  
}
.block8 {
  position: relative;
  &-p1 {
    margin-bottom: 80px;
    color: black;
    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.4286))), 36px);
    font-weight: 500;
    @media screen and (max-width: 768px) {
       margin-bottom: 45px;
       margin-top: 30px;
    }
  }
}
.style1 {
  margin-bottom: 90px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}
.style2 {
}
.style3 {
  
}
.style4 {
  user-select: none !important;
  user-select: none;
  height: 55px;
  @media screen and (max-width: 768px) {
    height: 40px;
  }
}

.swiper-slide, .swiper-slide-duplicate {
  width: auto !important;
  height: 100%
  // margin: 0 50px;
}
</style>