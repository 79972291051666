<template>
  <main class="news w100">
    <div class="container">
      <div class="breadcrumbs">
        <router-link to="/">{{ $t('main') }}</router-link>
        <span> / {{ $t('news') }}</span>
      </div>
      <div class="row text-start">
        <div class="news-title">{{ $t('news') }}</div>
      </div>
      <div class="desctop">
        <div class="newsCards" >
          <NewsCardPreview v-for="(item, i) in displayedProducts" :news="item" :key="item.id"/>
        </div>
      </div>
      <div class="mobile">
        <div class="newsCards">
           <NewsCardPreview v-for="(item, i) in news" :news="item" :key="item.id"/>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button @click="showAll = !showAll" class="watch-all-btn" :class="{activeShowAll: showAll == true}" :seeAll="$t('seeAll')" :notseeAll="$t('notseeAll')"></button>
      </div>
    </div>
  </main>
  <OstQues />
</template>

<script>
import OstQues from '@/components/OstQues.vue';
import NewsCardPreview from '@/components/UI/NewsCardPreview.vue';
import news from '@/data/news.json'
export default {
  components: { NewsCardPreview, OstQues },
  data() {
    return {
      news: news.news,
      showAll: false,
      displayedProductsCount: 8,
    }
  },
  computed: {
    displayedProducts() {
      return this.showAll ? this.news : this.news.slice(0, this.displayedProductsCount);
    }
  }
};
</script>

<style scoped lang="scss">
.newsCardNons {
  display: none;
}

.activeAllNews + .newsCardNons {
    display: block;
}
.d-flex {
  @media screen and (max-width: 992px) {
    display: none !important;
  }
}
.breadcrumbs {
  margin-top: 25px;
}
.newsCards {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  @media screen and (max-width: 992px) {
    flex-wrap: nowrap;
    overflow-y: scroll;
    padding: 17px;
    margin-left: -17px;

     &::-webkit-scrollbar {
                width: 0px;
                height: 0px;
                background-color: #f9f9fd;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0px;
                height: 0px;
                background-color: #5AE1E1;
            }

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
                border-radius: 0px;
                height: 0px;
                background-color: #f9f9fd;
            }
  }
}

.container {
  max-width: 1250px;
  margin: 0 auto;
  --bs-gutter-x: 0;
   @media (max-width: 772px) {
    padding: 0 10px;
   }
}
.news {
  width: 100vw;
  padding: 0 0 72px 0;
  @media (max-width: 772px) {
    padding: 72px 0;
  }

  &__intro {
    //margin-top: 74px;
  }

  &-title {
    font-style: normal;
    font-weight: 400;
    font-size: min(max(21.5px, calc(1.34375rem + ((1vw - 3.2px) * 1.2946))), 36px) !important;
    margin-bottom: 35px;
    color: black;
    @media screen and (max-width: 768px) {
      text-align: center;
      margin-bottom: 0;
    }
  }

  &-card {
    width: 95%;
    color: #02283B;
    background: #FFFFFF;
    box-shadow: 0 4px 14px rgba(2, 40, 59, 0.25);
    border-radius: 10px;
    margin-bottom: 25px;

    .card-body {
      text-align: start;
      padding: 20px 14px 37px;

      .card-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 8px;
      }

      .card-desc {
        margin-bottom: 34px;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
      }
    }

    .card-img {
      //img {
      //  width: 100%;
      //}
    }
  }

  .watch-all-btn {
    margin-top: 50px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #FEC50C;
    padding: 10px 49px;
    background: #E80707;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.5s ease;
    &:hover {
      transform: scale(1.04);
      color: white;
    }
    &:after {
      content: attr(seeAll);
    }
  }
  .activeShowAll {
    &:after {
      content: attr(notseeAll);
    }
  }
}
</style>
