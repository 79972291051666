<template>
     <div class="home__intro__form flex-col-between-center forma1 desctop" 
                data-aos="zoom-in" 
                data-aos-delay="200" 
                data-aos-duration="1000">
              <p class="w22-800">{{ $t('leaveRequest') }}</p>
              <div class="home__intro__form-inputs flex-col-between-center">
                  
               <div class="gapInput">
                <input data-aos="zoom-in"  
                  @input="proverka = false" 
                  data-aos-delay="250" 
                  data-aos-duration="1000"
                  type="text" :placeholder="$t('name') + ' ' + '*'" v-model="v$.name.$model" required="true" @keyup="onlyText()"/>
                  <template
                    v-for="(error, index) of v$.name.$errors"
                    :key="index"
                  >
                    <p class="errorValid" v-if="name.length > 0 || proverka">{{ error.$message }}</p>
                  </template>
                </div>

                <div class="gapInput">
                  <input data-aos="zoom-in" 
                      data-aos-delay="300" 
                      data-aos-duration="1000"
                      type="tel" :placeholder="$t('phone') + ' ' + '*'" 
                      v-model="v$.phone.$model" 
                      v-imask="phoneMask" 
                      @input="isNumber" 
                      @focus="proverka = false"
                      @accept="onAccept"
                      @complete="onComplete" required="true"/>
                    <template
                      v-for="(error, index) of v$.phone.$errors"
                      :key="index"
                    >
                      <p class="errorValid" v-if="phone.length > 0 || proverka">{{ error.$message }}</p>
                    </template>
                </div>

                <input class="gapInput gapInputX" data-aos="zoom-in" 
                data-aos-delay="350" 
                data-aos-duration="1000"
                type="text" :placeholder="$t('req1')" v-model="req1" :required="false"/>
                <input class="gapInput" data-aos="zoom-in" 
                data-aos-delay="400" 
                data-aos-duration="1000"
                type="text" :placeholder="$t('req2')" v-model="req2" :required="false"/>
                <input class="gapInput" data-aos="zoom-in" 
                data-aos-delay="450" 
                data-aos-duration="1000"
                type="text" :placeholder="$t('req3')" v-model="req3" :required="false"/>

                <div class="gapInput">
                    <input
                    @input="proverka = false" 
                    data-aos="zoom-in" 
                    data-aos-delay="500" 
                    data-aos-duration="1000"
                    type="email" :placeholder="$t('email')" v-model="v$.email.$model"/>
                    <template
                      v-for="(error, index) of v$.email.$errors"
                      :key="index"
                    >
                      <p class="errorValid" v-if="email.length > 0 || proverka">{{ error.$message }}</p>
                    </template>
                </div>


                <textarea data-aos="zoom-in" 
                data-aos-delay="550" 
                data-aos-duration="1000"
                :placeholder="$t('sendComment')" v-model="comment" :required="false"></textarea>
              </div>
              <button 
                data-aos="zoom-in" 
                data-aos-delay="500" 
                data-aos-duration="1000"
                class="home__intro-send w16-600" type="submit" @click="leaveRequest">{{ $t('send') }}</button>
          </div> 
</template>
<script>
import AOS from 'aos';
import 'aos/dist/aos.css';

import useVuelidate from "@vuelidate/core";
import { email, required, minLength, helpers, } from "@vuelidate/validators";
import { IMaskDirective } from "vue-imask";
import { mapGetters } from 'vuex';

export default {
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      proverka: false,
      v$: useVuelidate(),
      name: "",
      phone: "",
      req1: "",
      req2: "",
      req3: "",
      comment: "",
      email: "",
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
    }
    },

  validations() {
    return {
      name: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        minLength: helpers.withMessage(
          this.$t("errors.nameError"),
          minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        email: helpers.withMessage(this.$t("errors.email"), email),
      },
      phone: {
        required: helpers.withMessage(this.$t("errors.required1"), required),
        minLength: helpers.withMessage(
          this.$t("errors.telLength"),
          minLength(18)
        ),
      },
    };
    },
  computed: {
    ...mapGetters(["getIsAuth"])
  },
  
  methods: {

    resetModalData() {
      this.name = "",
      this.phone = "",
      this.req1 = "",
      this.req2 = "",
      this.req3 = "",
      this.comment = "",
      this.email = "",
      this.v$.$reset()
    },
    
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error("Такого мобильного оператора не существует!");
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      console.log("complete", maskRef.unmaskedValue);
    },


     onlyText() {
      this.name = this.name.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function(match) {
        return match.substr(0, 1);
      });
    },

        
    async leaveRequest() {

      this.proverka = true;
      
      this.v$.name.$validate();
      this.v$.phone.$validate();
      this.getIsAuth ? this.v$.$validate() : ''
      let bool = this.getIsAuth ? !this.v$.name.$invalid && !this.v$.phone.$invalid && !this.v$.email.$invalid : !this.v$.name.$invalid && !this.v$.phone.$invalid
      if (bool) {
        const reqData = {
        name: this.name,
        phone: this.phone,
        cargo: this.req1,
        weight: this.req2,
        type: this.req3,
        note: this.comment,
        email: this.email
        };
        await this.$axios.post('v1/mail/application', reqData)
          .then(res => {
            this.$toaster.success('Ваша заявка успешно отправлена!');
            this.resetModalData();
          })
          .catch(err => {
            this.$toaster.error('Неверные данные');
          });
        }
      else {
         this.$toaster.error('Заполните обязательные поля, пожалуйста!');
      }
        }
    
  },
  mounted() {
    AOS.init(); 
  }
}
</script>
<style lang="scss" scoped>
.errorValid {
  position: absolute;
  line-height: 14px;
  text-align: start !important;
  display: flex;
  justify-content: start;
  margin: auto;
  font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
  margin-top: 0;
  margin-left: 16px;
  color: red;
}
.home {
  width: 100%;
  @media screen and (max-width: 992px) {
    margin-top: 76px;
  }
  .home__intro-title{
    width: 50%;
    font-weight: 500;
    font-size: 56px;
    line-height: 55px;
    line-height: 55px;
  }
  &__intro{
    display: flex;
  }
  .truck1 {
    position: absolute;
    bottom: -14px;
    left: 0;
    @media screen and (max-width: 992px) {
         width: 70%;
          left: -17%;
    }
  }
  &__intro {
    position: relative;
    background: url("@/assets/images/mainBanner.jpg") no-repeat center;
    height: 650px;
    width: 100%;
    background-position-y: 70%;
    background-size: cover;
    padding-top: 100px;
    @media screen and (max-width: 992px) {
      height: 395px;
      padding-top: 65px;
    }


    &__form {
      margin-top: 0;
      width: 421px;
      box-sizing: border-box;
      align-items: start !important;
      border-radius: 10px;
      @media screen and (max-width: 992px) {
        margin-top: 58px;
        margin-left: auto;
        margin-right: auto;
        width: 90% !important
      }
      .gapInput {
        width: 47.51%;
      }
      &-inputs {
        padding: 15px 0 25px 0;
        width: 100%;
        gap: 20px;
        row-gap: 29px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: initial;
        input {
          padding: 0 6px;
          width: 100%;
          height: 51px;
          background: transparent;
          border: 1px solid #FFFFFF;
          border-radius: 5px;
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.1786))), 13px);
          color: white;
          outline: none;
          &:focus {
              box-shadow: 0px 0px 0px 2px rgba(249, 0, 0, 0.79);
            }
        }
        textarea {
          color: white;
          padding: 8px 16px;
          background: transparent;
          border: 1px solid #FFFFFF;
          border-radius: 5px;
          width: 100%;
          height: 50px;
          resize: none;
        }
      }
    }

    &-send {
      background: #FEC50C;
      color: #E80707;
      width: 100%;
      box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
      border-radius: 5px;
      padding: 0 49px;
      font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
      height: 43px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: auto;
      transition: all 0.5s ease;
      &:hover {
        opacity: 2;
        color: red;
      }
    }
  }

  &-img {
    padding: 100px 0;

    img {
      width: 100%;
    }
  }

  .services {
    display: flex;
    @media screen and (max-width: 992px) {
      justify-content: unset !important;
    }
    .service-item {
      position: relative;
      width: 25%;
      &__text {
        position: absolute;
        top: 55%;
        left: 0;
        width: 100%;
      }
      img {
        margin-left: -3%;
      }
    }
    .boat img {
      width: 103.6%;
      @media screen and (max-width: 992px) {
        width: max-content;
      }
    }

    .service__block{
      display: flex;
      justify-content: space-between;
      width: 50%;
    }

    .service-icon {
      padding: 35px 0 35px 35px;

      img {
        width: 100%;
      }
    }

    .service-title {
      font-size: min(max(16px, calc(1rem + ((1vw - 3.2px) * 0.6452))), 23px);
      line-height: 27px;
      text-align: center;
      color: #FFFFFF;
      padding: 10px 33px;
    }
    .service-link {
      background: #FFFFFF;
      box-shadow: 0px 4px 14px rgba(236, 71, 64, 0.3);
      border-radius: 5px;
      color: red;
      font-weight: 600;
      padding: 0 49px;
      height: 47px;
      transition: background 0.5s ease;
      @media screen and (max-width: $tablet + px) {
        height: 37px;
        padding: 0 37px;
      }
      &:hover {
        opacity: 2;
        background: red;
        color: white;
        box-shadow: 2px 1px 10px 2px rgba(255, 0, 0, 0.2);

      }
    }
  }
  @media (max-width: 1200px){}
  @media (max-width:992px){
    /*-------------Intro----------------*/

    .home__intro__form{
      width: 80%;

    }
    .home__intro__form p{
      text-align: center;
    }
    .home__intro-title{
      text-align: center;
      width: 100%;
    font-size: min(max(32px, calc(2rem + ((1vw - 3.2px) * 2.5806))), 56px);
      @media screen and (max-width: 992px) {
        display: flex;
        flex-direction: column;
        align-items: start;
        font-weight: 800;
        text-align: start;
        line-height: 35px;
      }
    }
    .home-img {
      padding: 50px 0;
    }
    /*-------------Intro----------------*/

    /*---------------Service---------------*/
    .service-item{
      width: 49% !important;
    }
    .service-item img {
      margin-left: 0;
      width: max-content;
    }
    .services{
      flex-wrap: wrap;
      gap: 10px;
      @media screen and (max-width: 992px) {
        display: flex;
        flex-wrap: nowrap;
        overflow: scroll; 
        padding: 20px;
        margin-left: -10px;

        &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
                border: transparent;
                color: transparent;
                outline: none;
            }

            &::-webkit-scrollbar-thumb {
                height: 0px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 0px;
                height: 0px;
            }
      }
    }
    .service__block{
      width: 100% !important;
    }

    /*---------------Service---------------*/
  }
  @media (max-width: 772px){
    /*-------------Intro----------------*/
    .home__intro__form p{

    }
    /*-------------Intro----------------*/
  }
  @media (max-width: 577px){
    .home-img {
      padding: 30px 0;
    }
    .service-item{
      width: 65% !important;
    }
  }
}
.w22-800 {
  font-weight: 400;
  color: #FEC50C;
  font-size: min(max(23px, calc(1.4375rem + ((1vw - 3.2px) * 0.7527))), 30px);
}
.home__intro-send {
  font-weight: 400 !important;
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
}
.gapInputX[type=text] {
}
</style>