<template>
    <div class="card card-custom">
        <div class="wrapper" :class="customclass">
            <p class="cover-image"></p>
        </div>
        <div class="character">
            <p class="texxt1">{{ title }}</p>
            <p class="texxt2">
                <vue3-autocounter
                    ref="counter"
                    :startAmount="0"
                    :endAmount="+sum" separator=' '
                    :duration="3"
                    :autoinit="true"/>
            </p>
        </div>
    </div>
</template>
<script>
import Vue3autocounter from "vue3-autocounter";
export default {
    props: ['title', 'sum', 'customclass'],
    data() {
        return {
            boolCount: false
        }
    },
    components: {
        "vue3-autocounter": Vue3autocounter,
    },
    mounted() {        
        window.addEventListener('scroll', this.visiblityCounter)
        this.$refs.counter.pause();
    },
    unmounted () {
        window.removeEventListener('scroll', this.visiblityCounter);
    },
    methods: {
        visiblityCounter() {
        const element = document.querySelector('.card-custom');
        const bounding = element.getBoundingClientRect();
        const isVisible = (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight + 100 || document.documentElement.clientHeight + 100) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );

        if (isVisible) {
            this.boolCount ? null : this.$refs.counter.start();
            this.boolCount = true
        } else {
            this.boolCount ? this.$refs.counter.reset() : null;
            this.boolCount = false
        }
        }
    }
}
</script>
<style lang="scss" scoped>
.custom1 {
    background: #F3F3F3 !important; 
}
.texxt1 {
    color: #161616;
    cursor: default;
    font-style: italic;
    font-weight: 700;
    font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.6452))), 20px);
    text-transform: uppercase;
}
.texxt2 {
    color: #161616;
    cursor: default;
    line-height: 55px;
    font-style: italic;
    font-weight: 500;
    font-size: min(max(45px, calc(2.8125rem + ((1vw - 3.2px) * 1.6129))), 60px);
}
*{
    box-sizing: border-box;
}
.card {
        border: none;
    // width: 300px;
     width: 100%;
    // height: 400px;
    height: 150px;
    border-radius: 15px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    perspective: 2500px;
    // margin: 0 50px;
    @media (max-width: $tablet + px) {
        align-items: center;
    }
}
.cover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    filter: blur(0);
    transition: .5s ease;
}
.wrapper {
    border-radius: 10px;
    position: absolute;
    background: white;
    border: 1px solid #d2d2d2;
    width: 240px;
    height: 150px;
    height: 100%;
    z-index: -1;
    transition: .5s ease;
    box-shadow: 0 0 15px rgb(0, 0, 0, .5); 
     @media (max-width: $tablet + px) {
        width: 100%;
     }
}
.card:hover .wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgb(0, 0, 0, .75); ;
}

.wrapper::before,
.wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: .5s ease;
    position: absolute;
    border-radius: 10px;
    left: 0;
}

.wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(
        to top,
        transparent 46%,
        rgba(12,13,19, .5) 68%
        rgba(12,13,19) 97% 
    );
}

.wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(
        to top,
        transparent 46%,
        rgba(12,13,19, .5) 68%
        rgba(12,13,19) 97% 
    );
}

.card:hover .wrapper::before, .wrapper::after {
    opacity: 1;
}

.card:hover .wrapper::after {
    height: 120px;
}

.card:hover .cover-image {
    filter: blur(2px);
}
.character {
    // width: 295px;
    width: 250px;

    height: 80%;
    object-fit: contain;

    opacity: 1;
    bottom: 3px;
    transition: .5s ease;
    position: absolute;
    z-index: -1;
}
.card:hover .character {
    opacity: 1;
    transform: translate3d(0%, -10.5%, 400px);
}
</style>