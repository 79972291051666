<template>
  <!-- Modal -->
  <div class="modal fade" id="loginForm" tabindex="-1" aria-labelledby="loginForm" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content login p-0" v-click-outside="resetModalData" >
          <div class="login__intro d-flex justify-content-center align-items-center">
            <div class="login-form">
              
              <div action="" class="formAct">
                <div class="form-title">{{ $t('auth') }}</div>

                <div class="form-body">
                  
                  <div class="data-inputs" v-if="type === 'login'">

                    <!-- @keyup="onlyText()" -->
                    <input placeholder="E-mail"  type="text" v-model="v$.email.$model">
                    <template
                      v-for="(error, index) of v$.email.$errors"
                      :key="index"
                    >
                      <p class="errorValid">{{ error.$message }}</p>
                    </template>

                    
                    <!-- <p class="errorValid">{{ errors }}</p> -->
                    <input :placeholder="$t('password')" type="password" v-model="v$.password.$model">
                    <template
                      v-for="(error, index) of v$.password.$errors"
                      :key="index"
                    >
                      <p class="errorValid">{{ error.$message }}</p>
                    </template>

                  </div>

                </div>
                <div class="form-actions text-center">
                  <a>
                    <button class="save-btn" @click="signIn">{{ $t('SignIn') }}</button>
                  </a>
                  <!-- <div class="privacy d-flex text-start justify-content-center" v-if="type === 'register'">
                    <CheckboxCustom id="privacy-check"/>
                    <input class="checkbox" type="checkbox" id="privacy-check" name="primary">
                    <label class="privacy-text" for="privacy-check">Я соглашаюсь с условиями <a class="privacy-link" href="#">публичной
                      оферты</a> и обработкой моих персональных данных в порядке, предусмотренном публичной
                      офертой.</label><br>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          
          <a href="#" class="close" data-bs-dismiss="modal">
            <img class="closeIcon" :src="require('@/assets/icons/closeIcon.svg')" aria-label="Close" aria-hidden="true"/>
          </a>
      </div>
    </div>
  </div>
</template>
<script>
import CheckboxCustom from '@/components/UI/CheckboxCustom.vue';

import RadioCustomVue from '@/components/RadioCustom.vue';
import { mapActions } from 'vuex';

import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email } from "@vuelidate/validators";


export default {
  components: { CheckboxCustom, RadioCustomVue},
  data() {
    return {
      v$: useVuelidate(),
      type: 'login',
      email: null,
      password: null,
      toast: null
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        email: helpers.withMessage(this.$t("errors.email"), email),
      },
      password: {
        required: helpers.withMessage(this.$t("errors.required1"), required),
        minLength: helpers.withMessage(
          this.$t(""),
          minLength(8)
        ),
      },
    };
  },
  methods: {
    ...mapActions(["checkAuth"]),


    // onlyText() {
    //   this.email = this.email.replace(
    //     /[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s]/gi,
    //     ""
    //   );
    // },
    resetModalData() {
      this.email = "",
      this.password = "",
      this.v$.$reset()
    },

    async signIn() {

      this.v$.$validate();

      if (!this.v$.$invalid) {
        const authData = {
          email: this.email,
          password: this.password,
        };

        await this.$axios.post('auth/login', authData)
          .then(res => {
            localStorage.setItem('access_token', res.data.token)
            localStorage.setItem('crm_url', res.data.url)
            window.open(res.data.url, '_blank');
            location.reload();
            this.checkAuth()
            this.$toaster.success('Вы успешно авторизовались!');
            this.resetModalData();
            this.v$.$reset()
          })
          .catch(err => {
            this.$toaster.error('Неверные данные');
          })
      } else {
        this.$toaster.error('Неправильный логин или пароль!');
      }
    }
  },
}
</script>
<style lang="scss" scoped>
// .modal {
//   @media screen and (max-width: 992px) {
//       top: 7%;
//   }
// }
.errorValid {
  text-align: start;
  font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
  margin-top: -10px;
  padding-bottom: 10px;
  margin-left: 16px;
  color: red;
}
.model-content {
  position: relative;
}

.closeIcon {
  position: absolute;
  top: 10px;
  right: 5px;
  transition: 0.5s ease;
  &:hover {
    transform: scale(1.15);

  }
  @media screen and (max-width: 992px) {
    right: 5px;
  }
}
.formAct {
  @media screen and (max-width: 992px) {

  }
}
.form-type-radios {
        padding: 23px 0 28px;
        display: flex;
        justify-content: center;
}
input {
  width: 380px;
  outline-color: red;
}
.radio {
    width: 50%;
    display: flex;
    align-items: center;
    gap: 5px;
  }

.privacy {
        padding-top: 23px;
        display: flex;
        align-items: flex-start !important;

        .checkbox {
          margin: 0 9px;
          width: 16px;
          height: 16px;
          background: #0AAAFA;
          border-radius: 2px;

          .privacy-link {
            text-decoration-line: underline !important;
          }
        }

        .privacy-text {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          color: #02283B;
          a {
            color: #02283B; 
          }
        }
      }
.modal-dialog {
  margin-top: 5.5%;
  @media screen and (max-width: 992px) {
    margin-top: 22%;
  }
}
.data-inputs {
  input {
    width: 100%;
  }
}

.login-form {
  padding: 35px 70px 70px 70px;
  width: 500px;
  @media screen and (max-width: 992px) {
    width: 100%;
    padding: 15px 20px 20px 20px;
  }
}
.save-btn {
  font-weight: 400 !important;
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
  width: 100% !important;
  height: 45px !important;
}
.form-title {
  font-weight: 700 !important;
  margin-bottom: 25px !important; 
}
</style>