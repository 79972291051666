<template>
  <main class="about-company w100">
    <section class="about">
      <div class="container">
        <div class="breadcrumbs">
          <router-link to="/">{{ $t('main') }}</router-link>
          <span> / {{ $t('aboutCompany') }}</span>
        </div>
        <div class="about__inner">
          <div class="about__info">
            <div class="info__inner">
              <div class="info__title title" style="font-weight: 500;">{{ $t('aboutCompany') }}</div>
              <div class="info__block">
                <div class="info__item">
                  <div class="item__text" v-html="$t('aboutCompanyText1')">
                  </div>
                  <div class="numbers__items">
                  <div class="items__title text-uppercase numbers__items-title">{{ $t('goalOutCompany') }}</div>
                  <div class="items__info">
                    <div style="display: flex; flex-direction: column; gap: 8px; color: black;">
                      <p>{{ $t('ТОО') }} “Tang-Nur Logistics Group”</p>
                      <p>{{ $t('ТОО') }} “Sunshine International Logistics”</p>
                      <p>{{ $t('ТОО') }} “Tang-Nur Container Services” </p>
                      <p>“Tong-Nuri Tashkent” MChJ</p>
                      <p>“XinJiang XinTong HengDa International Freight Forwarding” Co.LTD</p>
                    </div>
                  </div>
                </div>
                </div>
                <div class="info__image">
                  <div class="image__item"><img :src="require('@/assets/images/about-company/about-1.png')"/></div>
                  <div class="image__item"><img :src="require('@/assets/images/about-company/about-2.png')"/></div>
                </div>
              </div>
            </div>
          </div>
          <div class="about__numbers">
            <div class="numbers__inner">
              <div class="numbers__title title order-counter">{{ $t('companySums') }}</div>
              <div class="numbers__block">
                <div class="numbers__amount">
                  <div class="amount__category">
                    <company-card :title="$t('orderS')" sum="50000" customclass="custom1"/>
                    <company-card :title="$t('sotrudniki')" sum="50"/>

                    <company-card :title="$t('strani')" sum="10" class="desctop" />
                    <company-card :title="$t('ofics')" sum="3" customclass="custom1" class="desctop"/>
                  </div>
                  <template class="mobile">
                    <div class="amount__category">
                      <company-card :title="$t('strani')" sum="10" />
                      <company-card :title="$t('ofics')" sum="3" customclass="custom1"/>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="certificat">
                  <p class="certificat-title">{{ $t('certif') }}</p>
                  <swiper
                    class="certificat-swiper"
                    :modules="modules"
                    :space-between="48"
                    :breakpoints="swiper.breakpoints1"
                    :navigation="swiper.Navigation1"
                    :loop="true"
                    :autoplay="{
                      delay: 3000
                    }"
                  >
                  <swiper-slide class="swiperCompany" v-for="item in cert" :key="item.id">
                        <CertificatCard  :cert="item"/>
                  </swiper-slide>
                </swiper>
                  <div class="arrows">
                      <img :src="require('@/assets/icons/redArrow.svg')" class="arrows-left"/>
                      <img :src="require('@/assets/icons/redArrow.svg')" class="arrows-right"/>
                  </div>
          </div>
        </div>
      </div>
    </section>
    <OstQues />
  </main>
</template>

<script>
import CertificatCard from '@/components/CertificatCard.vue';
import CompanyCard from '@/components/CompanyCard.vue';
import cert from '@/data/cert.json'

import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import { Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/autoplay';
import OstQues from '@/components/OstQues.vue';

export default {
  components: { CompanyCard, CertificatCard, Swiper, SwiperSlide, OstQues },  
  data() {
    return {
      cert: cert.cert,
      count: 0,
      speed: 100,
      target: 0,
      swiper: {
        breakpoints1: {
          992: {
            slidesPerView: 4,
          },
          320: {
            slidesPerView: 2
          },
        },
        Navigation1: {
            prevEl: '.arrows-left',
            nextEl: '.arrows-right',
        }
      }
    };
  },

  mounted() {
    this.target = +this.$el.getAttribute('numberCom');
    this.updateCount();
  },
  methods: {
    updateCount() {
      const inc = this.target / this.speed;

      if (this.count < this.target) {
        this.count = Math.ceil(this.count + inc);
        setTimeout(this.updateCount, 1);
      } else {
        this.count = this.target;
      }
    }
  },
  
  setup() {
    return {
      modules: [Navigation, Autoplay],
    };
  },
};

</script>

<style scoped lang="scss">
.arrows-left {
  cursor: pointer;
  border: 2px solid #00000070;
  padding: 7px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -80px;
  z-index: 1;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.1);
  }
}

.arrows-right {
  cursor: pointer;
  rotate: 180deg;
  border: 2px solid #00000070;
  padding: 7px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: -80px;
  z-index: 1;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.1);
  }
}

.arrows-right {

}
.certificat {
  display: flex;
  flex-direction: column;
  gap: 75px;
  position: relative;
  @media screen and (max-width: 992px) {
    gap: 5px;
  }
  &-title {
      font-size: min(max(21.5px, calc(1.34375rem + ((1vw - 3.2px) * 1.2946))), 36px) !important;
      text-align: start;
      text-transform: none;
      color: black;
      @media screen and (max-width: 992px) {
        text-align: center;
      }
  }
  &-swiper {
    padding: 30px 26px;
    margin: unset;
    margin: 0px 41px;
    @media screen and (max-width: 992px) {
      margin: 0;
      padding: 0px 26px;
    }
  }
}
.swiperCompany {
  display: flex !important;
}
.image__item {
  @media screen and (max-width: 992px) {
    img {
      width: 100% !important;
      height: auto !important;
    }
  }
}
.title {
  font-size: min(max(21.5px, calc(1.34375rem + ((1vw - 3.2px) * 1.2946))), 36px) !important;
  font-weight: 400;
  margin-bottom: 35px;
  text-transform: none;
  text-align: left;
  color: #161616;
}
.blue {background-color: #F3F3F3;}
.white {background-color: #fff;}
.c-title {
  font-size: clamp(0.875rem, 0.799rem + 0.38vw, 1.25rem);
  font-weight: 900 !important;
  opacity: 1 !important;
  line-height: 23px;
  color: black;
  text-transform: uppercase;
  padding-top: 25px;}
.it-title {
  font-size: clamp(0.875rem, 0.736rem + 0.7vw, 1.563rem);
  font-style: italic;
  font-weight: 550;
}
.about {
  min-height: 500px;
  padding-top: 50px;
   @media screen and (max-width: 992px) {
    margin-top: 76px;
    padding-top: 26px;
   }
  &__inner {}
  &__info {
    margin-bottom: 54px;
     @media screen and (max-width: 992px) {
      margin-bottom: 28px;
     }
  }
  .info {
    &__title:last-child {margin-bottom: 0;}
    &__block {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;}
    &__item {
      max-width: 650px;
      min-height: 50px;
      line-height: 23px;
    }
    &__image {display: flex;}
  }
  .item {
    &__text {
        font-size: min(max(14px, calc(0.875rem + ((1vw - 3.2px) * 0.2151))), 16px);
      font-weight: 400;
      margin-bottom: 20px;
      text-align: left;
    }
  }

  .about__numbers {
    // margin-bottom: 153px;
    margin-bottom: 53px;
    @media screen and (max-width: 768px) {
      margin-bottom: 10px;
    }
    .numbers {
      &__block {
        display: flex;
        gap: 45px;
      }
      &__amount {
        // max-width: 700px;
        width: 100%;
        height: 20%;
        display: flex;
        flex-direction: column;
        gap: 18px;
        @media screen and (max-width: 992px) {
          width: 100%;
        }
      }
      &__items {
        width: 56%;
        @media screen and (max-width: 992px) {
          width: 100%;
        }
      }
    }
    .amount {
      &__category {
        display: flex;
        box-sizing: border-box;
        gap:15px;
      }
    }
    .category {
      &__blocks {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 48%;
        height: 40%;
        border-radius: 10px;
        filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
        text-align: center;
      }
      &__blocks:last-child {margin-right: 0;}
      &__stuff {
        font-size: clamp(45px, calc(2.8125rem + ((1vw - 3.2px) * 1.6129)), 60px);
        font-style: italic;
        padding: 0 60px 25px;
        font-weight: 400;
        color: black;
      }
    }
    .items {
      &__title {
        font-weight: 400;
        margin-bottom: 20px;
        text-align: left;
          font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.3226))), 20px);
      }
      &__info {
        display: flex;
        flex-direction: column;
        gap: 15px;
        text-align: left;
        max-width: 1000px;
         font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px) !important;
      }
    }
    .info {
      &__text:first-child {
        font-weight: 600;
        font-style: italic;
      }
    }
  }
}

  @media (max-width: 1400px){
    .image__item:last-child{display: none;}
  }
  @media (max-width: 992px){
    .numbers__block{flex-direction:column;}
    .numbers__amount{
      margin-bottom: 40px;
      margin-right: 0;
    }
    .numbers__items{
      margin: 0px;
      width: 100%;
    }
  }
  @media (max-width: 772px){
    .image__item:last-child{display: block;}
    .image__item img{
      max-width: 300px;
      height: 360px;
    }
    .info__block{
      display: flex;
      flex-direction: column;}
    .info__item{width: 100%;}
    .info__image{max-width: 100%;}
  }
  @media (max-width: 577px){
    .numbers__amount{
      flex-direction: row;
      justify-content: center;
    }
    .amount__category{
      width: 100%;
    }
    .image__item img{
      max-width: 210px;
      height: 260px;
    }
    .category__blocks{width: 100%;}
    .title{text-align: center;}
    .info__title{
      text-align: center;
       font-size: min(max(21.5px, calc(1.34375rem + ((1vw - 3.2px) * 1.2946))), 36px) !important;
       @media screen and (max-width: 992px) {
        font-weight: 500;
        line-height: 65px;
        text-align: center;
        color: #02283B;
        margin-bottom: 0;
       }
    }

  }
  .numbers__title {
    color: black;
    @media screen and (max-width: 992px) {
      font-weight: 400  ;
      font-size: 20px;
      line-height: 23px;
      text-align: center;
    }
  }

  .info__image {
    width: 45%;
    @media screen and (max-width: 992px) {
      margin-top: 32px;
      width: 100%;
    }
    .image__item {
      width: 100%;
      img {
        width: 100%;
        height: 100%;
        @media screen and (max-width: 992px) {
          max-width: 100% !important;
        }
      }
    }
  }
  .info__block {
     @media screen and (max-width: 992px) {
      align-items: center !important;
     }
  }
  .numbers__items {
    text-align: start;
    &-title {
      text-transform: none !important;
      color: #161616;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 1.0714))), 30px);
      @media screen and (max-width: 992px) {
        text-align: center;
      }
    }
  }
</style>