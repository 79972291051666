<template>
  <div class="details">
    <div class="details__intro">
      <img :src="require('@/assets/icons/track2.svg')" class="truck1"/>
      <div class="container">
        <div class="row">
          <div class="details1 col-6 ">
            <div class="details-title w46-600" style="font-weight: 400;">
              {{ $t('ourServ2') }}
            </div>
            <div class="col-10">
              <p class="details-desc w14-400 text-start" v-html="$t('trainDesc')">
              </p>
            </div>
            <div class="details-feedback-btn d-flex justify-content-start">
              <button class="w16-700 feedback-btn" data-bs-toggle="modal" data-bs-target="#orderForm">
                {{ $t('leaveRequest') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container railway-benefits">
      <div class="railway-title b36-700 text-uppercase trainDesc2">{{ $t('trainDesc2') }}</div>
      <div class="row d-flex justify-content-between align-items-center w100 delif">
        <div class="col-6 railway-left-benefits col1">
          <ul class="timeline">
            <li class="event">
              <p>
                {{ $t('railway.desc1') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('railway.desc2') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('railway.desc3') }}
              </p>
            </li>
            <div class="timeline1-border"></div>
          </ul>
        </div>
        <div class="col-6 col2">
          <ul class="timeline">
            <li class="event">
              <p>
                {{ $t('railway.desc4') }}
              </p>
            </li> 
            <li class="event">
              <p>
                {{ $t('railway.desc5') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('railway.desc6') }}
              </p>
            </li>
            <li class="event">
              <p>
                {{ $t('railway.desc7') }}
              </p>
            </li>
            <!-- <li class="event">
              <p>
                Предоставление сквозных ставок и т.д.
              </p>
            </li> -->
            <div class="timeline2-border"></div>
          </ul>
        </div>
      </div>
      <p v-html="$t('noticeForClient')" class="noticeClient">
      </p>
    </div>

    <div class="trainCardView container">
      <p class="trainCardView-p1">{{ $t('trainDesc3') }}</p>
      <div class=desctop v-if="isDesktop">
        <div class="trainCardView__card">
          <TrainViewCard :card="train[0]" class="trainCardView__card-train"/>
          <TrainViewCard :card="train[1]" class="trainCardView__card-train"/>
          <TrainViewCard :card="train[2]" class="trainCardView__card-train"/>
          <TrainViewCard :card="train[3]" class="trainCardView__card-train"/>
        </div>
      </div>

      <div class="mobile" v-else>
          <swiper
                class="swipeeeer"
                :breakpoints="breakpoints1"
                    :space-between="35"
                :lazy="true">
                <swiper-slide>
                  <TrainViewCard :card="train[0]" class="trainCardView__card-train"/>
                </swiper-slide>
                <swiper-slide>
                  <TrainViewCard :card="train[1]" class="trainCardView__card-train"/>
                </swiper-slide>
                <swiper-slide>
                  <TrainViewCard :card="train[2]" class="trainCardView__card-train"/>
                </swiper-slide>
                <swiper-slide>
                  <TrainViewCard :card="train[3]" class="trainCardView__card-train"/>
                </swiper-slide>
            </swiper>
      </div>
    </div>
    
    <!-- <div class="pluses">
      <div class="container">
        <div class="b36-700 text-uppercase title premush">{{ $t('premush') }}</div>
        <div class="benefit-list align-items-start premush1">
          <div class="benefit-item ">
            <div class="plus-icon d-flex justify-content-center align-items-center">
              <img :src="require('@/assets/images/our-services/icons/wallet.png')" alt="">
            </div>
            <div class="plus-title b20-700">
              {{ $t('premushTitle1') }}
            </div>
            <div class="plus-desc text-start">
              {{ $t('premushDesc1') }}
            </div>
          </div>
          <div class="benefit-item">
            <div class="plus-icon d-flex justify-content-center align-items-center">
              <img :src="require('@/assets/images/our-services/icons/earth.png')" alt="">
            </div>
            <div class="plus-title b20-700">
              {{ $t('premushTitle2') }}
            </div>
            <div class="plus-desc text-start">
              {{ $t('premushDesc2') }}
            </div>
          </div>
          <div class="benefit-item">
            <div class="plus-icon d-flex justify-content-center align-items-center">
              <img :src="require('@/assets/images/our-services/icons/navigator.png')" alt="">
            </div>
            <div class="plus-title b20-700">
              {{ $t('premushTitle3') }}
            </div>
            <div class="plus-desc text-start">
              {{ $t('premushDesc3') }}
            </div>
          </div>
          <div class="benefit-item">
            <div class="plus-icon d-flex justify-content-center align-items-center">
              <img :src="require('@/assets/images/our-services/icons/container.png')" alt="">
            </div>
            <div class="plus-title b20-700">
              {{ $t('premushTitle4') }}
            </div>
            <div class="plus-desc text-start">
              {{ $t('premushDesc4') }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="advantBack">
      <div class="advant">
          <p class="advant-title">{{ $t('premush') }}</p>
          <div class="advant__content">
              <div class="advant__content__box advant__content__box1">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/wallet.png')" alt=""></div>
                <p class="advant__content__box-p1" v-html="$t('premushTitle1')"></p>
                <p class="advant__content__box-p2" v-html="$t('premushDesc1')"></p>
              </div>
              <div class="advant__content__box advant__content__box2">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/earth.png')" alt=""></div>
                <p class="advant__content__box-p1" v-html="$t('premushTitle2')"></p>
                <p class="advant__content__box-p2" v-html="$t('premushDesc2')"></p>
              </div>
              <div class="advant__content__box advant__content__box">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/navigator.png')" alt=""></div>
                <p class="advant__content__box-p1" v-html="$t('premushTitle3')"></p>
                <p class="advant__content__box-p2" v-html="$t('premushDesc3')"></p>
              </div>
              <div class="advant__content__box advant__content__box4">
                <div class="advant__content__box-img"><img :src="require('@/assets/images/our-services/icons/container.png')" alt=""></div>
                <p class="advant__content__box-p1" v-html="$t('premushTitle4')"></p>
                <p class="advant__content__box-p2" v-html="$t('premushDesc4')"></p>
              </div>
          </div>
      </div>
    </div>
    
  </div>
  <ModalOrderForm />
  <OstQues />
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import 'swiper/css';

import ModalOrderForm from "@/modules/app/views/layout/main/ModalOrderForm";
import TrainViewCard from '@/components/TrainViewCard.vue';
import train from '@/data/train.json'
import OstQues from "@/components/OstQues.vue";
export default {
  name: "RailwayDetails",
  data() {
    return {
      isDesktop: window.screen.width > 992,
      train: train.news,
      breakpoints1: {
          992: {
          slidesPerView: 1.15,
          },
          768: {
          slidesPerView: 1.15,
          },
          410: {
          slidesPerView: 1.15,
          },
          320: {
          slidesPerView: 1.15,
          },
      },
    }
  },
  components: { ModalOrderForm, TrainViewCard, OstQues, Swiper, SwiperSlide }
};
</script>

<style lang="scss" scoped>
.advantBack {
  padding: 52px 0 95px 0;
  background: #F3F3F3;
  @media screen and (max-width: 768px) {
    margin-top: 53px;
    padding: 46px 0 45px 0;
  }
}
.advant {
  width: 1250px;
  margin: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: $laptop + px) {
      width: 1140px;
  }

  @media screen and (max-width: 1200px) {
    width: 960px;
  }

  @media screen and (max-width: $tablet + px) {
    width: 720px;
  }

  @media screen and (max-width: $mobile + px) {
    width: 95%;
  }
  &-title {
    margin-bottom: 36px;
    font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.4286))), 36px);
    color: black;
    font-weight: 400;
  }
  &__content {
    display: flex;
    gap: 2.4%;
    justify-content: center;
    @media screen and (max-width: 770px) {
        flex-direction: column;
        gap: 32px;
    }
    &__box {
      width: 20%;
      display: flex;
      flex-direction: column;
      gap: 14px;
      @media screen and (max-width: 770px) {
        width: 100%;
      }
      &-img {    
        width: 100%;
        display: flex;
        height: 83px;
        justify-content: center;
        align-items: center;
      }
      &-p1 {
        font-weight: 500;
        font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0))), 17px);
        text-transform: uppercase;
        color: #161616;
      }
      &-p2 {
        text-align: start;
        font-size: 15px;
        color: #161616;
        font-weight: 400;
        @media screen and (max-width: 770px) {
          text-align: center;
        }
       
      }
    }
  }
}

.benefit-item  {
  width: 21.5%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}

.timeline {
  border-left: 0px solid red !important;
  p {
    color: #161616 !important;
    font-weight: 500;
  }
}
.timeline1-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(74%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
      @media screen and (max-width: 768px) {
        height: min(max(115%, 5.125rem + (1vw - 4.1%) * 2.1429), 115%);
      }
  }
  .timeline2-border {
      z-index: -2;
      position: absolute;
      left: -1px;
      top: min(max(410px, calc(25.625rem + ((1vw - 3.2px) * -33.3333))), 15px);
      width: 100%;
      height:  min(max(87%, calc(5.125rem + ((1vw - 4.1%) * 2.1429))), 100%);
      border-left: 1px dashed #161616 !important;
  }

.event {
  p {
      font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px) !important;
  }
}
.b36-700 {
    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.2903))), 36px) !important;
}
.trainCardView {
  margin-top: 85px;
  @media screen and (max-width: 768px) {
    margin-top: 49px;
  }
  &-p1 {
    text-align: center;
    justify-content: center;
    color: #161616;
    display: flex;
    font-weight: 400;
      font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.2903))), 36px);
    line-height: 36px;
    margin-bottom: 35px;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
  &__card {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 13.04%;
    justify-content: center;
    row-gap: 44px;
    padding: 12px 15px;
    margin: 0 -12px;
    @media screen and (max-width: 768px) {
      flex-wrap: nowrap;
      justify-content: start;
    }
    &::-webkit-scrollbar {
                width: 0;
                height: 0;
                background: transparent;
                border: transparent;
                color: transparent;
                outline: none;
            }

            &::-webkit-scrollbar-thumb {
                height: 0px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 0px;
                height: 0px;
            }
  }
}
.plus-desc {
  font-weight: 400;
   font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
  line-height: 20px;
  text-align: start;
  @media screen and (max-width: 992px) {
    font-weight: 400;
    line-height: 20px;
    text-align: center !important;
  }
}
.premush {
  text-transform: none !important;
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: min(max(18px, calc(1.125rem + ((1vw - 3.2px) * 1.6071))), 36px);
  line-height: 31px;
}
.premush1 {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    gap: 30px;
  }
}
.col-6 {
  @media screen and (max-width: 992px) {
    width: 100%;
  }
}
.railway-left-benefits {
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 10px;
  }
}
.delif {
  align-items: start !important;
  flex-wrap: nowrap !important;
  @media screen and (max-width: 992px) {
    --bs-gutter-x: 0rem;
    margin: auto;
    width: 95%;
    display: flex;
    flex-direction: column;
  }
}
.details1 {
  position: absolute;
  top: 0;
  margin-top: 100px;
  width: 45.68%;
  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 30px;
  }
}

.truck1 {
    position: absolute;
    bottom: -0px;
    right: 0;
    width: 42%;
     @media screen and (max-width: 992px) {
     width: 70%;
      right: -20px;
      bottom: -0px;
    }
  }
.details {
  @media screen and (max-width: 992px) {
    margin-top: 76px;
  }
  &__intro {
    position: relative;
    height: 600px;
    background: url("@/assets/images/bg/rail.png") center no-repeat;
    background-size: cover;
    background-color: black;
    padding-top: 84px;
    background-position: center;
    background-position-y: 70%;
    padding-bottom: 237px;
    position: relative;
    @media screen and (max-width: 992px) {
      height: 393px;
    }
  }

  &__intro::before {
    position: absolute;
    background: url("@/assets/images/our-services/ground/car.png") center no-repeat;
    background-size: cover;
  }

  &__content {
    width: 50%;
  }

  &-img {
    width: 50%;
    margin-right: 37px;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

 &-title {
    font-size: min(max(30.5px, calc(1.90625rem + ((1vw - 3.2px) * 1.3839))), 46px) !important;
    line-height: 56px;
    margin-bottom: 25px;
    // margin-top: 140px;
    text-transform: uppercase;
    @media screen and (max-width: 992px) {
      font-weight: 550;
      line-height: 44px;
      margin-bottom: 5px;
      margin-top: 0;
    }
  }
  &-desc {
    line-height: 30px;
    margin-bottom: 25px;
    font-size: min(max(13.5px, calc(0.84375rem + ((1vw - 3.2px) * 0.7589))), 22px);
    @media screen and (max-width: 992px) {
      font-weight: 400;
      line-height: 20px;
      color: #FFFFFF;
      margin-bottom: 5px;
    }
  }
 &-feedback-btn {
    button {
      padding: 0 49px;
      height: 57px;
      background: #E80707;
      color: #FEC50C;
      box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
      font-weight: 400;
      font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px);
      border-radius: 5px;
      transition: all 0.5s ease;
      @media screen and (max-width: 992px) {
        font-weight: 550;
      }
    }
  }

  .railway-benefits {
    position: relative;
    //.railway-left-benefits {
    //  margin-right: 70px;
    //}
  }

  .railway-benefits::after {
    position: absolute;
    background: url("@/assets/images/our-services/rails.svg") center no-repeat;
    background-size: cover;
  }

  .railway-title {
    margin: 105px 0 35px 0;
     @media screen and (max-width: 992px) {
      margin: 22px 0 32px 0;
      font-weight: 550;
      font-size: 26px;
      line-height: 31px;
      text-align: center;
     }
  }

  .rails-img {
    padding-top: 100px;

    img {
      width: 100%;
    }
  }

  .pluses {
    background: #F3F3F3;
    // margin-bottom: 140px;
    margin-top: 60px;
    padding: 70px 0;
    @media screen and (max-width: 992px) {
      margin-top: 50px;
      padding: 50px 0;
    }
    .title {
      margin-bottom: 32px;
      @media screen and (max-width: 992px) {
        margin-left: 0;
        margin-bottom: 25px;
      }
    }
    .plus-title {
       font-weight: 400;
          font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.3226))), 20px);
        line-height: 22px;
        margin: 10px 0;
      margin: 10px 0;
      text-align: start;
      text-transform: uppercase;
      @media screen and (max-width: 992px) {
        font-weight: 550;
        line-height: 22px;
        margin: 10px 0;
        text-align: center;
      }
    }
    .plus-icon {
      height: 120px;
      @media screen and (max-width: 992px) {
        height: auto;
      }
    }
  }

  .benefit-list {
    margin: 32px 0;
    .benefit-item {
    }
  }

  .car-types {
    background: url("@/assets/images/our-services/ground/cars.svg") center no-repeat;
    background-size: cover;
    padding-top: 43px;
    width: 1440px;
    height: 400px;
  }
}
.trainDesc2 {
  text-transform: none !important;
  font-weight: 400 !important;
  font-size: min(max(21.5px, calc(1.34375rem + ((1vw - 3.2px) * 1.2946))), 36px) !important;
}
.noticeClient {
  margin-top: 26px;
  color: #000000;
  font-weight: 500;
  font-size: min(max(13px, 0.8125rem + (1vw - 3.2px) * 0.3226), 16px) !important;
}
.col-10 {
  width: 100%;
  p {
    font-weight: 400 !important;
  }
  @media screen and (max-width: 776px) {
    width: 100%;
  }
}
.col1 {
  width: 48%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.col2 {
  width: 54%;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
.swipeeeer {
  padding: 12px 6px;
}
</style>