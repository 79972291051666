<template>
    <!-- <ModalOrderForm /> -->
    <div class="trainCard">
        <img class="trainCard-img" :src="require('@/assets/images/train/' + card.image + '.jpg')"/>
        <p class="trainCard-title">{{ $t(card.title) }}</p>
        <p class="trainCard-desc" v-html="$t(card.desc)"></p>
        <button  class="trainCard-button" data-bs-toggle="modal" data-bs-target="#orderForm">{{ $t('leaveRequest') }}</button>
    </div>
</template>
<script>
import ModalOrderForm from '@/modules/app/views/layout/main/ModalOrderForm.vue';

export default {
    props: ["card"],
    components: { ModalOrderForm }
}
</script>
<style lang="scss" scoped>

.trainCard {
    width: 33%;
    position: relative;
    // width: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    height: fit-content;
    transition: all 0.5s ease;
    @media screen and (max-width: 992px) {
        width: 100%;
        padding-bottom: 10px;
    }
    &:hover {
        transform: scale(1.03);
    }
    &-img {
        width: 100%;
        @media screen and (max-width: 992px) {
            width: -webkit-fill-available;
        }
    }
    &-title {
        text-align: start;
        margin-top: 20px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: min(max(17px, calc(1.0625rem + ((1vw - 3.2px) * 0.3226))), 20px);
        line-height: 20px;
        color: #161616;
        margin-left: 9px;
        margin-right: 9px;
    }
    &-desc {
        height: 135px;
        display: flex;
        text-align: start;
        margin-left: 9px;
        margin-right: 9px;
        font-weight: 400;
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
        line-height: 20px;
        margin-top: 10px;
        margin-bottom: 15px;
        @media screen and (max-width: 768px) {
            height: 125px;
        }
         @media screen and (max-width: 380px) {
            height: auto
         }
    }
    &-button {
        margin-bottom: 16px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: auto;
        margin-left: auto;
        height: 57px;
        background: #E80707;
        color: #FEC50C;
        font-weight: 550;
        font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
        box-shadow: 0px 4px 14px rgba(232, 7, 7, 0.3);
        border-radius: 5px;
        padding: 0 49px;
        transition: all 0.5s ease;
        @media screen and (max-width: 768px) {
            height: 41px;
        }
        &:hover {
            background: white;
            color: red;
        }
    }
}
</style>