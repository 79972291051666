import { createStore } from "vuex";

export default createStore({
  state: {
    lang: localStorage.getItem('lang') || "ru",
    isAuth: false,
  },
  getters: {
    getLang: (state) => state.lang,
    getIsAuth: (state) => state.isAuth,
  },
  mutations: {
    UPDATE_LANG(state, lang) {
        state.lang = lang
        localStorage.setItem("lang", lang);
    },
    SET_AUTH(state, payload) {
      state.isAuth = payload;
      // console.log(isAuth,"state")
    },
  },
  actions: {
    checkAuth({ commit }) {
      if (localStorage.getItem("access_token")) {
        // alert(localStorage.getItem("access_token"));
        commit("SET_AUTH", true);
      } else {
        commit("SET_AUTH", false);
      }
    },
  },
  modules: {},
});
