<template>
  <div class="kz-freight container">
    <div class=" kz-freight__items flex-row-between-start w100">
      <div class="kz-freight__content">
        <div class="kz-freight-title b36-700">{{ $t('ourRoutesTitle') }}</div>
        <p class="b18-400" v-html="$t('textOurMarsh2')">
        </p>
        <!-- <p
        :class="{'db': show == true}"
        class="b18-400">
          Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной
          "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую
          оллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно
          пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн.
        </p> -->
      </div>
      <div class="kz-freight-form" 
                data-aos="fade-up" 
                data-aos-delay="200" 
                data-aos-duration="1000">
        <div class="kz-freight-form__title b22-700">{{ $t('leaveRequest') }}</div>
        <div class="kz-freight-form__inputs">
              <input data-aos="zoom-in" 
                  @input="proverka = false" 
                  data-aos-delay="250" 
                  data-aos-duration="1000"
                type="text" :placeholder="$t('name') + ' ' + '*'" v-model="v$.name.$model" @keyup="onlyText()"/>
                
                    <template
                      v-for="(error, index) of v$.name.$errors"
                      :key="index"
                    >
                      <p class="errorValid" v-if="name.length > 0 || proverka">{{ error.$message }}</p>
                    </template>

                <input data-aos="zoom-in" 
                data-aos-delay="300" 
                data-aos-duration="1000"
                type="tel" :placeholder="$t('phone') + ' ' + '*'"
                    v-model="v$.phone.$model" 
                    v-imask="phoneMask" 
                    @input="isNumber" 
                    @focus="proverka = false"
                    @accept="onAccept"
                    @complete="onComplete"/>
                    
                    <template
                      v-for="(error, index) of v$.phone.$errors"
                      :key="index"
                    >
                      <p class="errorValid" v-if="phone.length > 0 || proverka">{{ error.$message }}</p>
                    </template>

                <input data-aos="zoom-in" 
                data-aos-delay="350" 
                data-aos-duration="1000"
                type="text" :placeholder="$t('req1')" :required="false" v-model="req1"/>
                <input data-aos="zoom-in" 
                data-aos-delay="400" 
                data-aos-duration="1000"
                type="text" :placeholder="$t('req2')" :required="false" v-model="req2"/>
                <input data-aos="zoom-in" 
                data-aos-delay="450" 
                data-aos-duration="1000"
                type="text" :placeholder="$t('req3')" :required="false" v-model="req3"/>

                <input data-aos="zoom-in"
                    @input="proverka = false"  
                    data-aos-delay="500" 
                    data-aos-duration="1000"
                    type="email" :placeholder="$t('email')" v-model="v$.email.$model"/>
                    <template
                      v-for="(error, index) of v$.email.$errors"
                      :key="index"
                    >
                      <p class="errorValid" v-if="email.length > 0 || proverka">{{ error.$message }}</p>
                    </template>

                <textarea data-aos="zoom-in" 
                data-aos-delay="550" 
                data-aos-duration="1000"
                placeholder="Введите комментарий" v-model="comment" :required="false"></textarea>

                <button class="btnddd" 
                 data-aos="zoom-in" 
                data-aos-delay="500" 
                data-aos-duration="1000"
                @click="leaveRequest">{{ $t('send') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import AOS from 'aos';
import 'aos/dist/aos.css';

import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers, email } from "@vuelidate/validators";
import { IMaskDirective } from "vue-imask";
import { mapGetters } from 'vuex';

export default {
  directives: {
    imask: IMaskDirective,
  },
  name: "KZFreight",
  data(){
    return {
      proverka: false,
      v$: useVuelidate(),
      show:false,
      name: "",
      phone: "",
      req1: "",
      req2: "",
      req3: "",
      comment: "",
      email: "",
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
    }
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        minLength: helpers.withMessage(
          this.$t("errors.nameError"),
          minLength(3)
        ),
      },
      email: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        email: helpers.withMessage(this.$t("errors.email"), email),
      },
      phone: {
        required: helpers.withMessage(this.$t("errors.required1"), required),
        minLength: helpers.withMessage(
          this.$t("errors.telLength"),
          minLength(18)
        ),
      },
    };
  },
  computed: {
    ...mapGetters(["getIsAuth"])
  },
  methods:{
    showInfo(){
      if(this.show == false) return this.show = true
      else return this.show = false;
    },


    resetModalData() {
      this.name = "",
      this.phone = "",
      this.req1 = "",
      this.req2 = "",
      this.req3 = "",
      this.comment = "",
      this.email = "",
      this.v$.$reset()
    },


    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error("Такого мобильного оператора не существует!");
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      console.log("complete", maskRef.unmaskedValue);
    },


    onlyText() {
      this.name = this.name.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function(match) {
        return match.substr(0, 1);
      });
    },


    
    async leaveRequest() {

      this.proverka = true;

      this.v$.name.$validate();
      this.v$.phone.$validate();
      this.getIsAuth ? this.v$.$validate() : ''
      let bool = this.getIsAuth ? !this.v$.name.$invalid && !this.v$.phone.$invalid && !this.v$.email.$invalid : !this.v$.name.$invalid && !this.v$.phone.$invalid
      if (bool) {
        const reqData = {
        name: this.name,
        phone: this.phone,
        cargo: this.req1,
        weight: this.req2,
        type: this.req3,
        note: this.comment,
        email: this.email
        };
        await this.$axios.post('v1/mail/application', reqData)
          .then(res => {
            this.$toaster.success('Ваша заявка успешно отправлена!');
            this.resetModalData();
          })
          .catch(err => {
            this.$toaster.error('Неверные данные');
          })
        }
      else {
         this.$toaster.error('Заполните обязательные поля, пожалуйста!');
      }
    }

  },
  mounted() {
    AOS.init(); 
  }
};
</script>
<style lang="scss" scoped>
.errorValid {
  text-align: start;
  font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
  margin-top: -10px;
  padding-bottom: 10px;
  margin-left: 16px;
  color: red;
}

.btnddd {
  font-size: min(max(15px, calc(0.9375rem + ((1vw - 3.2px) * 0.4464))), 20px) !important;
  &:hover {
    background: white;
    color: red;
    transform: scale(1);
    
  }
}
.b22-700 {
    font-size: min(max(21px, calc(1.3125rem + ((1vw - 3.2px) * 0.1075))), 22px) !important;
}
.b18-400 {
    font-size: 16px !important;
}
.kz-freight {
  padding: 90px 0 76px;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
  &__content {
    margin-top: 90px;
  }
  @media screen and (max-width: 992px) {
    margin-top: 0;
    padding-bottom: 60px;
  }
  &__content {
    width: 65%;
    p {
      line-height: 25px;
      margin-bottom: 14px;
      font-size: clamp(0.75rem, 0.659rem + 0.45vw, 1rem);
      @media screen and (max-width: 992px) {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
      }
    }
    &-hide{
      display: none;
      font-size: 14px;
      color: #0AAAFA ;
    }
    &-show{
      display: block;
      font-size: 14px;
      color: #0AAAFA ;
    }
  }
  &-form {
    background: #FFFFFF;
    border: 1px solid #E80707;
    box-shadow: 0px 4px 24px rgba(10, 170, 250, 0.1);
    border-radius: 10px;
    padding: 15px 20px 20px 20px;
    width: 27.4%;
    &__title {
      color: #161616 !important;
      line-height: normal !important;
      font-weight: 400 !important;
      margin-bottom: 16px;
    }
    &__inputs {
      input {
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
        border: 1px solid #E0E0E0;
        border-radius: 5px;
        margin-bottom: 12px;
        outline-color: red;
      }
      textarea {
          color: rgb(0, 0, 0);
          padding: 12px 16px;
          background: #FFFFFF;
          border: 1px solid #E0E0E0;
          border-radius: 5px;
          font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
          width: 100%;
          height: 50px;
          resize: none;
           outline-color: red;
        }
      button {
      font-size: min(max(13px, calc(0.8125rem + ((1vw - 3.2px) * 0.3226))), 16px);
        margin-top: 11px;
        padding: 10px 50px;
        color: #FEC50C;
        background: $whiteRedColor;
        box-shadow: 0px 4px 14px rgba(255, 31, 1, 0.6);
        border-radius: 5px;
        transition: all 0.3s ease;
      }
    }
  }
  &-title {
    text-transform: none;
    display: flex;
    font-weight: 400;
    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.2903))), 36px);
    text-align: start;
    margin-bottom: 35px;  
    color: #161616;
    @media screen and (max-width: 992px) {
      font-weight: 400;
      line-height: 31px;
    }
  }
  .db{
    display: block !important;
  }
  .dn{
    display: none !important;
  } 
  @media (max-width: 1400px){
    .kz-freight-form__inputs{
    }
  }
  @media (max-width: 992px){
    .kz-freight__items{
      display: flex;
      flex-direction: column;
    }
.kz-freight__content{
  width: 100%;
}
.kz-freight-title {}
.kz-freight-form {
  width: 100%;
  margin: 20px 0px 0px 0px;
  @media screen and (max-width: 992px) {
    width: 95%;
    margin-top: 60px;
    margin-right: auto;
    margin-left: auto;
  }
}
.kz-freight-form__title {}
.kz-freight-form__inputs {}
  }
  @media (max-width: 772px){}
  @media (max-width: 577px){
  .kz-freight__content{
        // & p:nth-child(2){
        //   display: none;
        // }
    }
  }
}
.kz-freight-form__inputs {
  input {
    margin-bottom: 10px;
    height: 50px;
  }
}
</style>