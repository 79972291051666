<template>
  <div class="international container">
    <div class="international-title">{{ $t('interFreight') }}</div>
    <div class="international__info d-flex w100 ce" >
      <div class="international__content">
        <p class="black-15-400" v-html="$t('textOurMarsh1')">
        </p>
        <!-- <p
        :class="{'db': show == true}"
          class="black-15-400">
          1
        </p>
        
        <p
          v-if="show == true"
          class="black-15-400">
          2
        </p>

          <p
          @click="showInfo()"
          :class="{'dn': show == true}"
          class="international__content-show">{{ $t('podrognee') }}</p>
          <p 
          @click="showInfo()"
          :class="{'db': show == true}"
          class="international__content-hide">{{$t('notseeAll')}}</p> -->
      </div>
      <div class="international-img">
        <img :src="require('@/assets/images/our-routes-page/map.svg')"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InternationalFreight",
  data(){
    return{
      show:false,
    }
  },
  methods:{
    showInfo(){
      if(this.show == false) return this.show = true
      else return this.show = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.black-15-400 {
    font-size: 16px !important;
}
.international {
  &__content {
    width: 34%;
    margin-right: 19px;
    p {
      line-height: 25px;
      font-size: clamp(0.75rem, 0.659rem + 0.45vw, 1rem);
      margin-bottom: 20px;
      @media screen and (max-width: 992px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;
      }
    }
    &-hide{
      cursor: pointer;
      display: none;
      font-size: 14px;
      color: red ;
      text-align: start;
    }
    &-show{
      cursor: pointer;
      display: block;
      font-size: 14px;
      color: red;
      text-align: start;
      border-bottom: 1px solid red;
      width: fit-content;
    }
  }
  &-img {
    width: 62%;
    img {
      width: 107%;
      object-fit: cover;
      @media screen and (max-width: 778px) {
        width: 100%;
      }
    }
  }
  &-title {
    display: flex;
    color: #161616;
    text-transform: none;
    margin-bottom: 39px;
    font-weight: 400;
    font-size: min(max(24px, calc(1.5rem + ((1vw - 3.2px) * 1.2903))), 36px);
    line-height: 44px;
    @media screen and (max-width: 992px) {
      text-align: start;
      font-weight: 400;
      line-height: 31px;
      margin-bottom: 16px;
    }
  }
  .db{
    display: block !important;
  }
  .dn{
    display: none !important;
  } 
  @media (max-width: 1400px){}
  @media (max-width: 992px){
    .international__info{
      flex-direction: column;

    }
    .international__content{
      width: 100%;
    }
    .international-img{
      width: 110%;
      margin-left: -5%;
      display: flex;
    }
  }
  @media (max-width: 772px){}
  @media (max-width: 577px){
    .international{
      align-items: flex-start !important;
    }
    .international__content{
      & p:nth-child(2){
        display: none;
      }
    }
    .international-img{
      margin-top: 10px;
    }
  }
}
.ce {
  gap: 3%;
}
</style>