<template>
    <div class="newsP" @click="openNewsCard(news.id)">
        <img class="newsP-image" :src="require('@/assets/images/news/' + news.image + '.png')"/>
        <div class="newsP-content">
            <p class="newsP-title">{{ news.title }}</p>
            <p class="newsP-desc">{{ news.desc }}</p>
            <div class="newsP__bot">
                <div class="newsP__bot-box">
                    <img :src="require('@/assets/icons/data.svg')"/>
                    <p>{{ news.data }}</p>
                </div>
                <div class="newsP__bot-box">
                    <img :src="require('@/assets/icons/seed.svg')"/>
                    <p>{{ news.viewed }}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ["news"],
    methods: {
      openNewsCard(value) {
        if (value >= 0) {
            this.$store.state.openNewsCard = value;
            this.$router.push('/news/' + value)
        }
        else {
            console.log('Ошибка не правильно');
        }
    }
    }
    
}
</script>
<style lang="scss" scoped>
.newsP {
    cursor: pointer;
    width: 301px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px rgba(2, 40, 59, 0.25);
    border-radius: 10px;
    transition: transform 0.5s ease;
    &:hover {
        transform: scale(1.05);
    }
    &-image {
        border-radius: 10px;
    }
    &-title {
        height: 40px;
        width: 100%;
    }
    &-content {
        text-align: start;
        padding: 20px 14px 37px 14px;
    }
    &-title {
      font-weight: 600;
        font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.885))), 18px);
        line-height: 22px;
    }
    &-desc {
        color: #02283B;
        margin-top: 9px;
        font-weight: 400;
            font-size: min(max(13px, calc(0.8125rem + ((1vw - 10.24px) * 0.885))), 15px);
            line-height: 18px;
    }
    &__bot {
        color: #828282;
          font-size: min(max(16px, calc(1rem + ((1vw - 10.24px) * 0.885))), 18px);
        margin-top: 33px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-box {
            display: flex;
            gap: 10px;
            align-items: center;
        }
    }
}
</style>