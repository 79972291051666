<template>
    <div class="ostBack">
        <div class="ost container">
            <p class="ost-p1">{{ $t('youHaveQues') }}</p>
            <p class="ost-p2">{{ $t('youHaveQuesDesc') }}</p>
            <div class="ost__form">
                <div class="ost__form-input">
                    <input type="text" 
                        :placeholder="$t('name') + ' ' + '*'"
                        v-model="v$.name.$model" 
                        @keyup="onlyText()" 
                        />
                        <template
                        v-for="(error, index) of v$.name.$errors"
                        :key="index">
                        <p class="errorValid">{{ error.$message }}</p>
                    </template>
                </div>
                
                <div class="ost__form-input">
                    <input type="text"
                        :placeholder="$t('phone') + ' ' + '*'"
                        v-model="v$.phone.$model"
                        v-imask="phoneMask" 
                        @input="isNumber"
                        @accept="onAccept"
                        @complete="onComplete"/>
                  <template
                    v-for="(error, index) of v$.phone.$errors"
                    :key="index"
                  >
                    <p class="errorValid">{{ error.$message }}</p>
                  </template>
                </div>
                <button @click="orderPhone">{{ $t('contactWithUs') }}</button>
            </div>
            <p class="ost-p3" v-html="$t('youHaveQuesConfirm')"></p>
        </div>
    </div>
</template>
<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, helpers } from "@vuelidate/validators";
import { IMaskDirective } from "vue-imask";
export default {
  directives: {
    imask: IMaskDirective,
  },
  data() {
    return {
      v$: useVuelidate(),
      name: "",
      phone: "",
      phoneMask: {
        mask: "+{7} ({000}) 000-00-00",
        lazy: true,
      },
      operators: [
        700, 701, 702, 703, 704, 705, 706, 707, 708, 709, 747, 750, 751, 760,
        761, 762, 763, 764, 771, 775, 776, 777, 778,
      ],
    };
  },
  validations() {
    return {
      name: {
        required: helpers.withMessage(this.$t("errors.required"), required),
        minLength: helpers.withMessage(
          this.$t("errors.nameError"),
          minLength(3)
        ),
      },
      phone: {
        required: helpers.withMessage(this.$t("errors.required1"), required),
        minLength: helpers.withMessage(
          this.$t("errors.telLength"),
          minLength(18)
        ),
      },
    };
  },  
  methods: {
    isNumber(e) {
      let regex = /[0-9]/;
      if (!regex.test(e.key)) {
        e.returnValue = false;
        if (e.preventDefault) e.preventDefault();
      }
      if (e.target.value.slice(4, 7).length === 3) {
        if (this.operators.includes(parseInt(e.target.value.slice(4, 7)))) {
          console.log("true", true);
        } else {
          e.target.value = " ";
          this.$toaster.error("Такого мобильного оператора не существует!");
        }
      }
    },
    onAccept(e) {
      const maskRef = e.detail;
      this.value = maskRef.value;
    },
    onComplete(e) {
      const maskRef = e.detail; // что бы в дальнейшем сохранить
      console.log("complete", maskRef.unmaskedValue);
    },

    
    onlyText() {
      this.name = this.name.replace(/[^а-яА-ЯёЁәӘіІңҢғҒүҮұҰқҚөӨһҺa-zA-Z\-\s.]/gi, "");
      this.name = this.name.replace(/\.{2,}|\s{2,}|\-{2,}/g, function(match) {
        return match.substr(0, 1);
      });
    },

    resetModalData() {
      this.name = "",
      this.phone = "",
      this.v$.$reset()
    },

    

    async orderPhone() {
      this.v$.$validate();
      if (!this.v$.$invalid) {
        this.v$.$validate();
        const orderData = {
          name: this.name,
          phone: this.phone,
        };
        await this.$axios
          .post("v1/mail/call", orderData)
          .then((res) => {
            this.$toaster.success("Ваша заявка успешно отправлена!");
            this.resetModalData();
            this.v$.$reset()
          })
          .catch((err) => {
            this.$toaster.error("Неверные данные");
          });
      } else {
        this.$toaster.error("Заполните обязательные поля, пожалуйста!");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ostBack {
    background: #283043;
    padding: 15px 0 15px;
}
.ost {
    &-p1 {
        color: white;
        font-size: min(max(20px, calc(1.25rem + ((1vw - 3.2px) * 1.7857))), 40px);
    }
    &-p2 {
        color: white;
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.9821))), 20px);
    }
    &-p3 {
        color: rgb(202, 202, 202);
        font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.0893))), 10px);
    }
    &__form {
        margin-top: 33px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        gap: 4%;
        @media screen and (max-width: 992px) {
          margin-top: 16px;
        }
        &-input {
            width: 20%;
            @media screen and (max-width: 992px) {
              width: 28%;
            }
        }
        input {
            outline: none;
            height: 42px;
            width: 100%;
            font-size: min(max(12px, calc(0.75rem + ((1vw - 3.2px) * 0.0893))), 13px);
        }
        button {
            font-weight: 400;
            font-size: min(max(9px, calc(0.5625rem + ((1vw - 3.2px) * 0.9821))), 20px);
            background: #FEC50C;
            color: #E80707;
            height: 42px;
            width: 20%;
            @media screen and (max-width: 992px) {
              width: 33%;
            }
        }
    }
}
.errorValid {
  text-align: start !important;
  display: flex;
  justify-content: start;
  margin: auto;
  font-size: min(max(11px, calc(0.6875rem + ((1vw - 3.2px) * 0.3226))), 14px);
  margin-top: 0;
  margin-left: 16px;
  color: red;
}
</style>