<template>
  <div>
    <router-view />
  </div>
</template>

<script>

export default {};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  overflow-x: hidden;
  color: #2c3e50;
}
html,
body {
  margin: 0 !important;
  padding: 0 !important;
  overflow-x: hidden;
}
@media screen and (max-width: $tablet) {
  .overflowy {
    overflow: hidden !important;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 15px;
  background: #b00101;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 15px;
  background: #d80707;
}
</style>
