<template>
  <main class="services w100">
    Services
  </main>
</template>

<script>

export default {
  name: "OurServicesPage",
};
</script>

<style scoped lang="scss">
.services {
  width: 100vw;
  padding: 74px 0;

  &__intro {
    //margin-top: 74px;
  }
  //
  &-title {
    margin-bottom: 26px;
  }
}
</style>
